import React, { useRef, useState } from 'react';
import { ListPage, AppPaper, BirdzTitle } from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { CrIntervention } from '../CR/Cases/Types';
import { Link } from 'react-router-dom';
import Calendar from './Calendar/Calendar';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Planning = () => {
  const nonScheduledInterventionsEndpoint = `/api/boe/cases/non-scheduled-cr-interventions/`;
  const listingRef = useRef<any>(null);
  const [listVisibility, setListVisibility] = useState<boolean>(true);
  const [nonScheduledInterventions, setNonScheduledInterventions] = useState<CrIntervention[]>([]);

  const refreshListing = () => {
    setListVisibility(false);
    setTimeout(() => {
      setListVisibility(true);
    });
  };

  const listFields = [
    {
      name: 'created_at',
      label: 'Date création',
      transform: (value: any) => {
        return (value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
      },
      className: 'text-center'
    },
    {
      name: 'intervention_max_date',
      label: 'Date max intervention',
      transform: (value: any) => {
        return (value && moment(value).format('DD/MM/YYYY')) || '-';
      }
    },
    {
      name: 'cr_case__number',
      label: 'Numéro dossier'
    },
    {
      name: 'device_id',
      label: 'Équipement'
    },
    {
      name: 'location',
      label: 'Commune',
      transform: (col: any) => {
        return `${col?.zip_code} ${col?.city} ${
          col?.longitude && `(${col?.longitude},${col?.latitude})`
        }`;
      }
    },
    {
      name: 'view',
      label: 'Action',
      transform: (col: any, row: any) => {
        return (
          <IconButton component={Link} to={`/boe/cr/interventions/details/${row.id}#retour`}>
            <SearchIcon fontSize="small" />
          </IconButton>
        );
      },
      className: 'text-center'
    },
    {
      name: 'plannif',
      label: 'Planification',
      className: 'text-center drag-item',
      transform: () => {
        return <DragIndicatorIcon id="dragIntervention" style={{ cursor: 'pointer' }} />;
      }
    }
  ];

  return (
    <>
      <AppPaper>
        <BirdzTitle>Interventions à planifier</BirdzTitle>
        <div id="external-events" ref={listingRef}>
          {listVisibility && (
            <ListPage
              endpoint={nonScheduledInterventionsEndpoint}
              fields={listFields}
              onLoadItems={(items: any) => setTimeout(() => setNonScheduledInterventions(items))}
            />
          )}
        </div>
      </AppPaper>

      <Calendar
        nonScheduledInterventions={nonScheduledInterventions}
        onChange={refreshListing}
        ref={listingRef}
      />
    </>
  );
};

export default Planning;
