import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import {
  BirdzModal,
  BirdzModalActions,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';

type SmsFreeMessageModalProps = {
  onSubmit(smsMessage: string): void;
};

const SmsFreeMessageDialog = ({ onSubmit }: SmsFreeMessageModalProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [freeMessage, setFreeMessage] = useState<string>('');

  const sendSMS = () => {
    handleClose();
    onSubmit(freeMessage);
  };

  return (
    <div>
      <Button variant="outlined" size="small" onClick={handleOpen} sx={{ fontSize: '12px' }}>
        Envoyer un SMS en saisie libre
      </Button>
      <BirdzModal open={open} onClose={handleClose}>
        <BirdzModalTitle>Envoi d'un sms en saisie libre</BirdzModalTitle>
        <BirdzModalContent>
          <TextField
            label="SMS"
            type="string"
            defaultValue={'a5322 '}
            required
            helperText="Ce champ est obligatoire."
            fullWidth
            onChange={(event) => {
              setFreeMessage(event.target.value);
            }}
          />
        </BirdzModalContent>
        <BirdzModalActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            onClick={sendSMS}
            disabled={!freeMessage.startsWith('a5322 ') || freeMessage.length < 6}
          >
            Envoyer
          </Button>
        </BirdzModalActions>
      </BirdzModal>
    </div>
  );
};

export default SmsFreeMessageDialog;
