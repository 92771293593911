import React, { useEffect, useState } from 'react';
import {
  AppPaper,
  BirdzTitle,
  BirdzSubtitle,
  StyledTableHeaderRow,
  SearchField,
  ListField,
  ListPage,
  SearchForm
} from '@applications-terrains/birdz-react-library';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import {
  Alert,
  Box,
  Button,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { formatToSelectOptions, useGetMRDeviceRequests } from '../../../hooks/datarefs';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

//todo type device
type MrCaseDevice = {
  [key: string]: any;
};

enum MRCASETYPE {
  MAINTENANCE = 1,
  DEPLOYMENT = 2
}

const MrCaseDiagnostic = () => {
  const { notif, notifOptions } = useNotif();
  const [caseType, setCaseType] = useState<number>();
  const [searchFields, setSearchFields] = useState<SearchField[]>([]);
  const [listFields, setListFields] = useState<ListField[]>([]);
  const { data: mrDeviceRequests } = useGetMRDeviceRequests();
  const [requestId, setRequestId] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [targetedDevices, setTargetedDevices] = useState<MrCaseDevice[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<MrCaseDevice[]>([]);
  const [mrType, setMrType] = useState<number>();
  const navigate = useNavigate();
  const [creationInProgress, setCreationInProgress] = useState<boolean>(false);
  const [mrDevices, setMrDevices] = useState<any[]>([]);

  const DEPLOYMENT = {
    id: 'MOD_NUM',
    filters: [
      {
        name: 'module_address',
        label: 'Adresse radio',
        type: 'text',
        size: 12
      },
      {
        name: 'contrat_code',
        label: 'Code contrat',
        type: 'text',
        size: 12
      },
      {
        name: 'start_date',
        label: 'Date début',
        type: 'datepicker'
      },
      {
        name: 'end_date',
        label: 'Date fin',
        type: 'datepicker'
      }
    ]
  };

  const MAINTENANCE = {
    id: 'TC294_RADIO',
    filters: [
      {
        name: 'module_address',
        label: 'Adresse radio',
        type: 'text',
        size: 12
      },
      {
        name: 'contrat',
        label: 'Code contrat',
        type: 'text',
        size: 12
      },
      {
        name: 'adr',
        label: 'Commune / CP',
        type: 'text',
        size: 12
      },
      {
        name: 'start_date',
        label: 'Date début',
        type: 'datepicker'
      },
      {
        name: 'end_date',
        label: 'Date fin',
        type: 'datepicker'
      },
      {
        name: 'etat',
        label: 'État',
        type: 'select',
        options: {
          values: [
            { value: 'HS', label: 'HS' },
            { value: 'NS', label: 'NS' }
          ]
        },
        size: 6
      }
    ]
  };

  useEffect(() => {
    setTargetedDevices([]);
  }, [mrType]);

  useEffect(() => {
    let searchFields: SearchField[] = [
      {
        name: 'request_id',
        type: 'select',
        label: 'Base requête',
        options: {
          values: formatToSelectOptions(mrDeviceRequests)
        },
        size: 12
      }
    ];

    if (mrType === MRCASETYPE.DEPLOYMENT) {
      searchFields = searchFields.concat(DEPLOYMENT.filters);
    } else if (mrType === MRCASETYPE.MAINTENANCE) {
      searchFields = searchFields.concat(MAINTENANCE.filters);
    }

    setSearchFields(searchFields);
  }, [mrType, mrDeviceRequests]);

  useEffect(() => {
    if (!requestId) return;
    setIsLoading(true);
    setMrDevices([]);
    axios
      .get(`/api/boi/cases/mr-devices/?request_id=${requestId}`)
      .then(
        (response) => {
          const devices = response.data.results;
          setMrDevices(devices);
          updateListFields(devices);
        },
        () => {
          notif({
            content: 'Une erreur est survenue lors de la récupération des équipements',
            type: 'error'
          });
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [requestId]);

  const removeTargetedDevice = (targetedDevice: MrCaseDevice) => {
    const idField = mrType === MRCASETYPE.DEPLOYMENT ? DEPLOYMENT.id : MAINTENANCE.id;
    setTargetedDevices(
      targetedDevices.filter((device) => device[idField] !== targetedDevice[idField])
    );
  };

  const onChangeCaseType = (event: any) => {
    setCaseType(+event.target.value);
  };

  const createMrCase = () => {
    setCreationInProgress(true);
    const payload = {
      devices: targetedDevices.map((device) => {
        return {
          device: device.device,
          request_id: device.request_id
        };
      }),
      action_type: caseType
    };

    axios
      .post('/api/boi/cases/mr-cases/', payload)
      .then((response) => {
        notif({
          content: 'Le dossier a bien été créé',
          type: 'success'
        });

        setTimeout(() => {
          navigate('/boi/mr/cases/edit/' + response.data.id);
        });
      })
      .finally(() => {
        setCreationInProgress(false);
      })
      .catch(() => {
        notif({
          content: 'Une erreur est survenue lors de la création du dossier',
          type: 'error'
        });
      });
  };

  const updateListFields = (results: any) => {
    const columns = Object.keys(results[0]);
    const listFields: ListField[] = columns.map((column) => {
      return {
        name: column,
        label: column,
        options: {
          width: column.length * 14 + 'px'
        }
      };
    });
    setListFields(listFields);
  };

  const TargetedDevices = () => {
    // const listFields = mrType === MRCASETYPE.DEPLOYMENT ? DEPLOYMENT.fields : MAINTENANCE.fields;
    if (!targetedDevices || targetedDevices.length === 0) return null;

    return (
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table size="small">
          <TableHead>
            <StyledTableHeaderRow>
              {listFields.map((field) => {
                return <TableCell key={field.name}>{field.label}</TableCell>;
              })}
              <TableCell></TableCell>
            </StyledTableHeaderRow>
          </TableHead>
          <TableBody>
            {targetedDevices &&
              targetedDevices.map((targetedDevice) => {
                return (
                  <TableRow key={targetedDevice.id}>
                    {listFields.map((field) => {
                      return <TableCell key={field.name}>{targetedDevice[field.name]}</TableCell>;
                    })}
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          removeTargetedDevice(targetedDevice);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box className="mrDiagnotics">
      <AppPaper>
        <BirdzTitle>Diagnostic</BirdzTitle>
        <BirdzSubtitle>Équipements cibles</BirdzSubtitle>

        <SearchForm
          fields={searchFields}
          onSubmit={(filterString, searchFormValues) => {
            setRequestId(searchFormValues?.filterValues?.request_id);
            const request = mrDeviceRequests?.find(
              (request) => request.id === searchFormValues?.filterValues?.request_id
            );
            setMrType(request?.type);
          }}
        />

        <Box sx={{ mt: 1 }}>
          {requestId ? (
            <>
              {!isLoading ? (
                <ListPage
                  loadedData={mrDevices}
                  fields={listFields}
                  onSelectItems={(selectedItems) => {
                    setSelectedDevices(selectedItems);
                  }}
                  hideItems={targetedDevices}
                  selectedItems={selectedDevices}
                  itemKey="TC294_RADIO"
                />
              ) : (
                <LinearProgress />
              )}
            </>
          ) : (
            <Alert severity="info" sx={{ mt: 2 }}>
              Vous devez choisir une base requête
            </Alert>
          )}
        </Box>

        {mrDevices && mrDevices.length > 0 && (
          <>
            <Box sx={{ textAlign: 'center', m: 2 }}>
              <Button
                variant="contained"
                disabled={selectedDevices.length === 0}
                onClick={() => {
                  setTargetedDevices([
                    ...targetedDevices,
                    ...selectedDevices.map((device) => {
                      device.request_id = requestId;
                      device.device =
                        mrType === MRCASETYPE.DEPLOYMENT
                          ? device[DEPLOYMENT.id]
                          : device[MAINTENANCE.id];
                      return device;
                    })
                  ]);
                  setSelectedDevices([]);
                }}
              >
                Ajouter les {selectedDevices.length} équipements sélectionnés
              </Button>
            </Box>

            <TargetedDevices />

            {targetedDevices.length > 0 && (
              <Box sx={{ textAlign: 'center', m: 2 }}>
                Type de dossier:
                <Select
                  sx={{ width: 150, ml: 2 }}
                  size="small"
                  onChange={(e) => {
                    onChangeCaseType(e);
                  }}
                >
                  <MenuItem value={MRCASETYPE.MAINTENANCE}>Maintenance</MenuItem>
                  <MenuItem value={MRCASETYPE.DEPLOYMENT}>Déploiement</MenuItem>
                </Select>
                <Button
                  variant="contained"
                  sx={{ ml: 2 }}
                  disabled={targetedDevices.length === 0 || !caseType || creationInProgress}
                  onClick={() => {
                    createMrCase();
                  }}
                >
                  Créer un dossier avec{' '}
                  {targetedDevices.length === 1
                    ? `l'équipement sélectionné`
                    : `les ${targetedDevices.length} équipéments sélectionnés`}
                </Button>
              </Box>
            )}
          </>
        )}
        <BirdzNotif options={notifOptions} />
      </AppPaper>
    </Box>
  );
};

export default MrCaseDiagnostic;
