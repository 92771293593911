import React from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ParamForm from './ParamForm';
import { ParamsConfigType } from './Types';
import { paramsConfig } from './params.config';
import { Box } from '@mui/material';
import { BirdzTitle, AppPaper } from '@applications-terrains/birdz-react-library';

const ParamsList = () => {
  const [selectedParameter, setSelectedParameter] = useState<ParamsConfigType>();
  const navigate = useNavigate();

  const handleChange = (selectedOption: any) => {
    setSelectedParameter(selectedOption);
    navigate(`/boi/administration/tables/${selectedOption.value}`, { replace: true });
  };

  return (
    <AppPaper variant="outlined" square sx={{ p: 2 }}>
      <BirdzTitle>Gestion des tables techniques</BirdzTitle>
      <Select
        id="chooseTable"
        options={paramsConfig.sort((a, b) => a.label.localeCompare(b.label))}
        onChange={handleChange}
        placeholder="Choisir une table à modifier"
      />
      <Box id={selectedParameter?.label || 'box'} sx={{ mt: 2 }}>
        <h3>{selectedParameter?.label}</h3>
        {selectedParameter?.value && <ParamForm param={selectedParameter.value} />}
      </Box>
    </AppPaper>
  );
};

export default ParamsList;
