import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MrCaseInterventionDetail, MrCase } from './Types';
import axios from 'axios';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import {
  BirdzTitle,
  StyledTableBodyRow,
  StyledTableHeaderRow
} from '@applications-terrains/birdz-react-library';
import MrCaseEquipmentCount from './MrCaseEquipmentCount';

type MrCaseInterventionDetailsParams = {
  caseId: string;
};

export default function MrCaseInterventionDetails() {
  const { id } = useParams();
  const { caseId } = useParams<MrCaseInterventionDetailsParams>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [intervention, setIntervention] = useState<MrCaseInterventionDetail>();
  const [mrCase, setMrCase] = useState<MrCase>();

  useEffect(() => {
    setLoading(true);

    const fetchCaseDetails = async () => {
      await axios
        .get(`/api/boi/cases/mr-cases/${caseId}/`)
        .then((response: any) => {
          setMrCase(response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const fetchInterventionDetails = async () => {
      await axios
        .get(`/api/boi/cases/mr-interventions/${id}/`)
        .then((response: any) => {
          setIntervention(response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchInterventionDetails();
    fetchCaseDetails();
  }, [id]);
  return (
    <>
      {isLoading ? (
        <Box textAlign="center">
          <CircularProgress />
          <h3>Chargement en cours...</h3>
        </Box>
      ) : (
        <Paper variant="outlined" square sx={{ p: 2 }} id="mrCaseForm">
          <BirdzTitle>Détails dossier (incidents)</BirdzTitle>
          <h4>Informations générales</h4>

          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <TextField
                label="Numéro dossier"
                id="outlined-size-small"
                defaultValue={mrCase?.number}
                value={mrCase?.number || ''}
                size="small"
                variant="filled"
              />
            </Grid>

            <Grid item xs={12} md={5} textAlign="right" sx={{ width: 300 }}>
              <Box sx={{ marginTop: '-15px' }}>
                <MrCaseEquipmentCount equipmentsCount={mrCase?.equipments_count} />
              </Box>
            </Grid>
          </Grid>

          <h4>Intervention</h4>

          <TableContainer sx={{ mb: 2 }}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell width={250}>Adresse radio</TableCell>
                  <TableCell>{intervention?.radioAddress}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Type d'intervention</TableCell>
                  <TableCell>{intervention?.interventionType}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sous-traitant</TableCell>
                  <TableCell>{intervention?.subcontractor}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact principal</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Motif</TableCell>
                  <TableCell>{intervention?.closingReason}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Commentaire technicien </TableCell>
                  <TableCell>{intervention?.interventionComment}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <h4>Actions</h4>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table size="small">
              <TableHead>
                <StyledTableHeaderRow>
                  <TableCell align="center">Date / Heure</TableCell>
                  <TableCell align="center">Actions</TableCell>
                  <TableCell align="center">Détails</TableCell>
                </StyledTableHeaderRow>
              </TableHead>
              <TableBody>
                {intervention && intervention.actions ? (
                  intervention.actions.map((inter) => {
                    return (
                      <StyledTableBodyRow key={inter.actionDetail}>
                        <TableCell align="center">{inter.actionDate}</TableCell>
                        <TableCell align="center">{inter.actionType}</TableCell>
                        <TableCell align="center">{inter.actionDetail}</TableCell>
                      </StyledTableBodyRow>
                    );
                  })
                ) : (
                  <StyledTableBodyRow>
                    <TableCell align="center" colSpan={7}>
                      Aucune action
                    </TableCell>
                  </StyledTableBodyRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </>
  );
}
