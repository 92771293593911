import React from 'react';
import moment from 'moment';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { StyledTableHeaderRow } from '../../../styles/Table.styled';
import { SubcontractorDetails } from './SubcontractorBOE';

const PricingsBOE = ({ subcontractor }: { subcontractor: SubcontractorDetails }) => {
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <h3>Tarifs applicables</h3>
        <TableContainer component={Paper} sx={{ mb: 2 }}>
          <Table size="small">
            <TableHead>
              <StyledTableHeaderRow>
                <TableCell>Tarif</TableCell>
                <TableCell>Code produit</TableCell>
                <TableCell>C/O</TableCell>
                <TableCell>Montant par unité</TableCell>
                <TableCell>Date début</TableCell>
                <TableCell>Date fin</TableCell>
                <TableCell>Nb max par intervention</TableCell>
              </StyledTableHeaderRow>
            </TableHead>
            <TableBody>
              {subcontractor?.applicable_pricings &&
              subcontractor?.applicable_pricings?.length > 0 ? (
                subcontractor?.applicable_pricings?.map((applicablePricing) => {
                  return (
                    <TableRow key={`applicable_pricings-${applicablePricing.id}`}>
                      <TableCell>{applicablePricing.year__label}</TableCell>
                      <TableCell>{applicablePricing.product_subcontractor_name}</TableCell>
                      <TableCell>{applicablePricing.capex_opex}</TableCell>
                      <TableCell>{applicablePricing.price}</TableCell>
                      <TableCell>
                        {moment(applicablePricing.start_date).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {moment(applicablePricing.end_date).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>{applicablePricing.max_usage_limit || '-'}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>Aucun tarif disponible</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ mt: 2 }}>
        <h3>Tarifs lignes supplémentaires</h3>
        <TableContainer component={Paper} sx={{ mb: 2 }}>
          <Table size="small">
            <TableHead>
              <StyledTableHeaderRow>
                <TableCell>Tarif</TableCell>
                <TableCell>Code produit</TableCell>
                <TableCell>C/O</TableCell>
                <TableCell>Montant par unité</TableCell>
                <TableCell>Date début</TableCell>
                <TableCell>Date fin</TableCell>
                <TableCell>Nb max par intervention</TableCell>
              </StyledTableHeaderRow>
            </TableHead>
            <TableBody>
              {subcontractor?.additional_pricings &&
              subcontractor?.additional_pricings?.length > 0 ? (
                subcontractor?.additional_pricings?.map((additionalPricing) => {
                  return (
                    <TableRow key={`additional_pricings-${additionalPricing.id}`}>
                      <TableCell>{additionalPricing.year__label}</TableCell>
                      <TableCell>{additionalPricing.product_subcontractor_name}</TableCell>
                      <TableCell>{additionalPricing.capex_opex}</TableCell>
                      <TableCell>{additionalPricing.price}</TableCell>
                      <TableCell>
                        {moment(additionalPricing.start_date).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>
                        {moment(additionalPricing.end_date).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>{additionalPricing.max_usage_limit || '-'}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>Aucun tarif disponible</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default PricingsBOE;
