import React from 'react';
import { useGetAnomalyRules } from '../../../hooks/datarefs';
import { Box, LinearProgress } from '@mui/material';

export default function AnomalyProfilesRules({
  checkedRules,
  onCheckedRules
}: {
  checkedRules: number[];
  onCheckedRules: (checkedRules: number[]) => void;
}) {
  const { data: anomalyRules } = useGetAnomalyRules();
  if (!anomalyRules) return <LinearProgress />;
  return (
    <>
      {anomalyRules.map((anomalyRule) => {
        const checked =
          checkedRules && checkedRules.length > 0 ? checkedRules.includes(anomalyRule.id) : false;
        return (
          <Box sx={{ mt: 2, mb: 2 }} key={anomalyRule.id}>
            <input
              type="checkbox"
              checked={checked}
              onChange={(e) => {
                if (e.target.checked) {
                  onCheckedRules([...checkedRules, anomalyRule.id]);
                } else {
                  onCheckedRules(
                    checkedRules.filter((checkedRule) => checkedRule !== anomalyRule.id)
                  );
                }
              }}
            />
            {anomalyRule.label}
          </Box>
        );
      })}
    </>
  );
}
