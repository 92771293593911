import React from 'react';
import { BirdzTitle, InputField } from '@applications-terrains/birdz-react-library';
import { Grid } from '@mui/material';
import { ExportFormatSettingsMapping } from './ExportFormatSettingsMapping';

export const ExportFormatSettingsText = () => {
  return (
    <>
      <BirdzTitle>Transformation</BirdzTitle>
      <Grid container alignItems="center" spacing={1}>
        <Grid alignItems={'center'} item xs={4}>
          Prefixe
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <InputField name="text_prefix" fullWidth size="small" />
        </Grid>
        <Grid alignItems={'center'} item xs={4}>
          Suffixe
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <InputField name="text_suffix" fullWidth size="small" />
        </Grid>
      </Grid>

      <ExportFormatSettingsMapping />
    </>
  );
};
