import { Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { ModalItem } from '../DMSTypes';
import axios from 'axios';
import {
  BirdzModal,
  BirdzModalActions,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';

type DMSModalDeleteItemProps = {
  item: Partial<ModalItem>;
  onClose: (message?: string) => void;
  onError: (message: string) => void;
};

export const DMSModalDeleteItem = ({ onClose, onError, item }: DMSModalDeleteItemProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deleteElement = () => {
    setIsLoading(true);
    axios
      .post('/api/boi/dms/delete-file-folder/', { file_id: item.id })
      .then(
        () => {
          if (typeof item.onDelete === 'function') {
            item.onDelete();
          }
          onClose(`L'élément a été supprimé avec succès`);
        },
        () => {
          onError("Une erreur est survenue lors de la suppression de l'élément");
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <BirdzModal open={true}>
      <BirdzModalTitle>Supprimer le {item.type === 'file' ? 'fichier' : 'dossier'}</BirdzModalTitle>
      <BirdzModalContent>
        Voulez-vous vraiment supprimer le {item.type === 'file' ? 'fichier' : 'dossier'} :{' '}
        <strong>{item?.name}</strong>?
      </BirdzModalContent>
      <BirdzModalActions>
        <Button id="goBack" variant="outlined" onClick={() => onClose()}>
          Retour
        </Button>
        <Button
          id="delete"
          variant="contained"
          onClick={() => {
            deleteElement();
          }}
          disabled={isLoading}
        >
          {isLoading && <CircularProgress size={16} thickness={5} sx={{ mr: 1 }} />} Supprimer
        </Button>
      </BirdzModalActions>
    </BirdzModal>
  );
};
