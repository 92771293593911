import React, { useEffect, useState } from 'react';
import { FormValues, ParamsConfigType } from './Types';
import axios from 'axios';
import * as Yup from 'yup';
import { InputField, ListPage } from '@applications-terrains/birdz-react-library';
import { paramsConfig } from './params.config';
import { Box } from '@mui/material';
import { useNotif } from '@applications-terrains/birdz-react-library';
import { BirdzNotif } from '@applications-terrains/birdz-react-library';

type ParamFormProps = {
  param: string;
};

const ParamForm = ({ param }: ParamFormProps) => {
  const defaultOrder = 'id';
  const defaultListFields = [
    {
      name: 'id',
      label: 'ID',
      options: {
        renderFormAdd: () => <></>,
        renderFormEdit: (row: any) => <>{row.id}</>
      }
    }
  ];

  const [paramConfig, setParamConfig] = useState<ParamsConfigType>();
  const [listIsVisible, setListIsVisible] = useState<boolean>(true);
  const [order, setOrder] = useState<string>(defaultOrder);

  const [listFields, setListFields] = useState<any>(defaultListFields);
  const { notif, notifOptions } = useNotif();

  useEffect(() => {
    const foundParamConfig = paramsConfig.find((paramConfig) => paramConfig.value === param);
    if (foundParamConfig) {
      setParamConfig(foundParamConfig);

      const newListFields = [...defaultListFields];
      let newOrder = defaultOrder;
      if (foundParamConfig.extraFields) {
        foundParamConfig.extraFields.forEach((extraField) => {
          const newField: any = Object.assign({}, extraField);
          newField.options = {
            renderForm: () => (
              <InputField
                size="small"
                label={extraField.label}
                name={extraField.name}
                type={extraField.type}
              />
            )
          };
          newListFields.push(newField);

          if (extraField.name === 'order') {
            newOrder = 'order';
          }
        });
      }

      setOrder(newOrder);
      setListFields(newListFields);
    }
    //eslint-disable-next-line
  }, [param]);

  if (!paramConfig) {
    return null;
  }

  const refreshList = () => {
    setListIsVisible(false);
    setTimeout(() => {
      setListIsVisible(true);
    });
  };

  const onSave = (values: FormValues) => {
    if (values?.id) {
      return axios.put(`${paramConfig.endpoint}${values.id}`, values).then(
        () => {
          notif({
            type: 'success',
            content: 'La paramètre a été modifié avec succès'
          });
          refreshList();
        },
        (error) => {
          let errors = error?.response?.data ?? [];
          if (errors) {
            errors = Object.keys(errors).map(
              (fieldError) => fieldError + ': ' + errors[fieldError].join(',')
            );
          }
          notif({
            type: 'error',
            content: (
              <>
                Une erreur est survenue lors de la modification
                <br />
                <ul>
                  {errors.map((error: string) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </>
            )
          });
        }
      );
    } else {
      return axios.post(paramConfig.endpoint, values).then(
        () => {
          notif({
            type: 'success',
            content: 'La paramètre a été créé avec succès'
          });
          refreshList();
        },
        (error) => {
          let errors = error?.response?.data ?? [];
          if (errors) {
            errors = Object.keys(errors).map(
              (fieldError) => fieldError + ': ' + errors[fieldError].join(',')
            );
          }
          notif({
            type: 'error',
            content: (
              <>
                Une erreur est survenue
                <br />
                <ul>
                  {errors.map((error: string) => (
                    <li key={error}>{error}</li>
                  ))}
                </ul>
              </>
            )
          });
        }
      );
    }
  };

  const onDelete = (values: FormValues) => {
    return axios.delete(paramConfig?.endpoint + values.id + '/').then(() => {
      refreshList();
    });
  };

  const ParamComponent = (): JSX.Element => {
    const validationSchema: { [key: string]: any } = {};
    listFields.forEach((field: any) => {
      if (field.validationSchema) {
        validationSchema[field.name] = field.validationSchema;
      }
    });

    return (
      <Box id={paramConfig?.value}>
        <ListPage
          endpoint={paramConfig?.endpoint}
          defaultOrder={[order]}
          fields={listFields}
          inlineEditOptions={{
            onSave: onSave,
            onDelete: onDelete,
            validationSchema: Yup.object().shape(validationSchema)
          }}
        />
      </Box>
    );
  };

  return (
    <>
      {listIsVisible && <ParamComponent />}
      <BirdzNotif options={notifOptions} />
    </>
  );
};

export default ParamForm;
