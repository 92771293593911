import React from 'react';
import moment from 'moment';
import { ModuleData } from './CR.types';
import { Alert } from '@mui/material';

type ContractStateProps = {
  moduleData: ModuleData;
};

export default function ContractState({ moduleData }: ContractStateProps) {
  return (
    <Alert severity="info">
      Sous contrat{' '}
      <strong>{moduleData.contract_label ? moduleData.contract_label : <i>inconnu</i>}</strong>
      &nbsp; jusqu'au :{' '}
      <strong>
        {moduleData.contract_exp_date ? (
          moment(moduleData.contract_exp_date).format('DD/MM/YYYY')
        ) : (
          <i>date inconnue</i>
        )}
      </strong>
    </Alert>
  );
}
