import React from 'react';
import {
  Box,
  Grid,
  TextField,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormikContext } from 'formik';
import LinkedInterventions from './LinkedInterventions';
import { authService } from '../../../';

type InterventionReservationsProps = {
  readOnly?: boolean;
};

export default function InterventionReservations({
  readOnly = false
}: InterventionReservationsProps) {
  const { values, getFieldProps }: any = useFormikContext();

  return (
    <>
      {(authService.canAccess('CR_INTER_RESERVE_ADMIN') ||
        authService.canAccess('CR_INTER_RESERVE_LECTURE')) && (
        <Paper variant="outlined" square sx={{ mt: 2 }}>
          <Accordion defaultExpanded sx={{ boxShadow: 'none' }} disableGutters={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h3>Réserve(s) sur intervention</h3>
            </AccordionSummary>
            <AccordionDetails>
              {authService.canAccess('CR_INTER_RESERVE_ADMIN') && (
                <>
                  <Grid container>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Grid
                        container
                        justifyContent="flex-end"
                        sx={{ mt: '-40px' }}
                        alignItems={'center'}
                      >
                        <Box sx={{ mr: 2 }}>Prise en compte BC </Box>
                        <TextField
                          id="purchase_order_percent"
                          type="number"
                          size="small"
                          {...getFieldProps('purchase_order_percent')}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                          }}
                          inputProps={{
                            min: 0,
                            max: 100,
                            step: 5
                          }}
                          sx={{ width: 100 }}
                          value={
                            values?.purchase_order_percent === null
                              ? ''
                              : values.purchase_order_percent
                          }
                          disabled={readOnly}
                        />
                        <Box sx={{ ml: 1 }}>reste {100 - values?.purchase_order_percent}%</Box>
                      </Grid>

                      <Grid item sx={{ mt: 2 }}>
                        <TextField
                          id="reservations_on_intervention"
                          {...getFieldProps('reservations_on_intervention')}
                          placeholder=""
                          value={values['reservations_on_intervention'] || ''}
                          multiline
                          fullWidth
                          rows={4}
                          disabled={readOnly}
                        />
                      </Grid>

                      <Grid item sx={{ mt: 2 }}>
                        <LinkedInterventions interventions={values?.linked_interventions || []} />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {authService.canAccess('CR_INTER_RESERVE_LECTURE') && (
                <>
                  {values?.reservations_on_intervention ? (
                    <p>{values?.reservations_on_intervention}</p>
                  ) : (
                    <Alert severity="info">Aucune réserve</Alert>
                  )}

                  {values?.linked_interventions && values?.linked_interventions.length > 0 && (
                    <Grid item sx={{ mt: 2 }}>
                      <LinkedInterventions interventions={values?.linked_interventions || []} />
                    </Grid>
                  )}
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}
    </>
  );
}
