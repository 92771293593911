import React, { useEffect, useState } from 'react';
import { AppPaper, BirdzTitle } from '@applications-terrains/birdz-react-library';
import {
  Alert,
  LinearProgress,
  Grid,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableBody,
  TableCell,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  BirdzNotif,
  useNotif,
  useDialog,
  BirdzDialog
} from '@applications-terrains/birdz-react-library';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { StyledTableBodyRow, StyledTableHeaderRow } from '../../styles/Table.styled';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/RemoveRedEye';
import Summary from './Summary';
import { MonthlyStatement } from './Types';
import { saveAs } from 'file-saver';
import { PiFileCsv } from 'react-icons/pi';
import { useGetSubcontractors } from '../../hooks/datarefs';

type MonthlyStatementDetailsProps = {
  readOnly: boolean;
};

export default function MonthlyStatementDetails({ readOnly }: MonthlyStatementDetailsProps) {
  const { id } = useParams<string>();
  const [isLoading, setLoading] = useState<boolean>();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const navigate = useNavigate();
  const { notif, notifOptions } = useNotif();

  const [monthlyStatement, setMonthlyStatement] = useState<MonthlyStatement>();
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);
  const { data: subcontractors } = useGetSubcontractors();

  const fetchMonthlyStatement = async () => {
    setLoading(true);
    await axios
      .get(`/api/boi/monthly-statements/monthly-statement/${id}/`)
      .then((response) => {
        setMonthlyStatement(response.data);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMonthlyStatement();
  }, [id]);

  const validation = async () => {
    await axios
      .post(`/api/boi/monthly-statements/monthly-statement/${id}/validate/`, monthlyStatement)
      .then(
        () => {
          notif({
            content: 'Le relevé mensuel a été validé avec succès',
            type: 'success'
          });
          fetchMonthlyStatement();
        },
        () => {
          notif({
            content: "Une erreur est survenue lors de l'étape de validation",
            type: 'error'
          });
        }
      );
  };

  const exportBC = () => {
    setExportInProgress(true);
    axios
      .get(`/api/boi/monthly-statements/monthly-statement/${id}/export/`, {
        responseType: 'blob',
        headers: {
          Accept: '*/*'
        }
      })
      .then((response) => {
        const [, filename] = response.headers['content-disposition'].split('filename=');
        notif({
          content: 'Le BC a été exporté avec succès',
          type: 'success'
        });
        saveAs(response.data, filename);
      })
      .finally(() => {
        setExportInProgress(false);
      });
  };

  return isLoading ? (
    <LinearProgress />
  ) : monthlyStatement ? (
    <>
      <AppPaper>
        <Grid item xs={3} sx={{ mt: 2 }}>
          <BirdzTitle>Période</BirdzTitle>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              Sous-traitant :{' '}
              <strong>
                {
                  subcontractors?.find(
                    (subcontractor) => subcontractor.id === monthlyStatement.subcontractor
                  )?.name
                }
              </strong>
            </Grid>
            <Grid item xs={4}>
              Année : <strong>{monthlyStatement.year__name}</strong>
            </Grid>
            <Grid item xs={4}>
              Mois : <strong>{monthlyStatement.month__name}</strong>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6} sx={{ mt: 2 }}>
          <BirdzTitle>Interventions déclarées sur cette période</BirdzTitle>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table size="small">
              <TableHead>
                <StyledTableHeaderRow>
                  <TableCell align="center">Début incident</TableCell>
                  <TableCell align="center">PDC/PDR</TableCell>
                  <TableCell align="center">Commune</TableCell>
                  <TableCell align="center">Adresse radio</TableCell>
                  <TableCell align="center">Date fin d'intervention</TableCell>
                  <TableCell align="center">Réserve</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </StyledTableHeaderRow>
              </TableHead>
              <TableBody>
                {monthlyStatement.cr_interventions.length > 0 ? (
                  monthlyStatement.cr_interventions.map((statement, index: number) => {
                    return (
                      <StyledTableBodyRow key={statement.id}>
                        <TableCell align="center">
                          {moment.unix(statement.created_at).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell align="center">{statement.pdc_pdr}</TableCell>
                        <TableCell align="center">{statement.city}</TableCell>
                        <TableCell align="center">{statement.device_id}</TableCell>
                        <TableCell align="center">
                          {statement.end_intervention_date
                            ? moment(statement.end_intervention_date).format('DD/MM/YYYY')
                            : '-'}
                        </TableCell>
                        {readOnly && (
                          <TableCell align="center">
                            {Number(statement.purchase_order_percent) * 100}%
                          </TableCell>
                        )}
                        {!readOnly && (
                          <TableCell align="center">
                            <>
                              <TextField
                                type="number"
                                size="small"
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}
                                inputProps={{
                                  min: 0,
                                  max: 100,
                                  step: 5
                                }}
                                sx={{ width: 100 }}
                                value={Number(statement.purchase_order_percent) * 100}
                                onChange={(e) => {
                                  const updatedCrInterventions = [
                                    ...monthlyStatement.cr_interventions
                                  ];
                                  updatedCrInterventions[index].purchase_order_percent = (
                                    +e.target.value / 100
                                  ).toString();
                                  setMonthlyStatement({
                                    ...monthlyStatement,
                                    ...{
                                      cr_interventions: updatedCrInterventions
                                    }
                                  });
                                }}
                                onBlur={() => {
                                  axios
                                    .put(`/api/boi/cases/cr-interventions/${statement.id}/`, {
                                      purchase_order_percent:
                                        monthlyStatement.cr_interventions[index]
                                          .purchase_order_percent,
                                      cr_case: statement.cr_case,
                                      failure_code: statement.failure_code,
                                      updated_at: statement.updated_at
                                    })
                                    .then(
                                      (response) => {
                                        notif({
                                          content:
                                            'Le pourcentage de réserve a été modifié avec succès',
                                          type: 'success'
                                        });
                                        const updatedCrInterventions = [
                                          ...monthlyStatement.cr_interventions
                                        ];
                                        updatedCrInterventions[index].purchase_order_percent =
                                          response.data.purchase_order_percent;
                                        updatedCrInterventions[index].updated_at =
                                          response.data.updated_at;
                                        setMonthlyStatement({
                                          ...monthlyStatement,
                                          ...{ cr_interventions: updatedCrInterventions }
                                        });
                                      },
                                      () => {
                                        notif({
                                          content:
                                            'Une erreur est survenue lors de la modification du pourcentage de réserve',
                                          type: 'error'
                                        });
                                      }
                                    );
                                }}
                              />
                            </>
                          </TableCell>
                        )}
                        <TableCell align="center">
                          <Link to={`/boi/cr/interventions/edit/${statement.id}`}>
                            <IconButton aria-label="Intervention détail">
                              <VisibilityIcon id="visibilityIcon" />
                            </IconButton>
                          </Link>
                          {!readOnly && (
                            <IconButton
                              onClick={() => {
                                confirmDialog({
                                  title: "Supprimer l'intervention du relevé mensuel",
                                  content:
                                    'Êtes-vous sûr de vouloir supprimer cette intervention du relevé mensuel?',
                                  onValidate: () => {
                                    if (monthlyStatement.cr_interventions.length === 1) {
                                      closeDialog();
                                      notif({
                                        content:
                                          'Le relevé mensuel doit comptabiliser au moins 1 intervention.',
                                        type: 'error'
                                      });
                                    } else {
                                      axios
                                        .put(`/api/boi/cases/cr-interventions/${statement.id}/`, {
                                          monthly_statement: null,
                                          cr_case: statement.cr_case,
                                          failure_code: statement.failure_code,
                                          updated_at: statement.updated_at
                                        })
                                        .then(
                                          () => {
                                            notif({
                                              content:
                                                "L'intervention a été supprimée avec succès du relevé mensuel",
                                              type: 'success'
                                            });
                                            navigate(0);
                                          },
                                          () => {
                                            notif({
                                              content:
                                                'Une erreur est survenue lors de la suppression',
                                              type: 'error'
                                            });
                                          }
                                        );
                                      closeDialog();
                                    }
                                  },
                                  onCancel: () => {
                                    closeDialog();
                                  }
                                });
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          )}
                        </TableCell>
                      </StyledTableBodyRow>
                    );
                  })
                ) : (
                  <StyledTableBodyRow>
                    <TableCell colSpan={7}>
                      Aucune intervention trouvée pour cette période
                    </TableCell>
                  </StyledTableBodyRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={6} sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            disabled={exportInProgress}
            sx={{ mr: 1 }}
            onClick={() => exportBC()}
          >
            <PiFileCsv /> <Typography sx={{ ml: 1 }}>Exporter le BC</Typography>
          </Button>
          <BirdzTitle>Résumé</BirdzTitle>
          <Summary fullSummary={true} monthlyStatement={monthlyStatement} />
        </Grid>

        <Grid container sx={{ mt: 5, alignItems: 'center', justifyContent: 'center' }}>
          <Button
            variant="outlined"
            onClick={() => navigate('/boi/monthly-statement')}
            sx={{ mr: 2 }}
          >
            Retour à la liste
          </Button>
          {monthlyStatement && !monthlyStatement.validation_date ? (
            <Tooltip
              title={
                !monthlyStatement.transmission_date &&
                `Vous ne pouvez valider le relevé pour génération de BC tant qu'il n'a pas été transmis`
              }
            >
              <span>
                <Button
                  variant="contained"
                  onClick={() => validation()}
                  disabled={!monthlyStatement.transmission_date}
                >
                  Valider pour génération BC
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Alert severity="success" sx={{ height: '25px', alignItems: 'center' }}>
              Validé le {moment(monthlyStatement.validation_date).format('DD/MM/YYYY à HH:mm')}{' '}
            </Alert>
          )}
        </Grid>
        <BirdzDialog options={dialogOptions} />

        <BirdzNotif options={notifOptions} />
      </AppPaper>
    </>
  ) : null;
}
