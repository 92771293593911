import React from 'react';
import { BirdzTitle, InputField } from '@applications-terrains/birdz-react-library';
import { Grid } from '@mui/material';
import { ExportFormatSettingsMapping } from './ExportFormatSettingsMapping';

export const ExportFormatSettingsNumber = () => {
  return (
    <>
      <BirdzTitle>Format</BirdzTitle>
      <Grid container alignItems="center" spacing={1}>
        <Grid alignItems={'center'} item xs={4}>
          Séparateur décimal
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <InputField name="number_decimal_separator" fullWidth size="small" />
        </Grid>
        <Grid alignItems={'center'} item xs={4}>
          Séparateur millier
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <InputField name="number_thousand_separator" fullWidth size="small" />
        </Grid>
        <Grid alignItems={'center'} item xs={4}>
          Nombre décimales
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <InputField name="number_decimal_separator" fullWidth size="small" type="number" />
        </Grid>
      </Grid>

      <ExportFormatSettingsMapping />
    </>
  );
};
