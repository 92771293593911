import * as React from 'react';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { Button, Paper, TableCell, TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './SearchDevices.scss';
import { ModuleData } from '../Diagnostics/CR.types';
import { Link, useNavigate } from 'react-router-dom';
import {
  AppPaper,
  StyledTableCell,
  StyledTableHeaderRow,
  BirdzTitle
} from '@applications-terrains/birdz-react-library';
import crCaseService from '../Cases/CrCaseService';

type AutocompleteOption = {
  label: string;
};

const SearchDevices = () => {
  const [devices, setDevices] = useState<ModuleData[]>([]);
  const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOption[]>([]);

  const navigate = useNavigate();

  const searchDevice = (deviceId: string) => {
    axios.get(`/api/boi/devices/?device_id=${deviceId}`).then((response: any) => {
      setDevices(response.data);
    });
  };

  const createCase = (module_address: string) => {
    crCaseService.createCase(module_address).then((crCase) => {
      navigate(`/boi/cr/cases/edit/${crCase.id}/`);
    });
  };

  return (
    <AppPaper>
      <BirdzTitle>Recherche sur un PDC ou une adresse radio</BirdzTitle>
      <Autocomplete
        id="search_by_cr"
        disablePortal
        options={autocompleteOptions}
        sx={{ mt: 2 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={'Entrez 4 caractères minimum...'}
            onKeyUp={(e: any) => {
              const inputValue = e?.target?.value;

              if (!inputValue || inputValue.length <= 3) {
                setAutocompleteOptions([]);
                return;
              }

              axios
                .get(`/api/boi/devices/search/?device_id=${e.target.value}`)
                .then((response: any) => {
                  const data = response?.data?.map((device: string) => {
                    return { label: device };
                  });
                  setAutocompleteOptions(data);
                });
            }}
          />
        )}
        onChange={(event, value) => {
          if (value?.label) {
            searchDevice(value.label);
          }
        }}
        noOptionsText="Aucun résultat"
      />

      {devices.length !== 0 && (
        <TableContainer component={Paper} sx={{ marginTop: '25px' }}>
          <Table size="small">
            <TableHead>
              <StyledTableHeaderRow>
                <TableCell align="center">Adresse Radio</TableCell>
                <TableCell align="center">PDC</TableCell>
                <TableCell align="center">Commune</TableCell>
                <TableCell align="center">Contrat</TableCell>
                <TableCell align="center">Actions</TableCell>
              </StyledTableHeaderRow>
            </TableHead>
            <TableBody>
              {devices.map((device: any) => (
                <TableRow key={device.module_address}>
                  <StyledTableCell align="center">
                    {device.module_address ? device.module_address : 'Non renseignée'}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {device.pdc_id ? device.pdc_id : 'Non renseigné'}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {device.city ? device.city : 'Non renseignée'}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {device.contract ? device.contract : 'Non renseigné'}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Link to={`/boi/cr/diagnostics/details/${device.module_address}`}>
                      <Button id="launch_diagnostic" variant="outlined" size="small">
                        Lancer un diagnostic
                      </Button>
                    </Link>
                    <br />
                    <Button
                      id="create_crCase"
                      size="small"
                      style={{ marginTop: 5 }}
                      variant="outlined"
                      onClick={() => createCase(device.module_address)}
                    >
                      Créer un dossier
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </AppPaper>
  );
};

export default SearchDevices;
