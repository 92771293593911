import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Box,
  Paper,
  TableContainer,
  IconButton,
  CircularProgress
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import * as _ from 'lodash';
import { StyledTableHeaderRow, useNotif } from '@applications-terrains/birdz-react-library';
import { BirdzNotif } from '@applications-terrains/birdz-react-library';
import Done from '@mui/icons-material/Done';

export type SubcontractorWorkingHours = {
  end_working: string | null;
  isoweekday: number;
  start_working: string | null;
  subcontractor: number;
};

export default function WorkingHours({ onChange }: any) {
  const { id } = useParams<any>();
  const weekdays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'];

  const [subcontractorData, setSubcontractorData] = useState<any>();
  const [workingHours, setWorkingHours] = useState<SubcontractorWorkingHours[]>([]);
  const { notif, notifOptions } = useNotif();
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchSubcontratorDetails = async () => {
      await axios.get(`/api/boi/subcontractors/subcontractors/${id}/`).then((response) => {
        setSubcontractorData(response.data);

        const data = response.data.weekdays_working_hours as SubcontractorWorkingHours[];
        formatWorkingHours(data);
      });
    };

    fetchSubcontratorDetails();
    //eslint-disable-next-line
  }, [id]);

  const formatWorkingHours = (workingHours: any) => {
    const weekdaysWorkingHours = workingHours.map((day: SubcontractorWorkingHours) => {
      return {
        ...day,
        ...{ start_working: day.start_working?.substring(0, 5) },
        ...{ end_working: day.end_working?.substring(0, 5) }
      };
    });

    //add missing/not worked days
    if (weekdays.length !== weekdaysWorkingHours.length) {
      weekdays.forEach((dayName: string, index: number) => {
        const foundWeekday = weekdaysWorkingHours.find(
          (weekdayWorkingHours: any) => weekdayWorkingHours.isoweekday === index + 1
        );
        if (!foundWeekday) {
          weekdaysWorkingHours.splice(index, 0, {
            subcontractor: Number(id),
            isoweekday: index + 1,
            start_working: '',
            end_working: ''
          });
        }
      });
    }

    //sort isoweekday by asc order
    const result = _.sortBy(weekdaysWorkingHours, [
      function (day: SubcontractorWorkingHours) {
        return day.isoweekday;
      }
    ]) as SubcontractorWorkingHours[];
    setWorkingHours(result);
  };

  const saveWorkingHours = () => {
    let isError = false;
    const weekdaysWorkingHours = workingHours.map((day) => {
      if ((day.start_working && !day.end_working) || (!day.start_working && day.end_working)) {
        isError = true;
        notif({
          content: `Veuillez remplir des heures de début et de fin pour le ${
            weekdays[day.isoweekday - 1]
          }`,
          type: 'error'
        });
      }
      return day;
    });

    if (!isError) {
      const body = {
        name: subcontractorData.name,
        sage_code: subcontractorData.sage_code,
        weekdays_working_hours: weekdaysWorkingHours,
        updated_at: subcontractorData.updated_at
      };

      setSaveIsLoading(true);
      axios
        .put(`/api/boi/subcontractors/subcontractors/${id}/`, body)
        .then(
          (response) => {
            notif({
              content: 'Heures ouvrées modifiées avec succès',
              type: 'success'
            });
            setSubcontractorData(response.data);
            formatWorkingHours(response.data.weekdays_working_hours);
            onChange(response.data);
          },
          () => {
            notif({
              content: 'Erreur lors de la modification des heures ouvrées',
              type: 'error'
            });
          }
        )
        .finally(() => {
          setSaveIsLoading(false);
        });
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <h3>Heures ouvrées</h3>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table size="small">
          <TableHead>
            <StyledTableHeaderRow>
              <TableCell></TableCell>
              {weekdays.map((dayName: string) => {
                return (
                  <TableCell key={dayName} align="center">
                    {dayName}
                  </TableCell>
                );
              })}
              <TableCell align="center">Action</TableCell>
            </StyledTableHeaderRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Heure début</TableCell>
              {workingHours?.map((day: SubcontractorWorkingHours, index: number) => {
                return (
                  <TableCell key={'start_working_day_' + day.isoweekday}>
                    <TextField
                      id={'start_working_day_' + day.isoweekday}
                      label="Heure début"
                      type="time"
                      defaultValue={day.start_working || ''}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min with keyboard arrows up and down
                      }}
                      onChange={(newValue: any) => {
                        const newSubcontractorInfos = [...workingHours];
                        newSubcontractorInfos[index].start_working = newValue.target.value;
                        setWorkingHours(newSubcontractorInfos);
                      }}
                      size="small"
                      sx={{ m: 2 }}
                    />
                  </TableCell>
                );
              })}
              <TableCell rowSpan={2} align="center">
                {!saveIsLoading && (
                  <IconButton
                    type="button"
                    onClick={() => {
                      saveWorkingHours();
                    }}
                  >
                    <Done id="doneAction" fontSize="small" />
                  </IconButton>
                )}
                {saveIsLoading && <CircularProgress size={20} />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Heure fin</TableCell>
              {workingHours?.map((day: SubcontractorWorkingHours, index: number) => {
                return (
                  <TableCell key={'end_working_day_' + day.isoweekday}>
                    <TextField
                      id={'end_working_day_' + day.isoweekday}
                      label="Heure fin"
                      type="time"
                      defaultValue={day.end_working || ''}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min with keyboard arrows up and down
                      }}
                      onChange={(newValue: any) => {
                        const newSubcontractorInfos = [...workingHours];
                        newSubcontractorInfos[index].end_working = newValue.target.value;
                        setWorkingHours(newSubcontractorInfos);
                      }}
                      size="small"
                      sx={{ m: 2 }}
                    />
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <BirdzNotif options={notifOptions} />
    </Box>
  );
}
