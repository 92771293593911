import React, { useEffect, useState } from 'react';
import {
  ListPage,
  BirdzDialog,
  useDialog,
  AppPaper,
  BirdzTitle,
  ListField
} from '@applications-terrains/birdz-react-library';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Grid, IconButton } from '@mui/material';
import { BirdzNotif, useNotif, SearchField } from '@applications-terrains/birdz-react-library';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGetErps, useGetModuleFamilies } from '../../../hooks/datarefs';

const Clients = () => {
  const endpoint = '/api/boi/extractor/clients/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const [listFields, setListFields] = useState<ListField[]>([]);
  const [clientNames, setClientNames] = useState<string[]>([]);
  const { notif, notifOptions } = useNotif();
  const { data: erps } = useGetErps();
  const { data: moduleFamilies } = useGetModuleFamilies();

  useEffect(() => {
    if (!erps) return;

    const listFields = [
      { name: 'name', label: 'Nom', orderable: true },
      {
        name: 'erp',
        label: 'ERP',
        transform: (value: number[]) => {
          return value
            .map((erpId) => {
              const erp = erps?.find((erp) => erp.id === erpId);
              return erp?.name;
            })
            .join(', ');
        }
      },
      {
        name: 'module_families',
        label: 'Familles de modules',
        className: 'text-center',
        transform: (value: any) => {
          return value.join(', ');
        }
      },
      {
        name: 'jobs',
        label: 'Nombre de jobs',
        transform: (value: any) => {
          return value.length;
        }
      }
    ];
    setListFields(listFields);
  }, [erps]);

  const actions = [
    {
      name: 'edit',
      label: 'Modifier le client',
      render: (group: any) => {
        return (
          <IconButton component={Link} to={`/boi/extractor/clients/edit/${group.id}`}>
            <EditIcon fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-client',
      label: 'Supprimer le client',
      render: (model: any) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer le client',
                content: 'Êtes-vous sûr de vouloir supprimer ce client?',
                onValidate: () => {
                  axios.delete(`${endpoint}${model.id}/`).then(
                    () => {
                      notif({
                        content: 'Le client a été supprimé avec succès',
                        type: 'success'
                      });
                      document.location.reload();
                    },
                    () => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  const searchFields: SearchField[] = [
    {
      name: 'name',
      label: 'Client',
      type: 'select',
      options: {
        searchIsMulti: false,
        values: clientNames?.map((name) => {
          return { value: name, label: name };
        })
      }
      // options: {
      //   identifier: 'name',
      //   source: endpoint,
      //   label: 'name',
      //   searchIsMulti: false
      // }
    },
    {
      name: 'module_families',
      label: 'Familles de module',
      type: 'select',
      options: {
        values: moduleFamilies?.map((moduleFamily) => {
          return { value: moduleFamily.Name, label: moduleFamily.Name };
        })
      }
    }
  ];

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Liste des clients</BirdzTitle>
        </Grid>
        <Grid item>
          <Link to="/boi/extractor/clients/add">
            <Button variant="contained" sx={{ mt: 3 }}>
              <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un client
            </Button>
          </Link>
        </Grid>
      </Grid>

      <ListPage
        endpoint={endpoint}
        fields={listFields}
        actions={actions}
        searchFields={searchFields}
        onLoadItems={(clients) => setClientNames(clients.map((client) => client.name))}
      />
      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default Clients;
