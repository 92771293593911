import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CrInterventionEvent } from '../Cases/Types';
import moment from 'moment';
import { authService } from '../../..';
import { ListPage } from '@applications-terrains/birdz-react-library';

type InterventionEventsProps = {
  interventionEvents: CrInterventionEvent[];
  crCaseEvents: CrInterventionEvent[];
};

export default function InterventionEvents({
  interventionEvents,
  crCaseEvents
}: InterventionEventsProps) {
  return (
    <Paper variant="outlined" square sx={{ mt: 2 }}>
      <Accordion defaultExpanded sx={{ boxShadow: 'none' }} disableGutters={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h3>Évènements</h3>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {authService.canAccessBOI() && (
              <Grid item xs={6}>
                <h4>Évènements du dossier</h4>
                <ListPage
                  fields={[
                    {
                      name: 'date',
                      label: 'Date',
                      transform: (date: string) => {
                        return moment(date, 'YYYY-MM-DD HH:mmZ').format('DD/MM/YYYY à HH:mm');
                      }
                    },
                    {
                      name: 'step__name',
                      label: 'Étape'
                    }
                  ]}
                  loadedData={crCaseEvents}
                  displayPaginationOptions={false}
                  displayResultsNumber={false}
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <h4>Évènements de l'intervention</h4>
              <ListPage
                fields={[
                  {
                    name: 'date',
                    label: 'Date',
                    transform: (date: string) => {
                      return moment(date, 'YYYY-MM-DD HH:mmZ').format('DD/MM/YYYY à HH:mm');
                    }
                  },
                  {
                    name: 'step__name',
                    label: 'Étape'
                  }
                ]}
                loadedData={interventionEvents}
                displayPaginationOptions={false}
                displayResultsNumber={false}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}
