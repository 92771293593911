import { isArray } from 'lodash';
import React from 'react';

const formatErrorNotifObject = ({
  error,
  translations
}: {
  error: { [key: string]: string[] | string } | string;
  translations?: { [key: string]: { label: string } };
}) => {
  if (error instanceof Object) {
    const errorsList = Object.entries(error);
    return {
      type: 'error',
      content: (
        <div className="d-flex flex-column">
          <>{'Veuillez corriger les erreurs sur le formulaire :'}</>
          <ul>
            {errorsList.map(([title, content]) => {
              const errorLabel =
                translations && title in translations
                  ? translations[title as keyof typeof translations].label
                  : title;
              const contentToDisplay = isArray(content)
                ? content[0]
                : typeof content === 'string'
                  ? content
                  : JSON.stringify(content);
              return (
                <li key={errorLabel} className="d-flex gap-50">
                  <strong>{errorLabel}:</strong>
                  <div>{contentToDisplay}</div>
                </li>
              );
            })}
          </ul>
        </div>
      )
    };
  } else
    return {
      type: 'error',
      content: error
    };
};

export default formatErrorNotifObject;
