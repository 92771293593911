import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  AppPaper,
  BirdzSubtitle,
  BirdzTitle,
  ListField,
  ListPage,
  SearchField
} from '@applications-terrains/birdz-react-library';
import { authService } from '../../..';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { formatToSelectOptions, useGetContracts } from '../../../hooks/datarefs';

const Interventions = ({ displayFilters }: { displayFilters?: boolean }) => {
  const endpoint = authService.getEndpoint(
    '/api/boi/cases/cr-interventions/',
    '/api/boe/cases/cr-interventions-details/'
  );
  const [listFields, setListFields] = useState<ListField[]>([]);
  const [searchFields, setSearchFields] = useState<SearchField[]>([]);

  let contracts: any[] = [];
  if (authService.canAccessBOI()) {
    const { data } = useGetContracts();
    if (data) contracts = data;
  }

  useEffect(() => {
    let listFields: ListField[] = [
      {
        name: 'id',
        label: 'N° intervention',
        orderable: true
      },
      {
        name: 'cr_case__number',
        label: 'Dossier Rattaché',
        orderable: true,
        className: 'text-center'
      },
      {
        name: 'cr_case__status__label',
        label: 'Statut dossier'
      },
      {
        name: 'scheduled_start_date',
        label: 'Date intervention',
        transform: (value: any) => {
          return (value && moment(value).format('DD/MM/YYYY à HH:mm')) || '';
        },
        orderable: true,
        options: { width: '210px' }
      },
      {
        name: 'status',
        label: 'Statut intervention',
        transform: (value: any, row: any) => {
          return row?.status__name;
        },
        orderable: true
      }
    ];

    const BOIActions: ListField[] = [
      {
        name: 'action',
        label: 'Action',
        className: 'text-center',
        transform: (value: any, row: any) => {
          return (
            <IconButton component={Link} to={`/boi/cr/interventions/edit/${row.id}`} id="edit">
              <SearchIcon fontSize="small" />
            </IconButton>
          );
        }
      }
    ];

    const BOEActions: ListField[] = [
      {
        name: 'action',
        label: 'Détails',
        className: 'text-center',
        transform: (value: any, row: any) => {
          return (
            <IconButton
              component={Link}
              to={`/boe/cr/interventions/details/${row.id}`}
              id="details"
            >
              <SearchIcon fontSize="small" />
            </IconButton>
          );
        }
      },
      {
        name: 'show-feedback',
        label: 'Retour',
        className: 'text-center',
        transform: (value: any, row: any) => {
          return row.end_intervention_date ? (
            <IconButton
              component={Link}
              to={`/boe/cr/interventions/details/${row.id}#retour`}
              id="details_back"
            >
              <SearchIcon fontSize="small" />
            </IconButton>
          ) : (
            <>-</>
          );
        }
      }
    ];

    const searchFields = [
      {
        name: 'contract_code',
        id: 'search_by_contract',
        label: 'Code contrat',
        type: 'select',
        options: {
          values: formatToSelectOptions(contracts, {
            label: 'contract_management_code',
            value: 'contract_management_code'
          })
        },
        className: 'col-sm-6'
      },
      {
        name: 'created_at_after',
        id: 'search_by_date_after',
        label: 'Entre le ',
        type: 'datepicker',
        className: 'col-sm-6'
      },
      {
        name: 'created_at_before',
        id: 'search_by_date_before',
        label: 'et le',
        type: 'datepicker',
        className: 'col-sm-6'
      },
      {
        name: 'failure_code',
        id: 'search_by_failure_code',
        label: 'Code panne initiale',
        options: {
          identifier: 'id',
          source: authService.canAccessBOI()
            ? '/api/boi/params/failure-codes/'
            : authService.canAccessBOE()
              ? '/api/boe/params/failure-codes/'
              : '',
          label: 'label',
          searchIsMulti: false
        },
        className: 'col-sm-6'
      }
    ];

    if (authService.canAccessBOI()) {
      // Add specific listfields
      listFields.splice(2, 0, {
        name: 'subcontractor__name',
        label: 'Sous Traitant',
        orderable: true
      });
      // Add actions
      listFields = [...listFields, ...BOIActions];

      // Add specific searchFields
      searchFields.splice(0, 0, {
        name: 'cr_case__number',
        id: 'search_by_crCase',
        label: 'N° Dossier référent',
        options: {
          identifier: 'number',
          source: '/api/boi/cases/cr-cases/',
          label: 'number',
          searchIsMulti: false
        },
        className: 'col-sm-6'
      });
      searchFields.splice(1, 0, {
        name: 'subcontractor',
        id: 'search_by_subcontractor',
        label: 'Sous-traitant',
        options: {
          identifier: 'id',
          source: '/api/boi/subcontractors/subcontractors/',
          label: 'name',
          searchIsMulti: false
        },
        className: 'col-sm-6'
      });
    } else if (authService.canAccessBOE()) {
      // Add actions
      listFields = [...listFields, ...BOEActions];
    }

    setListFields(listFields);
    setSearchFields(searchFields);
  }, []);

  return (
    <AppPaper>
      <BirdzTitle>Liste des interventions</BirdzTitle>
      {authService.canAccessBOI() && (
        <BirdzSubtitle>
          Pour une nouvelle intervention, veuillez passer par un dossier
        </BirdzSubtitle>
      )}
      <ListPage
        endpoint={endpoint}
        fields={listFields}
        searchFields={
          typeof displayFilters === 'undefined' || displayFilters === true
            ? searchFields
            : undefined
        }
        paginationOptions={{
          pageSize: 25
        }}
        defaultOrder={['-updated_at']}
      />
    </AppPaper>
  );
};

export default Interventions;
