import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Box, Grid, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import {
  AppPaper,
  BirdzNotif,
  BirdzTitle,
  useNotif
} from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import { Export } from './types/types';
import ReRunJobHandler from './ReRunJobHandler';
import { downloadFile } from '../../../utils';
import './exports.scss';
import DisplayStatus, { StatusesProps } from './DisplayStatus';

const ExportDetail = () => {
  const { id } = useParams();
  const { notif, notifOptions } = useNotif();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exportDetail, setExportDetail] = useState<Export>();
  const [fileUrl, setFileUrl] = useState<string>('');

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axios
        .get(`/api/boi/extractor/jobs-executions/${id}/`)
        .then((response: AxiosResponse<Export>) => {
          setExportDetail(response.data);
          response.data.export_file_name &&
            axios
              .get(`/api/boi/extractor/jobs-executions/${response.data.id}/get-file-url/`)
              .then((res: AxiosResponse<{ signed_url: string }>) =>
                setFileUrl(res.data.signed_url)
              );
          setIsLoading(false);
        })
        .catch(() => {
          notif({
            content: 'Une erreur est survenue lors de la suppression',
            type: 'error'
          });
        });
    }
  }, [id]);

  const P = (props: PropsWithChildren) => (
    <p style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{props.children}</p>
  );

  return (
    <AppPaper>
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            height: 'calc(100vh - 8.5rem)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid justifyContent="space-between" container sx={{ alignItems: 'center' }}>
            <Grid item>
              <BirdzTitle>Export {exportDetail?.job.client_name}</BirdzTitle>
            </Grid>
            {exportDetail && <ReRunJobHandler exportObj={exportDetail} small={false} />}
          </Grid>
          <Grid container item>
            <Grid item xs={6} sx={{ display: 'flex' }}>
              <Box sx={{ marginRight: '1rem' }}>
                <p>Statut</p>
                <p>Date</p>
                <p>ERP - Format</p>
                <p>Nom du fichier</p>
                <p>Serveur</p>
                <p>Utilisateur</p>
                <p>Type d'envoi</p>
              </Box>
              <Box>
                {exportDetail?.status ? (
                  <DisplayStatus
                    statusLabel={exportDetail?.status as StatusesProps['statusLabel']}
                    className="mt-25"
                  />
                ) : (
                  <P>-</P>
                )}

                <P>
                  {exportDetail?.started_at
                    ? 'le ' +
                      moment(exportDetail?.started_at).format('DD/MM/YYYY') +
                      ' à ' +
                      moment(exportDetail?.started_at).format('HH:mm')
                    : '-'}
                </P>
                <P>
                  {exportDetail?.job.erp_name ?? '-'} -{' '}
                  {exportDetail?.job.export_format_name ?? '-'}
                </P>
                <>
                  {fileUrl && exportDetail?.export_file_name ? (
                    <Grid
                      item
                      className="downloadFile"
                      onClick={() =>
                        downloadFile(
                          `/api/boi/extractor/jobs-executions/${id}/file/`,
                          exportDetail?.export_file_name as string
                        )
                      }
                    >
                      {exportDetail?.export_file_name}
                    </Grid>
                  ) : (
                    '-'
                  )}
                </>
                <P>{exportDetail?.job.credentials_sending_export.server ?? '-'}</P>
                <P>{exportDetail?.job.credentials_sending_export.user ?? '-'}</P>
                <P>{exportDetail?.job.credentials_sending_export.sending_type ?? '-'}</P>
              </Box>
              <Box></Box>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex' }}>
              <Box sx={{ marginRight: '1rem' }}>
                <p>Détail erreur</p>
                <p>Client</p>
                <p>Famille de modules</p>
                <p>Type d'interventions</p>
                <p>Port</p>
                <p>Mot de passe</p>
                <p>Déclenchement</p>
              </Box>
              <Box>
                <P>{exportDetail?.error_detail ?? '-'}</P>
                <P>{exportDetail?.job.client_name ?? '-'}</P>
                <P>
                  {exportDetail?.job.module_families.length
                    ? exportDetail?.job.module_families.map(
                        (family, index) =>
                          `${family}${index < exportDetail?.job.module_families.length - 1 ? ', ' : ''}`
                      )
                    : '-'}
                </P>
                <P>
                  {exportDetail?.job.intervention_types.length
                    ? exportDetail?.job.intervention_types.map(
                        (type, index) =>
                          `${type}${index < exportDetail?.job.intervention_types.length - 1 ? ', ' : ''}`
                      )
                    : '-'}
                </P>
                <P>{exportDetail?.job.credentials_sending_export.port ?? '-'}</P>
                <P>{exportDetail?.job.credentials_sending_export.password ? '**********' : '-'}</P>

                {exportDetail?.trigger_type === 'AUTOMATICALLY' ? (
                  <P>automatique</P>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      fontSize: 'smaller',
                      gap: 0.5
                    }}
                  >
                    <>manuel</>
                    <span>&#40;par</span> <i className="highlight">{exportDetail?.executed_by}</i>
                    <span> pour le motif : </span>
                    <span>
                      <i className="highlight">{exportDetail?.raison}</i>
                      &#41;
                    </span>
                  </Box>
                )}
              </Box>
            </Grid>
            {exportDetail?.min_intervention_validation_date !== null &&
              exportDetail?.max_intervention_validation_date !== null && (
                <P>
                  Interventions validées entre le{' '}
                  {'le ' +
                    moment(exportDetail?.min_intervention_validation_date).format('DD/MM/YYYY') +
                    ' à ' +
                    moment(exportDetail?.min_intervention_validation_date).format('HH:mm') +
                    ' et le ' +
                    moment(exportDetail?.max_intervention_validation_date).format('DD/MM/YYYY') +
                    ' à ' +
                    moment(exportDetail?.max_intervention_validation_date).format('HH:mm')}
                </P>
              )}
          </Grid>
        </>
      )}
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default ExportDetail;
