import { Button } from '@mui/material';
import React from 'react';
import { ModalItem } from '../DMSTypes';
import moment from 'moment';
import {
  BirdzModal,
  BirdzModalActions,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';

type DMSModalInformationsItemProps = {
  item: Partial<ModalItem>;
  onClose(): void;
};

export const DMSModalInformationsItem = ({ item, onClose }: DMSModalInformationsItemProps) => {
  return (
    <BirdzModal open={true}>
      <BirdzModalTitle>
        Détails du {item.type === 'file' ? 'fichier' : 'dossier'} : {item.name}
      </BirdzModalTitle>
      <BirdzModalContent>
        Créé le : {moment(item?.meta?.created_at).format('DD/MM/YYYY à HH[h]mm')}
        <br />
        {item.type === 'file' && (
          <>
            Taille : {item?.meta?.size}
            <br />
            Type de fichier : {item?.meta?.mimeType}
            <br />
          </>
        )}
        Créé par : {item?.meta?.created_by}
      </BirdzModalContent>
      <BirdzModalActions>
        <Button variant="contained" id="closeDetails" onClick={() => onClose()}>
          Retour
        </Button>
      </BirdzModalActions>
    </BirdzModal>
  );
};
