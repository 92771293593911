import {
  BirdzModal,
  BirdzModalActions,
  BirdzModalContent,
  BirdzModalTitle,
  BirdzNotif,
  useNotif
} from '@applications-terrains/birdz-react-library';
import { Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import Redo from '@mui/icons-material/Redo';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Export } from './types/types';

const ReRunJobHandler = ({ exportObj, small = true }: { exportObj: Export; small?: boolean }) => {
  const { notif, notifOptions } = useNotif();

  const [reason, setReason] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
    setReason('');
  };

  const ActionButton: React.ElementType = small ? IconButton : Button;

  return (
    <>
      <ActionButton onClick={() => setModalOpen(true)} variant={small ? 'text' : 'outlined'}>
        <Redo fontSize="small" />
        {!small && <span style={{ marginLeft: '0.5rem' }}>relancer l'export</span>}
      </ActionButton>
      <BirdzModal open={modalOpen}>
        <BirdzModalTitle>Relancer l'export</BirdzModalTitle>
        <BirdzModalContent>
          <div>
            <div style={{ marginBottom: '1rem' }}>
              Veuillez saisir un motif afin de relancer l'export :
            </div>
            <TextareaAutosize
              minRows={3}
              onChange={(e) => setReason(e.target.value)}
              style={{ width: '100%' }}
            />
          </div>
        </BirdzModalContent>
        <BirdzModalActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button
            onClick={() => {
              axios
                .post(`/api/boi/extractor/jobs/${exportObj.job.id}/run-job/`, {
                  raison: reason
                })
                .then(
                  () => {
                    notif({
                      content: 'Le job à été relancé avec succès',
                      type: 'success'
                    });
                  },
                  () => {
                    notif({
                      content: 'Une erreur est survenue',
                      type: 'error'
                    });
                  }
                );
              handleClose();
            }}
            disabled={reason.length === 0}
          >
            Envoyer
          </Button>
        </BirdzModalActions>
      </BirdzModal>
      <BirdzNotif options={notifOptions} />
    </>
  );
};

export default ReRunJobHandler;
