import React from 'react';
import moment from 'moment';
import { ListPage } from '@applications-terrains/birdz-react-library';

type ModuleData = {
  module_address: string;
  contract: string;
  city: string;
  longitude: number;
  latitude: number;
  device_type: any;
  average_exclusive_module: any;
  contract_label: string;
  contract_exp_date: any;
  pdc_id: string | null;
  phone: string | null;
};

type CallHistoryProps = {
  moduleData?: ModuleData;
};

export default function CallHistory({ moduleData }: CallHistoryProps) {
  return (
    <ListPage
      name=""
      filters={'&device_id=' + moduleData?.module_address}
      endpoint="/api/boi/devices/call-history/"
      fields={[
        {
          name: 'date',
          label: 'Date du fichier',
          transform: (value: any) => {
            return (value && moment(value).utc().format('DD/MM/YYYY à HH:mm:ss')) || '';
          }
        },
        { name: 'device_id', label: 'Addresse du CR' },
        { name: 'call_reason_label', label: "Raison de l'appel" }
      ]}
      paginationOptions={{ pageSize: 10 }}
    />
  );
}
