import * as React from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import moment from 'moment';
import axios from 'axios';
import {
  StyledTableBodyRow,
  StyledTableCell,
  StyledTableHeaderCell
} from '../../../../styles/Table.styled';
import { TableRow, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  BirdzModal,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';

interface ModalProps {
  day: number;
}

interface HoursProps {
  chanceofsnow: number;
  hourly_end: string;
  hourly_start: string;
  humidity: number;
  precip: number;
  temperature: number;
  wind_speed: number;
  windgust: number;
}

interface weatherDetailsDataProps {
  date_of_day: string;
  hours: HoursProps[];
}

const WeatherContitionsModal = ({ day }: ModalProps) => {
  const [weatherDetailsData, setWeatherDetailsData] = useState<weatherDetailsDataProps>();
  const [open, setOpen] = useState(false);
  const [requestedDay, setRequestedDay] = useState<number>();

  const handleOpen = () => {
    setOpen(true);
    setRequestedDay(day);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (requestedDay !== undefined) {
      axios
        .get(
          '/api/boi/external/weather-conditions-per-day/?latitude=45.778013280618765&longitude=4.875794224827663&day=' +
            requestedDay
        )
        .then((response: any) => {
          setWeatherDetailsData(response.data);
        });
    }
  }, [requestedDay]);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <SearchIcon />
      </IconButton>
      <BirdzModal open={open} onClose={handleClose} maxWidth={'lg'}>
        <BirdzModalTitle>
          {weatherDetailsData && (
            <div>
              Détails Météo {requestedDay === 0 && "Aujourd'hui"}{' '}
              {requestedDay !== 0 && 'J' + requestedDay}{' '}
              {moment(weatherDetailsData.date_of_day).format('DD/MM/YYYY')}
            </div>
          )}
        </BirdzModalTitle>
        <BirdzModalContent>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableHeaderCell align="center">
                      <strong>Heure</strong>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell align="center">
                      <strong>Température (°C)</strong>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell align="center">
                      <strong>Humidité (%)</strong>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell align="center">
                      <strong>Vent (km/h)</strong>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell align="center">
                      <strong>Rafales (km/h)</strong>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell align="center">
                      <strong>Précipitations (mm)</strong>
                    </StyledTableHeaderCell>
                    <StyledTableHeaderCell align="center">
                      <strong>Neige (mm)</strong>
                    </StyledTableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {weatherDetailsData &&
                    weatherDetailsData.hours.map((row) => (
                      <StyledTableBodyRow key={row.hourly_start}>
                        <StyledTableCell key={row.hourly_start} align="center">
                          {moment(row.hourly_start, 'HH:mm:ss').format('HH[h]mm')} -{' '}
                          {moment(row.hourly_end, 'HH:mm:ss').format('HH[h]mm')}
                        </StyledTableCell>
                        <StyledTableCell key={row.temperature} align="center">
                          {Number(row.temperature).toFixed(1)}
                        </StyledTableCell>
                        <StyledTableCell key={row.humidity} align="center">
                          {Number(row.humidity).toFixed(0)}
                        </StyledTableCell>
                        <StyledTableCell key={row.wind_speed} align="center">
                          {Number(row.wind_speed).toFixed(1)}
                        </StyledTableCell>
                        <StyledTableCell key={row.windgust} align="center">
                          {Number(row.windgust).toFixed(1)}
                        </StyledTableCell>
                        <StyledTableCell key={row.precip} align="center">
                          {Number(row.precip).toFixed(0)}
                        </StyledTableCell>
                        <StyledTableCell key={row.chanceofsnow} align="center">
                          {Number(row.chanceofsnow).toFixed(0)}
                        </StyledTableCell>
                      </StyledTableBodyRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </BirdzModalContent>
      </BirdzModal>
    </>
  );
};

export default WeatherContitionsModal;
