import React, { useEffect } from 'react';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const PasswordReset = () => {
  const { notif, notifOptions } = useNotif();
  const [password, setPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');

  const [modalOpened, setModalOpened] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = React.useState<string>('');

  useEffect(() => {
    const token = location.hash.replace('#', '');
    if (!token) {
      notif({
        content: 'Une erreur est survenue lors de la réinitialisation du mot de passe.',
        type: 'error'
      });
      closeModal();
    }
    setToken(token);
  }, [location.hash]);

  const resetPassword = () => {
    axios
      .post('/api/auth/reset-password/', {
        password,
        token
      })
      .then(() => {
        notif({
          content: 'Votre mot de passe a bien été réinitialisé.',
          type: 'success'
        });
      })
      .catch((error) => {
        notif({
          content: (
            <>
              Une erreur est survenue lors de la réinitialisation du mot de passe
              <br />
              {error?.response?.data}
            </>
          ),
          type: 'error'
        });
      })
      .finally(() => {
        closeModal();
      });
  };

  const closeModal = () => {
    setTimeout(() => {
      setModalOpened(false);
      navigate('/auth');
    }, 1500);
  };

  return (
    <>
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={modalOpened}
      >
        <DialogTitle>Réinitialisation du mot de passe</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mb: 2 }}>Veuillez saisir votre nouveau mot de passe</Box>
          <TextField
            label="Mot de passe"
            type="password"
            variant="outlined"
            fullWidth
            onChange={(e: any) => {
              setPassword(e.target.value);
            }}
          />
          <TextField
            label="Confirmation mot de passe"
            type="password"
            variant="outlined"
            fullWidth
            sx={{ mt: 1 }}
            onChange={(e: any) => {
              setConfirmPassword(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} key="button-cancel">
            Annuler
          </Button>
          <Button
            disabled={!password || password !== confirmPassword}
            variant="contained"
            onClick={() => {
              resetPassword();
            }}
            key="button-validate"
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
      <BirdzNotif options={notifOptions} />
    </>
  );
};

export default PasswordReset;
