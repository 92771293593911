import { PatrimonyItem } from '../../CR/Cases/Types';

export enum MRCASETYPE {
  MAINTENANCE = 1,
  DEPLOYMENT = 2
}

type MrCasePatrimony = {
  [deviceId: string]: PatrimonyItem;
};

export type MrCaseDevice = {
  device: string;
  intervention_type: number | null;
  mr_case?: number;
  request_id?: number;
  request_name?: string;
};

export type MrCase = {
  action_type?: number;
  comment?: string;
  created_at?: number;
  devices: MrCaseDevice[];
  equipments_count?: {
    BRIDGE: number;
    CELLULE: number;
    REPETEUR: number;
  };
  events_history?: MrCaseEvents[];
  folder_id?: string;
  folder_name?: string;
  has_interventions?: boolean;
  id?: number;
  intervention_type?: number;
  interventions?: {
    interventions?: MrCaseIntervention[];
    nbInterventions?: number;
    actions?: MrCaseInterventionsAction[];
    nbActions?: number;
  };
  network_topology?: string;
  number?: string;
  observation?: string;
  patrimony: MrCasePatrimony;
  status?: number;
  status_name?: string;
  subcontractor?: string;
  type?: number;
  updated_at?: number;
  phoenix_zone_id?: number;
};

export type MrCaseIntervention = {
  dateRetour: string;
  id: number;
  interventionType: string;
  isSyncOSS: 'true' | 'false';
  interventionComment: string;
  radioAddress: string;
  status: string;
};

export type MrCaseInterventionsAction = {
  date: string;
  status: string;
  type: string;
};

export type MrCaseInterventionDetail = {
  subcontractor: number;
  interventionType: string;
  radioAddress: string;
  interventionComment: string;
  closingDate: string;
  actions: [
    {
      actionDetail: string;
      actionDate: string;
      actionType: string;
    }
  ];
  closingReason: string;
};

type MrCaseEvents = {
  case: number;
  created_at: number;
  date: string;
  id: number;
  step: number;
  step__code: string;
  step__name: string;
  updated_at: number;
};
