import React, { useEffect, useState } from 'react';
import {
  AppPaper,
  BirdzNotif,
  BirdzTitle,
  ListField,
  ListPage,
  SearchField,
  SearchForm,
  useNotif
} from '@applications-terrains/birdz-react-library';
import { formatToSelectOptions, useGetCrContracts, useGetYears } from '../../../hooks/datarefs';
import { Grid } from '@mui/material';
import CrStatisticsDetail from './CrStatisticsDetail';
import { PiFileCsv } from 'react-icons/pi';

export default function CrPlus() {
  const endpoint = '/api/boi/cases/cr-cases/';
  const [filterString, setFilterString] = useState<string>('');
  const [searchFields, setSearchFields] = useState<SearchField[]>([]);
  const { notif, notifOptions } = useNotif();

  const { data: contracts } = useGetCrContracts();
  const { data: years } = useGetYears();

  useEffect(() => {
    const searchFields: SearchField[] = [
      {
        name: 'pdc_pdr',
        label: 'Numéro PDC',
        type: 'text'
      },
      {
        name: 'device_id',
        label: 'Numéro CR',
        type: 'text'
      },
      {
        name: 'contract_code_label',
        label: 'Contrat',
        type: 'select',
        options: {
          values: contracts
            ? contracts?.map((contract: any) => {
                return {
                  value: contract,
                  label: contract
                };
              })
            : []
        }
      },
      {
        name: 'city_id',
        label: 'Commune / CP',
        type: 'autocomplete',
        options: {
          identifier: 'id',
          source: '/api/boi/params/cities/',
          label: 'city_zipcode',
          searchIsMulti: false,
          formatResponse: (response: any) => {
            return {
              label: `${response.label} - ${response.zip_code}`,
              value: response.id,
              raw: response
            };
          }
        }
      },
      {
        name: 'subcontractor',
        label: 'Sous-traitant',
        options: {
          identifier: 'id',
          label: 'name',
          source: '/api/boi/subcontractors/subcontractors/?is_active=true&'
        }
      },
      {
        name: 'with_intervention',
        label: 'Avec interventions',
        type: 'select',
        options: {
          values: [
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' }
          ]
        }
      },
      {
        name: 'year_id',
        label: 'Année',
        type: 'select',
        options: {
          values: formatToSelectOptions(years) || []
        }
      },
      {
        name: 'created_at_after',
        label: 'Entre le ',
        type: 'datepicker'
      },
      {
        name: 'created_at_before',
        label: 'et le',
        type: 'datepicker'
      }
    ];
    setSearchFields(searchFields);
  }, [contracts, years]);

  const listFields: ListField[] = [
    {
      name: 'number',
      label: "Numéro d'incident"
    },
    {
      name: 'type__name',
      label: 'Type'
    },
    {
      name: 'pdc_pdr',
      label: 'PDC/PDR'
    },
    {
      name: 'device_id',
      label: 'Adresse radio'
    },
    {
      name: 'contract',
      label: 'Contrat',
      transform: (value: any, item: any) => {
        return item.contract_code + ' - ' + item.contract_label;
      }
    }
  ];

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Rapport maintenance CR+</BirdzTitle>
        </Grid>
      </Grid>

      <SearchForm
        fields={searchFields}
        onSubmit={(filterString) => {
          setFilterString(filterString);
        }}
      />

      <CrStatisticsDetail filter={filterString} />

      <ListPage
        endpoint={endpoint}
        fields={listFields}
        filters={filterString}
        defaultOrder={['-updated_at']}
        exportButtons={[
          {
            name: 'Exporter en csv',
            fileName: 'statistiques_cr_plus',
            type: 'csv',
            icon: <PiFileCsv />,
            requestEndpoint: '/api/boi/cases/statistics-csv-export/',
            keepQueryParams: false,

            onSuccess: () => {
              notif({
                type: 'success',
                content: 'Export réussi'
              });
            }
          }
        ]}
      />
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
}
