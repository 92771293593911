import React from 'react';
import { Box } from '@mui/material';
import DMSManager from '../../DMS/DMSManager';
import { AssociatedDrive } from '../../DMS/DMSTypes';
import {
  BirdzModal,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';

interface CrCaseDriveAssociationModalProps {
  open: boolean;
  selectedValue: string;
  onClose: (id: string | undefined, name: string | undefined) => void;
}

export default function CrCaseDriveAssociationModal(
  driveAssociation: CrCaseDriveAssociationModalProps
) {
  const { onClose, open } = driveAssociation;
  const handleClose = (drive_id?: string, drive_name?: string) => {
    onClose(drive_id, drive_name);
  };
  return (
    <BirdzModal
      open={open}
      fullWidth={true}
      maxWidth={'md'}
      onClose={() => {
        handleClose();
      }}
    >
      <BirdzModalTitle onClose={() => handleClose()}>Association d'un drive</BirdzModalTitle>
      <BirdzModalContent>
        <Box sx={{ minHeight: '500px' }}>
          <DMSManager
            title=""
            readOnly={true}
            listOnlyFolders={true}
            onSelectDrive={(driveProps: AssociatedDrive) => {
              handleClose(driveProps.id, driveProps.name);
            }}
          />
        </Box>
      </BirdzModalContent>
    </BirdzModal>
  );
}
