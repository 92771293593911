import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  AppPaper,
  BirdzDialog,
  BirdzNotif,
  BirdzTitle,
  FormField,
  Item,
  ListField,
  ListPage,
  SearchField,
  useDialog,
  useNotif
} from '@applications-terrains/birdz-react-library';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { Link, useParams } from 'react-router-dom';
import { useGetAgents, useGetCompanies } from '../../hooks/datarefs';

type ParamsType = {
  id?: string;
};

const ALLOWEDFEATURES = [
  { value: 'maintenance_order', label: 'Interventions programmées' },
  {
    value: 'spontaneous_maintenance_order',
    label: "Interventions opportunistes accessibles depuis les fiches d'interventions"
  },
  { value: 'full_spontaneous_maintenance_order', label: 'Interventions opportunistes FULL' },
  {
    value: 'radio_diagnostic_infosit',
    label: "Diagnostic Infosit (accessible depuis l'onglet Paramètres)"
  },
  { value: 'radio_diagnostic_test_rf', label: "Test RF (accessible depuis l'onglet Paramètres)" },
  {
    value: 'radio_diagnostic_liste_rf',
    label: "Liste RF (accessible depuis l'onglet Paramètres)"
  },
  {
    value: 'radio_diagnostic_spy_iotr',
    label: "Spy iOTR (accessible depuis l'onglet Paramètres)"
  },
  { value: 'force_join_request', label: "Join request (accessible depuis l'onglet Paramètres)" },
  {
    value: 'apilink_reset_location_activity',
    label:
      "RAZ de la position et de l'activité (accessible depuis la fonction Poteau incendie dans l'onglet Paramètres)"
  },
  {
    value: 'apilink_reset_user_statistics',
    label:
      "RAZ des statistiques utilisateur (accessible depuis la fonction Poteau incendie dans l'onglet Paramètres)"
  },
  {
    value: 'apilink_reset_slope_fraud',
    label:
      "RAZ inclinaison et fraude (accessible depuis la fonction Poteau incendie dans l'onglet Paramètres)"
  },
  {
    value: 'apilink_stop_equipment',
    label:
      "Arrêt de l'équipement (accessible depuis la fonction Poteau incendie dans l'onglet Paramètres)"
  },
  {
    value: 'apilink_toggle_threshold_1_turn',
    label:
      "Seuil Ouvert/Fermé à 1 tour (accessible depuis la fonction Poteau incendie dans l'onglet Paramètres)"
  },
  {
    value: 'apilink_toggle_threshold_2_turns',
    label:
      "Seuil Ouvert/Fermé à 2 tours (accessible depuis la fonction Poteau incendie dans l'onglet Paramètres)"
  },
  {
    value: 'apilink_toggle_threshold_3_turns',
    label:
      "Seuil Ouvert/Fermé à 3 tours (accessible depuis la fonction Poteau incendie dans l'onglet Paramètres)"
  },
  {
    value: 'radio_diagnostic_wmbus_wake_up',
    label: 'Outils de wake-up Wmbus'
  },
  {
    value: 'security_key_download',
    label: 'Outils de téléchargement des clés'
  },
  {
    value: 'security_key_viewer',
    label: 'Outils pour voir les clés téléchargées'
  }
];

export default function BconnectUsers(params: any) {
  const { id } = useParams<ParamsType>();

  const endpoint = '/api/bconnect/webapp/customer/agents/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();

  const [listFields, setListFields] = useState<ListField[]>([]);

  const { data: companies } = useGetCompanies();
  const { data: agents } = useGetAgents();

  const agentState = [
    {
      value: 1,
      label: 'Actif'
    },
    {
      value: 2,
      label: 'Suspendu'
    }
  ];

  const agent_state = [
    {
      value: 1,
      label: 'Actif'
    },
    {
      value: 2,
      label: 'Suspendu'
    }
  ];

  useEffect(() => {
    const listFields: ListField[] = [
      {
        name: 'Id',
        label: 'Id Agent',
        orderable: true
      },
      {
        name: 'State',
        label: 'Statut',
        transform: (value: any) => {
          const state = agent_state.find((state: any) => state.value === value);
          return state?.label;
        },
        orderable: true
      },
      {
        name: 'Name',
        label: 'Nom',
        orderable: true
      },
      {
        name: 'Mail',
        label: 'Email',
        orderable: true
      },
      {
        name: 'CompanyId',
        label: 'Client',
        transform: (value: any) => {
          const customer: any = companies?.find((company: any) => company.Id === value);
          return 'Id ' + value + ' - ' + customer?.Name;
        },
        orderable: true
      },
      {
        name: 'AllowedFeatures',
        label: 'Droits',
        transform: (value: any) => {
          if (value.length === ALLOWEDFEATURES.length) {
            return 'Tous les droits';
          }
          if (value.length === 2) {
            return 'Interventions programmées + 1 autre';
          }
          if (value.length > 1 && value.length !== ALLOWEDFEATURES.length) {
            return 'Interventions programmées + ' + (value.length - 1) + '  autres';
          }
          if (value.length === 1) {
            const featureLabel: any = ALLOWEDFEATURES.find(
              (feature: any) => value[0] === feature.value
            );
            return featureLabel?.label;
          }
          return value;
        }
      }
    ];

    setListFields(listFields);
  }, [companies, agents]);

  const searchFields: SearchField[] = [
    {
      name: 'Agent',
      label: 'Nom',
      type: 'select',
      options: {
        identifier: 'value',
        label: 'label',
        values: agents?.map((agent) => {
          return { value: agent.Id, label: agent.Name };
        })
      }
    },
    {
      name: 'State',
      label: 'Statut',
      type: 'select',
      options: {
        identifier: 'value',
        label: 'label',
        values: agentState
      }
    }
  ];

  const formFields: FormField[] = [
    {
      name: 'User-Name',
      type: 'text',
      label: 'Nom*',
      validation: Yup.string()
        .required("Vous devez renseigner le nom de l'agent")
        .max(128, "Le nom de l'agent ne doit pas excéder 128 caractères")
    },
    {
      name: 'User-Mail',
      type: 'text',
      label: 'Adresse email*',
      validation: Yup.string()
        .required("L'email ne peut être vide")
        .email('Vous devez renseigner un e-mail valide')
        .max(100, "L'email de l'agent ne doit pas excéder 100 caractères")
        .test('checkEMailUnique', 'Un agent avec le même email existe déjà', (value: any) => {
          return new Promise((resolve) => {
            // Verify if an agent with the same mail is already registered
            const agentUsedMail = agents?.find((agent: any) => {
              return agent.Mail === value && (!id || (id && parseInt(id) !== agent.Id));
            });
            resolve(agentUsedMail === undefined);
          });
        })
    },
    {
      name: 'CompanyId',
      type: 'readonly',
      transform: (value: any) => {
        const customer: any = companies?.find((company: any) => company.Id === value);
        return customer?.Name;
      },
      label: 'Client'
    },
    {
      name: 'State',
      type: 'radio',
      label: 'Etat',
      options: {
        values: [
          { value: 1, label: 'Actif' },
          { value: 2, label: 'Suspendu' }
        ]
      }
    },
    {
      name: 'AllowedFeatures',
      label: 'Droits',
      type: 'select-multiple',
      options: {
        values: ALLOWEDFEATURES
      }
    }
  ];

  const transformItemOnLoad = (item: any) => {
    Object.keys(item.User).forEach((attribute: string) => {
      item['User-' + attribute] = item.User[attribute];
    });
    return item;
  };

  const transformItemOnSave = (item: any) => {
    const userObject = {
      Name: item['User-Name'],
      Mail: item['User-Mail'],
      CompanyId: item['CompanyId'],
      State: item['State'],
      AllowedFeatures: item['AllowedFeatures']
    };

    return userObject;
  };

  const actions = [
    {
      name: 'edit',
      label: "Modifier l'utilisateur",
      render: (user: any) => {
        return (
          <IconButton component={Link} to={`/bconnect/users/edit/${user.Id}`}>
            <EditIcon fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-user',
      label: "Supprimer l'utilisateur",
      render: (model: any) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: "Supprimer l'utilisateur",
                content: 'Êtes-vous sûr de vouloir supprimer cet utilisateur?',
                onValidate: () => {
                  axios.delete(`${endpoint}${model.Id}/`).then(
                    () => {
                      notif({
                        content: "L'utilisateur a été supprimé avec succès",
                        type: 'success'
                      });
                      document.location.reload();
                    },
                    () => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  return (
    <>
      <AppPaper>
        {params.action === 'list' && (
          <>
            <BirdzTitle>Liste des utilisateurs</BirdzTitle>
            <ListPage
              endpoint={endpoint}
              fields={listFields}
              searchFields={searchFields}
              actions={actions}
              paginationOptions={{
                pageSize: 25
              }}
            />
            <BirdzDialog options={dialogOptions} />
          </>
        )}
        {params.action === 'edit' && (
          <>
            <Item
              action="edit"
              endpoint={endpoint}
              fields={formFields}
              name="Modifier l'utilisateur"
              transformItemOnLoad={transformItemOnLoad}
              transformItemOnSave={transformItemOnSave}
              idField="Id"
            />
          </>
        )}
        <BirdzNotif options={notifOptions} />
      </AppPaper>
    </>
  );
}
