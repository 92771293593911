import React from 'react';
import { Alert, Box } from '@mui/material';
import './NotFound.scss';

const NotFound = () => {
  return (
    <>
      <Alert severity="info">Page introuvable</Alert>
      <Box
        className="error404"
        onMouseEnter={() => {
          // Restart animation on mouse enter
          const digits: any = document.getElementsByClassName('digits');
          for (const digit of digits) {
            digit.style.animation = 'none';
            digit.offsetHeight; /* trigger reflow */
            digit.style.animation = null;
          }
        }}
      >
        <div id="counter" className="animated">
          <ul className={`digits digits-first luckie-1`} id="test">
            {Array.from(Array(10).keys()).map((i) => (
              <li key={i}>{i}</li>
            ))}
          </ul>
          <ul className={`digits digits-second luckie-2`}>
            {Array.from(Array(10).keys()).map((i) => (
              <li key={i}>{i}</li>
            ))}
          </ul>
          <ul className={`digits digits-third luckie-1`}>
            {Array.from(Array(10).keys()).map((i) => (
              <li key={i}>{i}</li>
            ))}
          </ul>
        </div>
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="951.000000pt"
          height="672.000000pt"
          viewBox="0 0 951.000000 672.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,672.000000) scale(0.100000,-0.100000)"
            fill="#000000"
            stroke="none"
          >
            <path
              d="M4530 6399 c-1145 -87 -2146 -797 -2613 -1854 -56 -126 -127 -332
-157 -452 l-23 -93 -153 0 -152 0 -4 123 c-2 94 -7 131 -22 159 -27 55 -84
106 -144 128 -50 19 -78 20 -477 20 -372 0 -430 -2 -467 -17 -63 -24 -122 -76
-149 -130 l-24 -48 -3 -909 c-3 -1009 -5 -974 63 -1053 19 -23 56 -52 83 -65
l47 -23 450 0 c437 0 451 1 495 21 61 29 116 86 134 142 11 30 16 82 16 154
l0 108 153 0 154 0 13 -57 c7 -32 32 -119 56 -194 342 -1064 1244 -1874 2329
-2094 226 -46 363 -59 625 -59 264 0 394 13 627 60 334 68 713 219 1002 399
400 249 763 612 1009 1010 159 257 302 594 367 863 l17 72 153 0 153 0 4 -128
c3 -123 4 -131 33 -178 34 -55 111 -107 172 -118 67 -11 828 -7 883 5 70 15
140 72 168 137 l22 50 0 926 c1 818 -1 929 -15 962 -25 60 -59 97 -116 129
l-54 30 -445 0 c-498 0 -493 1 -568 -69 -69 -63 -82 -100 -82 -238 l0 -118
-154 0 -154 0 -17 73 c-65 266 -214 616 -368 864 -184 295 -499 640 -768 841
-618 461 -1351 678 -2099 621z m585 -204 c345 -44 619 -127 930 -280 284 -140
526 -312 754 -538 710 -701 1011 -1692 810 -2667 -162 -790 -673 -1503 -1373
-1915 -961 -565 -2158 -533 -3082 82 -564 375 -986 940 -1179 1577 -131 433
-161 919 -84 1351 143 797 588 1477 1265 1928 422 281 893 439 1429 481 97 8
412 -4 530 -19z m-3912 -1966 c12 -7 25 -17 29 -23 4 -6 8 -411 8 -901 -1
-778 -3 -893 -16 -910 -14 -19 -28 -20 -430 -23 l-414 -3 -25 25 -25 25 2 688
3 688 186 5 c203 5 210 7 229 65 9 27 8 40 -5 64 -27 54 -55 61 -245 61 l-170
0 0 96 c0 102 8 130 43 144 34 14 804 12 830 -1z m7964 -12 c15 -14 18 -37 20
-122 l3 -103 -185 -4 -185 -3 -27 -27 c-36 -36 -38 -97 -5 -130 21 -22 32 -23
210 -28 l187 -5 0 -693 0 -694 -24 -19 c-22 -18 -44 -19 -432 -19 -396 0 -410
1 -429 20 -20 20 -20 33 -20 913 0 490 4 897 8 903 21 32 48 34 454 31 376 -2
409 -3 425 -20z m-7471 -424 c-41 -150 -41 -835 0 -975 5 -17 -6 -18 -130 -18
l-136 0 0 505 0 505 136 0 c124 0 135 -1 130 -17z m6394 -488 l0 -505 -136 0
-136 0 6 28 c43 223 43 726 0 955 l-6 27 136 0 136 0 0 -505z"
            />
            <path
              d="M4445 5860 c-327 -47 -562 -119 -840 -257 -370 -184 -706 -474 -952
-823 -253 -358 -408 -778 -454 -1225 -18 -185 -7 -505 25 -686 93 -531 342
-1008 720 -1384 699 -695 1710 -933 2635 -619 610 207 1122 635 1434 1199 143
258 245 563 292 865 23 147 30 448 16 606 -81 875 -603 1651 -1381 2052 -301
155 -512 219 -937 281 -109 16 -418 11 -558 -9z m645 -195 c515 -72 995 -314
1360 -685 368 -373 591 -824 671 -1355 21 -138 19 -531 -4 -670 -43 -260 -111
-475 -222 -700 -349 -709 -1012 -1192 -1800 -1311 -165 -25 -504 -25 -665 -1
-264 41 -481 108 -715 222 -483 235 -864 618 -1099 1107 -162 335 -236 660
-236 1033 0 570 194 1101 563 1540 389 464 986 776 1592 834 135 13 414 6 555
-14z"
            />
            <path
              d="M4164 5066 c-24 -24 -34 -43 -34 -66 0 -40 26 -85 57 -99 16 -8 201
-11 578 -11 l553 0 31 26 c38 32 49 80 27 122 -34 65 -4 62 -619 62 l-559 0
-34 -34z"
            />
            <path
              d="M2985 4410 c-77 -9 -132 -38 -183 -95 -74 -84 -72 -66 -72 -736 0
-579 1 -600 21 -653 14 -40 35 -69 74 -104 98 -90 -66 -83 1960 -80 l1780 3
50 23 c63 29 109 73 143 137 l27 50 0 625 0 625 -23 42 c-35 66 -79 110 -140
140 l-57 28 -1760 1 c-968 1 -1787 -2 -1820 -6z m3582 -214 l28 -24 0 -591 0
-591 -28 -27 -27 -28 -1758 -3 c-1239 -2 -1768 0 -1794 8 -70 20 -68 0 -68
645 l0 577 29 29 29 29 1781 0 1780 0 28 -24z"
            />
            <path
              d="M4716 2575 c-34 -18 -35 -20 -123 -150 -84 -126 -202 -353 -242 -467
-90 -259 -79 -448 34 -594 92 -118 201 -175 349 -182 110 -5 193 15 274 67
142 92 222 240 222 409 -1 165 -97 418 -267 703 -124 207 -176 252 -247 214z
m116 -417 c128 -236 180 -379 181 -498 1 -85 -14 -127 -63 -184 -53 -59 -102
-81 -190 -81 -58 0 -84 5 -116 22 -83 44 -144 145 -144 238 0 80 48 235 115
374 69 141 132 251 144 251 4 0 37 -55 73 -122z"
            />
          </g>
        </svg>
      </Box>
    </>
  );
};
export default NotFound;
