import React, { useEffect, useState } from 'react';
import { AppPaper, BirdzTitle, FormField, Item } from '@applications-terrains/birdz-react-library';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ContractsDropdown from '../../Contracts/ContractsDropdown';

const ConfigurationDetail = () => {
  const endpoint = '/api/bstock/configurations/';
  const { id } = useParams();
  const [protocols, setProtocols] = useState<{ id: number; label: string }[]>([]);

  useEffect(() => {
    protocols.length === 0 &&
      axios.get('/api/boi/params/protocols/').then((res) => setProtocols(res.data.results));
  }, [protocols]);

  const formFields: FormField[] = [
    {
      name: 'name',
      type: 'text',
      label: 'Nom de la configuration',
      validation: Yup.string().required('Merci de renseigner le nom')
    },
    {
      name: 'contract',
      type: 'custom',
      label: 'Client',
      validation: Yup.string(),
      render: (value: string[], onChange: any) => {
        return (
          <ContractsDropdown
            required={true}
            isMulti={false}
            value={value}
            onChange={(data) => {
              onChange(data);
            }}
          />
        );
      }
    },
    {
      name: 'protocol',
      label: 'Protocole',
      type: 'select',
      validation: Yup.string().required('Merci de choisir un protocole'),
      options: {
        values: protocols?.map((protocol) => ({ label: protocol.label, value: protocol.id }))
      }
    },
    {
      name: 'dst',
      type: 'checkbox',
      label: 'DST'
    },
    {
      name: 'autoconnect',
      type: 'checkbox',
      label: 'Auto-connect'
    },
    {
      name: 'time_index',
      type: 'checkbox',
      label: 'Index horaire'
    }
  ];
  return (
    <AppPaper>
      <BirdzTitle>{id ? 'Modifier' : 'Ajouter'} une configuration</BirdzTitle>
      <AppPaper>
        <Box sx={{ mt: 3 }}>
          <Item action={id ? 'edit' : 'add'} endpoint={endpoint} fields={formFields} name="" />
        </Box>
      </AppPaper>
    </AppPaper>
  );
};

export default ConfigurationDetail;
