import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField
} from '@mui/material';
import { Form, Formik, FormikErrors } from 'formik';
import * as Yup from 'yup';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  BirdzModal,
  BirdzModalActions,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';

export const InterventionPricingsModal = ({ open, onClose, onValidate }: any) => {
  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);

  return (
    <BirdzModal open={open} onClose={onClose}>
      <BirdzModalTitle>Télécharger un devis</BirdzModalTitle>
      <BirdzModalContent>
        <Formik
          initialValues={{}}
          enableReinitialize={true}
          onSubmit={(values: any) => {
            setFormIsSubmitting(true);
            onValidate(values);
            return;
          }}
          validationSchema={Yup.object().shape({
            product_subcontractor_name: Yup.string().required('Champ obligatoire'),
            price: Yup.number()
              .required('Champ obligatoire')
              .typeError('Le champ doit être numérique'),
            file: Yup.mixed().required('Champ obligatoire')
          })}
        >
          {(props: any) => {
            const { isValid, isSubmitting, touched, errors, setFieldValue, values, getFieldProps } =
              props;
            const getError = (fieldName: string) => {
              const errorMessage = errors[fieldName as keyof FormikErrors<unknown>];
              return (!isValid || touched[fieldName as keyof FormikErrors<unknown>]) && errorMessage
                ? errorMessage
                : '';
            };

            return (
              <Form id="modalForm">
                <div>
                  <TextField
                    id="product_subcontractor_name"
                    autoFocus
                    margin="dense"
                    label="Libellé"
                    type="text"
                    fullWidth
                    error={getError('product_subcontractor_name')}
                    helperText={getError('product_subcontractor_name')}
                    {...getFieldProps('product_subcontractor_name')}
                    value={values.product_subcontractor_name || ''}
                  />
                  <TextField
                    id="price"
                    margin="dense"
                    label="Prix HT"
                    type="text"
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>,
                      autoComplete: 'off'
                    }}
                    error={getError('price')}
                    helperText={getError('price')}
                    {...getFieldProps('price')}
                    value={values.price || ''}
                  />

                  <Box sx={{ mt: 2 }}>
                    {!values.file ? (
                      <Box>
                        <Button variant="outlined" component="label">
                          <UploadIcon sx={{ mr: 1 }} /> Choisir un fichier
                          <TextField
                            margin="dense"
                            id="file"
                            label="Fichier PDF"
                            type="file"
                            fullWidth
                            variant="standard"
                            hidden
                            inputProps={{ accept: 'application/pdf' }}
                            sx={{ display: 'none' }}
                            onChange={(event: any) => {
                              setFieldValue('file', event?.currentTarget?.files[0]);
                            }}
                          />
                        </Button>
                        {getError('file') && (
                          <Box sx={{ color: '#dc3545', fontSize: '0.75rem' }}>
                            {getError('file')}
                          </Box>
                        )}
                        {isSubmitting && <LinearProgress />}
                      </Box>
                    ) : (
                      <Box>
                        {values?.file?.name}
                        <IconButton
                          sx={{ ml: 1 }}
                          onClick={() => setFieldValue('file', null)}
                          id="delete_button"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </div>
              </Form>
            );
          }}
        </Formik>
      </BirdzModalContent>
      <BirdzModalActions>
        <Button onClick={() => onClose(false)} id="cancel_button">
          Annuler
        </Button>
        <Button type="submit" form="modalForm" disabled={formIsSubmitting}>
          {formIsSubmitting ? <CircularProgress size={18} thickness={5} /> : <>Envoyer</>}
        </Button>
      </BirdzModalActions>
    </BirdzModal>
  );
};
