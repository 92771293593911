import React from 'react';
import InterventionsMap from './InterventionsMap';
import { AppPaper, BirdzTitle } from '@applications-terrains/birdz-react-library';

const HomePage = () => {
  return (
    <AppPaper>
      <BirdzTitle>BConnect</BirdzTitle>
      <InterventionsMap />
    </AppPaper>
  );
};
export default HomePage;
