import React, { useEffect, useState } from 'react';
import {
  AppPaper,
  BirdzTitle,
  ListField,
  ListPage,
  SearchField
} from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import { useGetAgents, useGetCompanies } from '../../../hooks/datarefs';

export default function NotationsList() {
  const endpoint = '/api/bconnect/webapp/administrator/notations/exportByActiveCriteria/';
  const [listFields, setListFields] = useState<ListField[]>([]);
  const [searchFields, setSearchFields] = useState<SearchField[]>([]);

  const { data: companies } = useGetCompanies();
  const { data: agents } = useGetAgents();

  const notesOptions = [
    {
      value: 1,
      label: '1'
    },
    {
      value: 2,
      label: '2'
    },
    {
      value: 3,
      label: '3'
    },
    {
      value: 4,
      label: '4'
    },
    {
      value: 5,
      label: '5'
    }
  ];

  useEffect(() => {
    if (!companies) {
      return;
    }

    const listFields: ListField[] = [
      {
        name: 'createdAt',
        label: 'Date de création',
        transform: (value: any) => {
          return 'Le ' + moment(value).format('DD-MM-YYYY') + ' à ' + moment(value).format('HH:mm');
        },
        orderable: true
      },
      {
        name: 'Agent.CompanyId',
        label: 'Client',
        transform: (value: any) => {
          const customer: any = companies && companies.find((company: any) => company.Id === value);
          if (value !== undefined) {
            return 'Id ' + value + ' - ' + customer?.Name;
          } else {
            return '(aucune donnée)';
          }
        }
      },
      {
        name: 'AgentId',
        label: 'Id Agent',
        orderable: true
      },
      {
        name: 'Agent.Name',
        label: 'Nom Agent'
      },
      {
        name: 'NotationCriteriaId',
        label: 'Id Critère',
        orderable: true
      },
      {
        name: 'NotationCriteria.DefaultName',
        label: 'Nom Critère'
      },
      {
        name: 'Value',
        label: 'Note / 5',
        orderable: true
      }
    ];

    const searchFields: SearchField[] = [
      {
        name: 'Client',
        label: 'Client',
        type: 'select',
        options: {
          identifier: 'value',
          label: 'label',
          values: companies.map((company: any) => {
            return { value: company.Id, label: company.Name };
          })
        }
      },
      {
        name: 'Agent',
        label: 'Agent',
        type: 'select',
        options: {
          identifier: 'value',
          label: 'label',
          values:
            agents &&
            agents.map((company: any) => {
              return { value: company.Id, label: company.Name };
            })
        }
      },
      {
        name: 'Value_equal_to',
        label: 'Note égale à',
        type: 'select',
        options: {
          identifier: 'value',
          label: 'label',
          values: notesOptions
        }
      },
      {
        name: 'Value_less_than',
        label: 'Note inférieure à',
        type: 'select',
        options: {
          identifier: 'value',
          label: 'label',
          values: notesOptions
        }
      },
      {
        name: 'Value_greater_than',
        label: 'Note supérieure à',
        type: 'select',
        options: {
          identifier: 'value',
          label: 'label',
          values: notesOptions
        }
      }
    ];

    setListFields(listFields);
    setSearchFields(searchFields);
  }, [companies, agents]);

  return (
    <AppPaper>
      <BirdzTitle>Liste des notations</BirdzTitle>

      <ListPage
        endpoint={endpoint}
        fields={listFields}
        searchFields={searchFields}
        paginationOptions={{
          pageSize: 25
        }}
      />
    </AppPaper>
  );
}
