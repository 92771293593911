import styled from 'styled-components';

import { TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';

export const StyledTableHeaderRow = styled(TableRow)`
  background-color: #dee2e6;
  border: 1px solid #dee2e6;
`;

export const StyledTableCell = styled(TableCell)`
  border-right: 1px solid #dee2e6;
`;

export const StyledTableHeaderCell = styled(TableCell)`
  background-color: #dee2e6;
`;

export const StyledTableBodyRow = styled(TableRow)`
  "&:nth-of-type(odd)": {
    backgroundColor: "#F9F9F9",
  },
`;

export const TableBoldCell = styled(TableCell)`
  background-color: #dee2e6;
  font-weight: bold;
`;
