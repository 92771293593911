import React from 'react';
import moment from 'moment';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Tooltip
} from '@mui/material';
import { Trans } from 'react-i18next';
import _ from 'lodash/fp';
import VisibilityIcon from '@mui/icons-material/RemoveRedEye';
import { StyledTableBodyRow, StyledTableHeaderRow } from '../../../styles/Table.styled';
import { CrIntervention, Field } from './Types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

interface CrInterventionsHistoryProps {
  interventions: CrIntervention[];
  goToInterventionForm(): void;
  onInterventionClick(intervention: CrIntervention): void;
  canCreateIntervention?: boolean;
}

const CrInterventionsHistory = ({
  interventions,
  goToInterventionForm,
  onInterventionClick,
  canCreateIntervention = true
}: CrInterventionsHistoryProps) => {
  const renderField = (intervention: CrIntervention, field: Field): any => {
    let value = _.get(field.name, intervention);
    if (field.transform !== undefined) {
      value = field.transform(value, intervention);
    }

    if (typeof value === 'boolean')
      return value ? <DoneIcon fontSize="small" /> : <ClearIcon fontSize="small" />;
    else if (typeof value === 'object' && Array.isArray(value)) return value.join(', ');
    else return value;
  };
  const listFields = [
    {
      name: 'id',
      label: '#',
      options: {
        width: '80px'
      }
    },
    {
      name: 'created_at',
      label: 'Date',
      transform: (value: any) => {
        return (value && moment.unix(value).format('DD/MM/YYYY HH:mm')) || '';
      }
    },
    {
      name: 'status__name',
      label: 'Statut'
    },
    {
      name: 'purchase_order_percent',
      label: '%BC',
      transform: (value: number) => {
        return `${value !== null ? +value * 100 : '- '}%`;
      }
    }
  ];
  return (
    <Accordion
      variant="outlined"
      square
      sx={{ mt: 2 }}
      defaultExpanded={true}
      disableGutters={true}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <h3>Interventions associées existantes</h3>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <StyledTableHeaderRow>
                {listFields.map((field) => (
                  <TableCell key={field.name}>
                    <Trans>{field.label}</Trans>
                  </TableCell>
                ))}
                <TableCell sx={{ width: 150, textAlign: 'center' }}>Actions</TableCell>
              </StyledTableHeaderRow>
            </TableHead>
            <TableBody>
              {_.isEmpty(interventions) ? (
                <StyledTableBodyRow>
                  <TableCell colSpan={listFields.length + 2}>
                    <Trans>Il n'y a aucun résultat à afficher.</Trans>
                    {typeof goToInterventionForm === 'function' && canCreateIntervention && (
                      <Button
                        id="associateIntervention"
                        size="small"
                        variant="outlined"
                        sx={{ ml: 1 }}
                        onClick={() => goToInterventionForm()}
                      >
                        Associer une intervention
                      </Button>
                    )}
                  </TableCell>
                </StyledTableBodyRow>
              ) : (
                interventions.map((intervention: any) => (
                  <StyledTableBodyRow
                    key={intervention.id || intervention[Object.keys(intervention)[0]]}
                  >
                    {listFields.map((field: any) => (
                      <TableCell key={field.label} title={field.label}>
                        {renderField(intervention, field)}
                      </TableCell>
                    ))}
                    <TableCell align="center">
                      <Tooltip title="Voir intervention">
                        <IconButton
                          id="go_to_intervention"
                          sx={{ ml: 2 }}
                          onClick={() => {
                            onInterventionClick(intervention);
                            goToInterventionForm();
                          }}
                        >
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </StyledTableBodyRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default CrInterventionsHistory;
