import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useCountdown from '../../../hooks/useCountdown';

const NotPendingHandler = ({ error }: { error: string }) => {
  const navigate = useNavigate();
  const backToList = () => navigate('/bconnect/interventions/pending');
  const count = useCountdown(backToList);

  return (
    <Box
      className="h-100 w-100 d-flex flex-column align-items-center justify-content-center"
      sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: 'white' }}
    >
      {error}

      <Button sx={{ mt: 1 }} onClick={backToList}>
        retour à la liste dans {count}
      </Button>
    </Box>
  );
};

export default NotPendingHandler;
