import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { CountHistory } from '../CR.types';
import {
  StyledTableBodyRow,
  StyledTableCell,
  StyledTableHeaderCell
} from '../../../../styles/Table.styled';
import { TableRow, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  BirdzModal,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';

interface CountHistoryModalProps {
  id: string;
}

const CountHistoryModal = ({ id }: CountHistoryModalProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [countHistory, setCountHistory] = useState<CountHistory[]>();

  useEffect(() => {
    if (id && open) {
      axios.get(`/api/boi/devices/count-history/?device_id=${id}`).then((response: any) => {
        setCountHistory(response?.data?.results);
      });
    }
  }, [id, open]);

  return (
    <>
      <IconButton onClick={handleOpen}>
        <SearchIcon fontSize="small" />
      </IconButton>
      <BirdzModal open={open} onClose={handleClose}>
        <BirdzModalTitle onClose={handleClose}>Détails nombre de modules exclusifs</BirdzModalTitle>
        <BirdzModalContent>
          {countHistory?.length === 0 && (
            <div style={{ textAlign: 'center' }}>
              Il n'y a pas d'historique pour ce concentrateur.
            </div>
          )}

          {countHistory && countHistory?.length > 0 && (
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableHeaderCell align="center">
                        <strong>Date</strong>
                      </StyledTableHeaderCell>
                      <StyledTableHeaderCell align="center">
                        <strong>Nombre de modules</strong>
                      </StyledTableHeaderCell>
                      <StyledTableHeaderCell align="center">
                        <strong>Nombre de modules exclusifs</strong>
                      </StyledTableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {countHistory &&
                      countHistory.map((history) => (
                        <>
                          <StyledTableBodyRow key={history.report_date}>
                            <StyledTableCell key={history.report_date} align="center">
                              {moment(history.report_date).format('DD/MM/YYYY')}
                            </StyledTableCell>
                            <StyledTableCell key={history.child_modules_count} align="center">
                              {history.child_modules_count}
                            </StyledTableCell>
                            <StyledTableCell key={history.child_exclusive_modules} align="center">
                              {history.child_exclusive_modules}
                            </StyledTableCell>
                          </StyledTableBodyRow>
                        </>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </BirdzModalContent>
      </BirdzModal>
    </>
  );
};

export default CountHistoryModal;
