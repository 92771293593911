import React, { useEffect, useState } from 'react';
import { Alert } from '@mui/material';
import axios from 'axios';
import { MonthlyStatement } from './Types';
import { Pricing } from '../../types';

type SummaryProps = {
  fullSummary: boolean;
  monthlyStatement?: MonthlyStatement;
  interventions?: number;
};

export default function Summary({ fullSummary, monthlyStatement, interventions }: SummaryProps) {
  const [priceUnit, setPriceUnit] = useState<string>();
  const [interWithReservation, setInterWithReservation] = useState<number>(0);

  const findApplicablePricing = async () => {
    const subcontractor = monthlyStatement?.cr_interventions[0]?.subcontractor;

    if (subcontractor) {
      await axios
        .get(`/api/boi/subcontractors/subcontractors/${subcontractor}/`)
        .then((response) => {
          const applicablePricings: Pricing[] = response.data.applicable_pricings;

          //find applicable pricings for the statement's year
          applicablePricings.find((item) => {
            if (item.year === monthlyStatement.year) {
              setPriceUnit(item.price);
            }
          });
        });
    }
  };

  useEffect(() => {
    if (fullSummary && monthlyStatement) {
      findApplicablePricing();

      const count = monthlyStatement?.cr_interventions.filter((inter) => {
        if (Number(inter.purchase_order_percent) !== 1.0) {
          return inter;
        }
      });

      setInterWithReservation(count?.length);
    }
  }, [monthlyStatement?.id]);

  return (
    <Alert severity="info">
      Sur la période du mois de <strong>{monthlyStatement?.month__name}</strong> de l'année{' '}
      <strong>{monthlyStatement?.year__name}</strong>, on comptabilise{' '}
      {interventions ? (
        <>
          <strong>{interventions}</strong> intervention{interventions > 1 && 's'}
        </>
      ) : (
        <>
          <strong>{monthlyStatement?.cr_interventions.length}</strong> intervention
          {monthlyStatement && monthlyStatement.cr_interventions.length > 1 && 's'}
        </>
      )}
      {fullSummary ? (
        <>
          {' '}
          (dont <strong>{interWithReservation ?? ''}</strong> avec réserve
          {interWithReservation > 1 && 's'}). <br></br> Le montant prévisionnel du BC basé sur le
          tarif de la période ({priceUnit ?? '-'}) sera de{' '}
          <strong>{monthlyStatement?.prices_interventions}</strong>.
        </>
      ) : (
        '.'
      )}
    </Alert>
  );
}
