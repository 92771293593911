import React from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

interface CustomTextFieldProps {
  error?: boolean;
  size: string;
}

const ContractsCustomTextField = styled((props) => <TextField {...props} />)<CustomTextFieldProps>(
  ({ error }) => ({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: error ? '#d32f2f' : undefined
      }
    }
  })
);

export default ContractsCustomTextField;
