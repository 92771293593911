import moment from 'moment';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  AppPaper,
  ListField,
  ListPage,
  SearchField,
  SearchForm,
  BirdzTitle
} from '@applications-terrains/birdz-react-library';
import CloseIcon from '@mui/icons-material/Close';
import { authService } from '../..';
import { IconButton, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useGetSubcontractors } from '../../hooks/datarefs';

const Informations = () => {
  const { data: subcontractors } = useGetSubcontractors();
  const [filterString, setFilterString] = React.useState<string>('&processed=false');
  const [endpoint, setEndpoint] = React.useState<string>('');
  const [listFields, setListFields] = React.useState<ListField[]>([]);
  const [searchFields, setSearchFields] = React.useState<SearchField[]>([]);

  useEffect(() => {
    if (authService.canAccessBOI() && !subcontractors) return;

    let _endpoint = '';
    const _listFields: ListField[] = [
      {
        name: 'created_at',
        label: 'Date',
        transform: (value: any) => {
          return (value && moment.unix(value).format('DD/MM/YYYY')) || '';
        }
      },
      {
        name: 'created_by__name',
        label: 'Tech'
      },
      {
        name: 'content',
        label: 'Contenu'
      },
      {
        name: 'picture',
        label: 'Photo(s)',
        className: 'text-center'
      }
    ];

    const _searchFields: SearchField[] = [
      {
        name: 'created_at_after',
        label: 'Date début',
        type: 'datepicker'
      },
      {
        name: 'created_at_before',
        label: 'Date fin',
        type: 'datepicker'
      },
      {
        name: 'processed',
        label: 'Inclure les informations traitées',
        type: 'checkbox',
        size: 12,
        labelSize: 3,
        fieldSize: 9
      }
    ];

    if (authService.canAccessBOI()) {
      _endpoint = '/api/boi/extras/observations/?processed=false';
      _listFields.splice(0, 0, {
        name: 'subcontractor',
        label: 'Sous-traitant',
        transform: (value: any) => {
          const result = subcontractors?.find((subcontractor: any) => {
            return subcontractor.id === value ? subcontractor : null;
          });
          return result?.name;
        }
      });

      _searchFields.splice(0, 0, {
        name: 'created_by',
        label: 'Technicien',
        options: {
          identifier: 'id',
          source: '/api/boi/administration/users/?user_type=external&',
          searchIsMulti: false
        }
      });

      _searchFields.splice(0, 0, {
        name: 'subcontractor',
        label: 'Sous-traitant',
        options: {
          identifier: 'id',
          source: '/api/boi/subcontractors/subcontractors/',
          label: 'name',
          searchIsMulti: false
        }
      });

      if (authService.canAccess('INFO_CMP_LU')) {
        _listFields.push({
          name: 'is_read',
          label: 'Marqué comme lu',
          className: 'text-center'
        });
      }

      if (authService.canAccess('INFO_CMP_CMP')) {
        _listFields.push({
          name: 'processed',
          label: 'Traité',
          className: 'text-center',
          transform: (value: any) => {
            return value === null ? (
              <CloseIcon fontSize="small" />
            ) : (
              moment(value).format('DD/MM/YYYY à HH:mm')
            );
          }
        });
      }

      _listFields.push({
        name: '',
        label: 'Retour',
        className: 'text-center',
        transform: (value: any, item: any) => {
          return (
            <IconButton component={Link} to={'/boi/informations/details/' + item.id}>
              <SearchIcon fontSize="small" />
            </IconButton>
          );
        }
      });
    } else if (authService.canAccessBOE()) {
      _endpoint = '/api/boe/extras/observations/';

      _listFields.push({
        name: '',
        label: 'Retour',
        className: 'text-center',
        transform: (value: any, item: any) => {
          return (
            <IconButton component={Link} to={'/boe/informations/details/' + item.id}>
              <SearchIcon fontSize="small" />
            </IconButton>
          );
        }
      });

      _searchFields.splice(0, 0, {
        name: 'created_by',
        label: 'Technicien',
        options: {
          identifier: 'user',
          label: 'user__name',
          source: '/api/boe/subcontractors/technicians/',
          searchIsMulti: false
        },
        className: 'col-sm-6'
      });
    }

    setListFields(_listFields);
    setSearchFields(_searchFields);
    setEndpoint(_endpoint);
  }, [subcontractors]);

  return (
    <AppPaper>
      <div>
        <BirdzTitle>Informations remontées du terrain</BirdzTitle>

        <Paper variant="outlined" square sx={{ p: 2, mb: 1 }}>
          <SearchForm
            fields={searchFields}
            onSubmit={(filterString: string) => {
              setFilterString(filterString.replace('&processed=true', ''));
            }}
          />
        </Paper>

        {endpoint && (
          <>
            <ListPage
              endpoint={`${endpoint}`}
              filters={filterString}
              fields={listFields}
              paginationOptions={{
                pageSize: 25
              }}
              defaultOrder={['-updated_at']}
            />
          </>
        )}
      </div>
    </AppPaper>
  );
};

export default Informations;
