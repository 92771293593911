export const licencesCount = (dateLast: any) => {
  const currentDate: any = new Date();
  const d = dateLast.toString();
  const date: any = new Date(d);

  const diffTime = Math.abs(currentDate - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};
