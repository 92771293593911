import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Job } from '../types/types';
import { endpoint } from '../const';
import { showFriendlyErrorsHTML } from '@applications-terrains/birdz-react-library';
import React, { ReactNode, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

export const useSaveJob = (translations?: { [key: string]: string }) => {
  const navigate = useNavigate();

  const [notification, setNotification] = useState<{
    type: 'success' | 'error';
    content: ReactNode;
  } | null>(null);

  const [submitInProgress, setSubmitInProgress] = useState(false);

  const saveJob = (job: Job) => {
    const { id } = job;
    setSubmitInProgress(true);
    const method = id ? 'put' : 'post';
    const url = `${endpoint}${id ? id + '/' : ''}`;
    axios[method](url, job)
      .then(() => {
        setNotification({
          type: 'success',
          content: 'Le job a bien été enregistré'
        });
        setTimeout(() => {
          navigate('/boi/extractor/jobs/list');
        }, 2000);
      })
      .catch((error: any) => {
        const errors = error?.response?.data || [];

        function displayErrors(errors: any) {
          const errorMessages: any = {};

          for (const field in errors) {
            if (Array.isArray(errors[field])) {
              if (errors[field].length && errors[field][0] !== 'Client does not exist id: 1')
                errorMessages[translations && field in translations ? translations[field] : field] =
                  [errors[field][0]];
            } else {
              for (const subField in errors[field]) {
                errorMessages[
                  translations && subField in translations ? translations[subField] : subField
                ] = [errors[field][subField][0]];
              }
            }
          }

          return errorMessages;
        }

        setNotification({
          type: 'error',
          content: showFriendlyErrorsHTML(
            displayErrors(errors) as any,
            "Une erreur est survenue lors de l'enregistrement du job"
          )
        });
      })
      .finally(() => {
        setSubmitInProgress(false);
      });
  };

  const notif = (
    <Snackbar
      open={notification !== null}
      onClose={() => setNotification(null)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={4000}
    >
      <Alert severity={notification?.type} sx={{ width: '100%' }}>
        <>{notification?.content}</>
      </Alert>
    </Snackbar>
  );

  return { saveJob, notification, submitInProgress, notif };
};
