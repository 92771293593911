import React, { Fragment, useEffect, useState } from 'react';
import { AppPaper } from '@applications-terrains/birdz-react-library';
import { Grid, Tab, Tabs, TextField } from '@mui/material';
import { FormikErrors, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';

type DefaultValuesType = { [key: string]: string | number };

export const SendingType = ({
  setValidationSchema,
  validationSchema
}: {
  setValidationSchema: (data: Yup.ObjectShape) => void;
  validationSchema: Yup.ObjectShape;
}) => {
  const [field] = useField('credentials_sending_export');
  const { setFieldValue, errors } = useFormikContext();

  let sendingErrors: FormikErrors<unknown> = {};
  if ('credentials_sending_export' in errors)
    sendingErrors = errors['credentials_sending_export'] as FormikErrors<unknown>;

  // API n'est pas encore géré coté back
  const sendingTypesValues = [
    'FTP',
    'SFTP'
    //  'API'
  ];
  const [valueTab, setValueTab] = useState(() => {
    const index = sendingTypesValues.indexOf(field?.value?.['sending_type']);
    return index !== -1 ? index : 0;
  });

  const FTP_DEFAULT_VALUE: DefaultValuesType = {
    sending_type: 'FTP',
    server: '',
    port: 21,
    user: '',
    password: '',
    directory: ''
  };

  const SFTP_DEFAULT_VALUE: DefaultValuesType = {
    sending_type: 'SFTP',
    server: '',
    port: 22,
    user: '',
    private_key: '',
    directory: ''
  };

  const API_DEFAULT_VALUE: DefaultValuesType = {
    url: '',
    user: '',
    password: '',
    token: ''
  };

  const defaultValues = {
    FTP: FTP_DEFAULT_VALUE,
    SFTP: SFTP_DEFAULT_VALUE,
    API: API_DEFAULT_VALUE
  };

  const target = sendingTypesValues[valueTab] as keyof typeof defaultValues;

  const fields = [
    {
      label: 'Serveur',
      name: 'server',
      type: 'text',
      required: true,
      validation: Yup.string().required('Merci de renseigner une adresse IP ou une URL'),
      default: defaultValues[target].server,
      target: ['FTP', 'SFTP']
    },
    {
      label: 'Port',
      name: 'port',
      type: 'number',
      required: true,
      validation: Yup.number().min(1, 'Merci de renseigner un nombre supérieur à 0.').required(),
      default: defaultValues[target].port,
      target: ['FTP', 'SFTP'],
      InputProps: {
        inputProps: { min: 0 }
      },
      onKeyPress: (event: any) => {
        if (event?.key === '-' || event?.key === '+') {
          event.preventDefault();
        }
      }
    },
    {
      label: 'URL',
      name: 'url',
      type: 'url',
      required: true,
      validation: Yup.string().url().required('Merci de renseigner une url valide'),
      default: defaultValues[target].url,
      target: ['API']
    },
    {
      label: 'Utilisateur',
      name: 'user',
      type: 'text',
      required: true,
      validation: Yup.string().required('Merci de renseigner un utilisateur'),
      default: defaultValues[target].user,
      target: ['FTP', 'SFTP', 'API']
    },
    {
      label: 'Clé privée',
      name: 'private_key',
      type: 'password',
      validation: Yup.string(),
      default: defaultValues[target].private_key,
      target: ['SFTP']
    },
    {
      label: 'Mot de passe',
      name: 'password',
      type: 'password',
      validation: Yup.string(),
      default: defaultValues[target].password || '',
      target: ['FTP', 'SFTP', 'API']
    },
    {
      label: 'Répertoire',
      name: 'directory',
      type: 'text',
      validation: Yup.string().required('Merci de renseigner un répertoire'),
      default: defaultValues[target].directory,
      target: ['FTP', 'SFTP']
    },
    {
      label: 'Token',
      name: 'token',
      type: 'text',
      validation: Yup.string(),
      default: defaultValues[target].token,
      target: ['API']
    }
  ];

  const formValues = fields.filter((el) => el.target.includes(target));

  const setValue = (sendingType: string, fieldName: string, fieldValue: any) => {
    let sendingTypeValue = {};
    if (field.value && field.value['sending_type'] === sendingType) {
      sendingTypeValue = Object.assign({}, field.value, { [fieldName]: fieldValue });
    } else {
      sendingTypeValue = {
        sending_type: sendingType,
        [fieldName]: fieldValue
      };
    }
    setFieldValue(field.name, sendingTypeValue);
  };

  useEffect(() => {
    setValidationSchema({
      ...validationSchema,
      credentials_sending_export: Yup.object({
        sending_type: Yup.string().required(),
        ...Object.fromEntries(formValues.map((field) => [field.name, field.validation]))
      })
    });
    formValues.forEach((val) =>
      setValue(
        target,
        val.name,
        field?.value && field?.value['sending_type'] === target
          ? field.value?.[val.name]
          : val.name in defaultValues[target]
            ? defaultValues[target][val.name]
            : ''
      )
    );
  }, [valueTab]);

  const gridSize = Math.floor(12 / formValues.length);

  return (
    <>
      <Tabs
        value={valueTab}
        onChange={(event: any, newValueTab: number) => setValueTab(newValueTab)}
        sx={{ mb: 1 }}
      >
        {sendingTypesValues.map((sendingType, index) => (
          <Tab key={index} label={sendingType} />
        ))}
      </Tabs>
      <AppPaper sx={{ pt: 2 }}>
        <Grid container spacing={1}>
          {formValues.map((specificField, index) => {
            const value = field?.value?.[specificField.name] || specificField.default || '';
            if (
              field.value !== undefined &&
              (!(specificField.name in field.value) || field.value[specificField.name] !== value)
            ) {
              const newValues = { ...field.value, [specificField.name]: value };
              setFieldValue(field.name, newValues);
            }
            return (
              <Fragment key={specificField.name + target + index}>
                <Grid item xs={gridSize}>
                  <TextField
                    autoComplete={specificField.name}
                    value={value}
                    size="small"
                    onChange={(e: any) => {
                      setValue(target, specificField.name, e.target.value);
                    }}
                    {...specificField}
                    error={specificField.name in sendingErrors}
                    helperText={
                      specificField.name in sendingErrors
                        ? sendingErrors[specificField.name as keyof typeof errors]
                        : ''
                    }
                  />
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </AppPaper>
    </>
  );
};
