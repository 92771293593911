import React, { useEffect, useState } from 'react';
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField
} from '@mui/material';
import { ModalItem } from '../DMSTypes';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';
import { authService } from '../../..';
import axios from 'axios';
import {
  BirdzModal,
  BirdzModalActions,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';
import _ from 'lodash';

type DMSModalPermissionsProps = {
  item: Partial<ModalItem>;
  onClose: (message?: string) => void;
  onError: (message: string) => void;
};

type UserItem = {
  id: number;
  email: string;
};

export const DMSModalPermissions = ({ onClose, onError, item }: DMSModalPermissionsProps) => {
  const [isHover, setIsHover] = useState('');
  const [usersToAdd, setUsersToAdd] = useState<UserItem[]>([]);
  const [warning, setWarning] = useState<boolean>(false);
  const [allowedUsers, setAllAllowedUsers] = useState<UserItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!item.checkedItems || item.checkedItems.length === 0) {
      const fetchAllowedUsers = async () => {
        await axios.get('/api/boi/dms/file-permissions/?file_id=' + item.id).then((response) => {
          if (!response.data.allowed_users || response.data.allowed_users.length === 0) {
            return;
          }
          setUsersToAdd(
            response.data.allowed_users.map((item: any) => {
              return { id: item.id, email: item.email };
            })
          );
        });
      };

      fetchAllowedUsers();
    } else {
      const ids = item.checkedItems.map((item) => {
        return item.id;
      });

      const fetchUsersInFolders = async () => {
        let allowedUsersByRessource: UserItem[][];
        await axios
          .all(ids.map((id) => axios.get(`/api/boi/dms/file-permissions/?file_id=${id}`)))
          .then((response) => {
            allowedUsersByRessource = response.map((item) => {
              return item.data.allowed_users.map((user: any) => {
                return { id: user.id, email: user.email };
              });
            });

            //stock the access id for the first resource selected
            const reference = allowedUsersByRessource[0].map((user) => {
              return user.id;
            });

            let warning = false;
            const allAllowedUsers: UserItem[] = [];

            for (const allowedUser of allowedUsersByRessource) {
              allowedUser.forEach((user) => {
                if (!allAllowedUsers.some((u) => u.id === user.id)) {
                  allAllowedUsers.push(user);
                }
              });

              if (
                !_.isEqual(
                  allowedUser.map((u) => {
                    return u.id;
                  }),
                  reference
                )
              ) {
                warning = true;
              }
            }
            setWarning(warning);
            setAllAllowedUsers(allAllowedUsers);
            setUsersToAdd(allAllowedUsers);
          });
      };

      fetchUsersInFolders();
    }
  }, [item]);

  const updatePermissions = () => {
    if (!authService.isAdminGED()) {
      return;
    }

    const payloadValues = usersToAdd.map((user) => {
      return { id: user.id };
    });
    const payload = { allowed_users: payloadValues };

    const updatePermissionRequests: Promise<unknown>[] = [];

    if (item.checkedItems) {
      if (item.checkedItems.length === 0) {
        updatePermissionRequests.push(
          axios.post('/api/boi/dms/file-permissions/?file_id=' + item.id, payload)
        );
      } else {
        item.checkedItems.forEach((checkedItem) => {
          updatePermissionRequests.push(
            axios.post('/api/boi/dms/file-permissions/?file_id=' + checkedItem.id, payload)
          );
        });
      }
    }

    setIsLoading(true);
    axios
      .all(updatePermissionRequests)
      .then(() => {
        onClose('Les droits ont été modifiés avec succès');
      })
      .catch(() => {
        onError('Une erreur est survenue lors de la modification des droits');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <BirdzModal open={true} maxWidth="md">
      <BirdzModalTitle>
        Droits sur :{' '}
        {item.checkedItems && item.checkedItems.length !== 0
          ? item.checkedItems.map((checkedItem) => checkedItem.name).join(', ')
          : item.name}
      </BirdzModalTitle>
      <BirdzModalContent>
        <Grid container spacing={2}>
          {warning && (
            <Grid item xs={12}>
              <Alert severity="warning">
                Certains utilisateurs (suivis d'un *) ont des accès partiels à ces ressources.{' '}
                <br />
                En les autorisant, ces utilisateurs auront accès à toutes aux ressources
                sélectionnées.
              </Alert>
            </Grid>
          )}
          <Grid item xs={6}>
            <List>
              <ListItem key="user_col">
                <VisibilityIcon sx={{ mr: 1 }} />
                Tous les utilisateurs :
              </ListItem>

              <Autocomplete
                options={
                  (item?.userList &&
                    item?.userList
                      ?.map((user) => {
                        if (!usersToAdd.some((u) => u.id === user.id)) {
                          return {
                            id: user.id,
                            label: user.email
                          };
                        } else {
                          return null;
                        }
                      })
                      .filter((user) => user !== null)) ||
                  []
                }
                onChange={(_event, selectedUser) => {
                  if (selectedUser) {
                    setUsersToAdd(
                      usersToAdd.concat({ id: selectedUser.id, email: selectedUser.label })
                    );
                  }
                }}
                value={{ id: null, label: '' }}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Utilisateur" />}
              />
            </List>
          </Grid>
          <Grid item xs={6}>
            <List>
              <ListItem key="allowed_user_col">
                <VisibilityOffIcon sx={{ mr: 1 }} />
                Utilisateurs autorisés :
              </ListItem>
              {usersToAdd.map((user: any) => {
                const key = 'new_allowed_user' + user.id;
                return (
                  <ListItem
                    id={user.id}
                    key={key}
                    onMouseEnter={() => setIsHover(key)}
                    onMouseLeave={() => setIsHover('')}
                    sx={{ p: 0, height: '30px' }}
                  >
                    {user.email} {warning && allowedUsers.some((u) => u.id === user.id) && '*'}
                    {isHover === key && (
                      <IconButton
                        size="small"
                        sx={{ ml: 1 }}
                        onClick={() => {
                          setUsersToAdd(usersToAdd.filter((u) => u.id !== user.id));
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </BirdzModalContent>
      <BirdzModalActions>
        <Button id="goBack" variant="outlined" onClick={() => onClose()}>
          Retour
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            updatePermissions();
          }}
          disabled={isLoading}
        >
          {isLoading && <CircularProgress size={16} thickness={5} sx={{ mr: 1 }} />} Valider
        </Button>
      </BirdzModalActions>
    </BirdzModal>
  );
};
