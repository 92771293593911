import { Box, LinearProgress, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useGetCaseStatuses } from '../../../hooks/datarefs';

type CrCaseStatusProps = {
  crCaseStatusId: number;
};

const CrCaseStatus = ({ crCaseStatusId }: CrCaseStatusProps) => {
  const { data: caseStatuses } = useGetCaseStatuses();
  const [interventionStatusProgressValue, setInterventionStatusProgressValue] = useState<number>(0);

  useEffect(() => {
    if (!caseStatuses) return;

    const position = caseStatuses
      .map((interventionStatuse: any) => interventionStatuse.id)
      .indexOf(crCaseStatusId);
    setInterventionStatusProgressValue((position / (caseStatuses.length - 1)) * 100);
  }, [crCaseStatusId, caseStatuses]);

  return (
    <>
      {caseStatuses && caseStatuses.length > 0 && (
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={4}>
            {caseStatuses.map((interventionStatuse: any, index: number) => (
              <div key={index}>{interventionStatuse.label}</div>
            ))}
          </Stack>
          <LinearProgress
            variant="determinate"
            color="inherit"
            value={interventionStatusProgressValue}
            sx={{ height: '14px', mb: 2 }}
          />
        </Box>
      )}
    </>
  );
};
export default CrCaseStatus;
