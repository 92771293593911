import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Grid, IconButton, Stack } from '@mui/material';
import { Alert } from '@mui/material';
import moment from 'moment';
import SmsFreeMessageDialog from './SmsFreeMessageDialog';
import LoopIcon from '@mui/icons-material/Loop';
import { useNotif, BirdzNotif, ListPage } from '@applications-terrains/birdz-react-library';
import { authService } from '../../../..';

type SmsCommand = {
  id?: number;
  label?: string;
  command?: string;
  created_at?: number;
  updated_at?: number;
};

type ResponseSmsProps = {
  accound_id: string;
  date: number;
  from_: any;
  last_message_id: string;
  message: string;
};

type SmsHistoryItem = {
  created_at: number;
  device_id: string;
  id?: number;
  message: string;
  message_id?: string;
  phone_number: string;
  response_sms?: ResponseSmsProps | null;
};

type SmsHistoryProps = {
  moduleData: {
    module_address: string;
    contract: string;
    city: string;
    longitude: number;
    latitude: number;
    device_type: string;
    average_exclusive_module: number;
    contract_label: string;
    contract_exp_date: string;
    pdc_id: string;
    phone: string;
  };
};

type SmsPayload = {
  device_id?: string;
  phone_number: string;
  sms_command_id?: number;
  free_message?: string;
};

const SmsHistory = ({ moduleData }: SmsHistoryProps) => {
  const [smsCommands, setSmsCommands] = useState<SmsCommand[]>([]);
  const [smsHistoryItems, setSmsHistoryItems] = useState<SmsHistoryItem[]>([]);
  const [disableSendCommands, setDisableSendCommands] = useState<boolean>(false);
  const { notif, notifOptions } = useNotif();

  useEffect(() => {
    const wssHost =
      window.location.hostname === 'localhost'
        ? 'ws://localhost:3000'
        : 'wss://' + window.location.host;
    const ws = new WebSocket(wssHost + '/ws/sms-resp/');

    const onReceiveMessage = (event: any) => {
      const receivedMessage = JSON.parse(event.data);
      if (receivedMessage?.type === 'sms.message' && receivedMessage.data) {
        const receiveMessageId = receivedMessage.data?.last_message_id;
        const foundSmsHistory = smsHistoryItems.find(
          (smsHistoryItem) => smsHistoryItem.message_id === receiveMessageId
        );
        if (foundSmsHistory) {
          foundSmsHistory.response_sms = receivedMessage.data;
          setSmsHistoryItems([...smsHistoryItems]);
        }
      }
    };

    ws.addEventListener('message', onReceiveMessage);

    return () => {
      // Clean connexions and listeners
      ws.removeEventListener('message', onReceiveMessage);
      ws.close();
    };
  }, [smsHistoryItems]);

  const phoneNumberFound = useCallback(() => {
    return moduleData?.phone;
  }, [moduleData.phone]);

  const sendCommand = (smsCommand: SmsCommand) => {
    setDisableSendCommands(true);
    const body: SmsPayload = {
      phone_number: moduleData.phone,
      device_id: moduleData.module_address
    };
    if (smsCommand.id) {
      body.sms_command_id = smsCommand.id;
    }
    if (smsCommand?.command) {
      body.free_message = smsCommand?.command;
    }

    axios
      .post('/api/boi/devices/send-sms/', body)
      .then(function (response) {
        setSmsHistoryItems([...[response.data], ...smsHistoryItems]);
      })
      .catch(function (error) {
        notif({
          type: 'error',
          content: (
            <>
              Une erreur est survenue lors de l'envoi de la commande.
              <br />
              <br />
              {error?.response?.data}
            </>
          )
        });
      })
      .finally(() => {
        setDisableSendCommands(false);
      });
  };

  const sendCommandAgain = (smsHistoryItem: SmsHistoryItem) => {
    //Search in all smsCommands the command with the same command than smsHistoryItem's message
    const findCommand = smsCommands.find(
      (smsCommand) => smsCommand.command === smsHistoryItem.message
    );
    if (findCommand) {
      sendCommand(findCommand);
    }
  };

  useEffect(() => {
    axios
      .get(
        `/api/boi/devices/sms-history/?device_id=${moduleData.module_address}&ordering=-created_at`
      )
      .then((response: any) => {
        setSmsHistoryItems(response.data.results);
      });

    axios.get('/api/boi/params/sms-commands/').then((response: any) => {
      setSmsCommands(response.data.results);
    });
  }, [moduleData.module_address]);

  return (
    <>
      {!phoneNumberFound() && (
        <Alert severity="error">Aucun numéro de téléphone n'a été trouvé.</Alert>
      )}

      {phoneNumberFound() && (
        <>
          {authService.canAccess('CR_DIAG_SMS') && (
            <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
              {smsCommands &&
                smsCommands.map((smsCommand) => {
                  return (
                    <Button
                      id={smsCommand.label}
                      variant="outlined"
                      size="small"
                      onClick={() => sendCommand(smsCommand)}
                      disabled={disableSendCommands}
                      sx={{ fontSize: '12px' }}
                      key={smsCommand.command}
                    >
                      {smsCommand.label}
                    </Button>
                  );
                })}
              <SmsFreeMessageDialog
                onSubmit={(smsMessage) => {
                  const newCommand: SmsCommand = {
                    command: smsMessage
                  };
                  sendCommand(newCommand);
                }}
              />
            </Stack>
          )}

          {smsHistoryItems.length === 0 ? (
            <div style={{ marginTop: '15px' }}>
              <strong>Aucun historique des sms envoyés</strong>
            </div>
          ) : (
            <Grid item xs={2}>
              <ListPage
                fields={[
                  {
                    name: 'created_at',
                    label: "Date d'envoi",
                    transform: (date: number) => moment.unix(date).format('DD/MM/YYYY à HH:mm:ss')
                  },
                  {
                    name: 'message',
                    label: 'Message envoyé'
                  },
                  {
                    name: 'response_sms_date',
                    label: 'Date de la réponse',
                    transform(value, row) {
                      return row.response_sms?.date
                        ? moment(row.response_sms?.date).format('DD/MM/YYYY à HH:mm:ss')
                        : '-';
                    }
                  },
                  {
                    name: 'response_sms_message',
                    label: 'Message de la réponse',
                    transform(value, row) {
                      return row.response_sms?.message ? row.response_sms?.message : '-';
                    }
                  }
                ]}
                loadedData={smsHistoryItems}
                actions={[
                  {
                    name: 'send_again',
                    label: 'Envoyer à nouveau',
                    render: (smsHistoryItem: any) => {
                      return (
                        <IconButton onClick={() => sendCommandAgain(smsHistoryItem)}>
                          <LoopIcon id="send_again" />
                        </IconButton>
                      );
                    }
                  }
                ]}
                paginationOptions={{ pageSize: 10 }}
              />
            </Grid>
          )}
        </>
      )}
      <BirdzNotif options={notifOptions} />
    </>
  );
};

export default SmsHistory;
