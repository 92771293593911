import React, { ReactNode } from 'react';
import { green, red } from '@mui/material/colors';
import { Box, Chip } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { capitalizeFirstLetter } from '../../../utils';

const greenColor = green[500];
const redColor = red[500];

const iconSize = '16px';
const icons = {
  true: <CheckIcon style={{ color: greenColor, fontSize: iconSize }} sx={{ pr: 0.5 }} />,
  false: <CancelIcon style={{ color: redColor, fontSize: iconSize }} sx={{ pr: 0.5 }} />
};

const Value = ({ label, value }: { label: string; value: ReactNode }) => (
  <Box sx={{ my: 0.5 }} className="d-flex align-items-center">
    {label && <Box sx={{ my: 0.5, width: '100px' }}>{capitalizeFirstLetter(label)} : </Box>}
    {value && <strong style={{ color: '#637192' }}>{value}</strong>}
  </Box>
);

const displayChip = (condition: boolean) => (
  <Chip
    label={condition ? 'activé' : 'désactivé'}
    size="small"
    icon={icons[(condition ? 'true' : 'false') as keyof typeof icons]}
    sx={{ px: 0.5, fontWeight: 'lighter' }}
    variant="outlined"
  />
);

const DisplayConfigurationParameters = ({
  protocol_name,
  dst = false,
  time_index = false,
  autoconnect = false
}: {
  protocol_name: 'HR' | 'LoRaWan' | 'SigFox';
  dst: boolean;
  time_index: boolean;
  autoconnect: boolean;
}) => {
  return (
    <>
      <Value label="protocole" value={protocol_name} />
      <Value label="DST" value={displayChip(dst)} />
      <Value label="index horaire" value={displayChip(time_index)} />
      <Value label="auto-connect" value={displayChip(autoconnect)} />
    </>
  );
};

export default DisplayConfigurationParameters;
