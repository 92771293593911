import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  IconButton
} from '@mui/material';
import { MRCASETYPE, MrCase } from './Types';
import {
  StyledTableBodyRow,
  StyledTableHeaderRow
} from '@applications-terrains/birdz-react-library';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/RemoveRedEye';
import { Link } from 'react-router-dom';

type MrCaseInterventionsProps = {
  mrCase: Partial<MrCase>;
};

const MrCaseInterventions = ({ mrCase }: MrCaseInterventionsProps) => {
  const [cities, setCities] = useState<string[]>([]);
  const [contracts, setContracts] = useState<string[]>([]);

  useEffect(() => {
    if (!mrCase.patrimony) return;

    const deviceIds = Object.keys(mrCase.patrimony);
    const newCities: string[] = [];
    const newContracts: string[] = [];

    for (const deviceId of deviceIds) {
      const device = mrCase.patrimony[deviceId];

      if (device === null) return;

      const city = `${device.city} (${device.zip_code})`;
      const contract = device.contract_code;

      if (!newCities.includes(city)) {
        newCities.push(city);
      }
      if (!newContracts.includes(contract)) {
        newContracts.push(contract);
      }

      setCities(newCities);
      setContracts(newContracts);
    }
  }, [mrCase.patrimony]);

  const hasInterventions = () => {
    return mrCase.interventions && mrCase?.interventions?.interventions?.length !== 0;
  };

  return (
    <Grid item sx={{ mb: 1 }}>
      <>
        <h4>Retours d'interventions</h4>
        {hasInterventions() ? (
          <Box>
            <p>
              Contrat :{' '}
              {contracts.map((contract, index: number) => {
                return `${contract}` + (index + 1 !== contracts.length ? ', ' : '');
              })}
            </p>
            <p>
              Commune / CP :{' '}
              {cities.map((city, index: number) => {
                return `${city}` + (index + 1 !== cities.length ? ', ' : '');
              })}
            </p>
            {mrCase.action_type === MRCASETYPE.MAINTENANCE && (
              <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table size="small">
                  <TableHead>
                    <StyledTableHeaderRow>
                      <TableCell align="center">Adresse radio</TableCell>
                      <TableCell align="center">Date retour</TableCell>
                      <TableCell align="center">Type intervention</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Maj OSS</TableCell>
                      <TableCell align="center">Commentaire technicien</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </StyledTableHeaderRow>
                  </TableHead>
                  <TableBody>
                    {mrCase?.interventions?.interventions?.map((intervention, index: number) => {
                      return (
                        <StyledTableBodyRow key={index}>
                          <TableCell align="center">{intervention.radioAddress}</TableCell>
                          <TableCell align="center">{intervention.dateRetour}</TableCell>
                          <TableCell align="center">{intervention.interventionType}</TableCell>
                          <TableCell align="center">{intervention.status}</TableCell>
                          <TableCell align="center">
                            {intervention.isSyncOSS === 'true' ? <CheckIcon /> : <CloseIcon />}
                          </TableCell>
                          <TableCell align="center">{intervention.interventionComment}</TableCell>
                          <TableCell align="center">
                            <Link to={`/boi/mr/cases/${mrCase.id}/intervention/${intervention.id}`}>
                              <IconButton aria-label="Intervention détail">
                                <VisibilityIcon id="visibilityIcon" />
                              </IconButton>
                            </Link>
                          </TableCell>
                        </StyledTableBodyRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {mrCase.action_type === MRCASETYPE.DEPLOYMENT && (
              <TableContainer component={Paper} sx={{ mb: 2 }}>
                <Table size="small">
                  <TableHead>
                    <StyledTableHeaderRow>
                      <TableCell>Type de travaux</TableCell>
                      <TableCell width={150}>Statut</TableCell>
                      <TableCell width={150}>Date historique</TableCell>
                    </StyledTableHeaderRow>
                  </TableHead>
                  <TableBody>
                    {mrCase.interventions?.actions?.map((action, index: number) => {
                      return (
                        <StyledTableBodyRow key={index}>
                          <TableCell>{action.type}</TableCell>
                          <TableCell>{action.status}</TableCell>
                          <TableCell>{action.date}</TableCell>
                        </StyledTableBodyRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        ) : (
          <Alert severity="info">Il n'y a pas encore de retours d'intervention disponible</Alert>
        )}
      </>
    </Grid>
  );
};

export default MrCaseInterventions;
