import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React, { useState } from 'react';
import {
  BirdzModal,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';

type DetailField = {
  label: string;
  name: keyof Contrats;
};

type ContractsDetailProps = {
  contractItem: Contrats;
  onClose(): void;
};

type Contrats = {
  contract_management_code: string;
  contract_effect_date: Date;
  contract_expiration_date: Date;
  signature_date: Date;
  supervision_date: Date;
  scheduled_radio_90_date: Date;
  initial_deployed_date: any;
  contract_ve: string;
  contract_status: string;
  contract_status_label: string;
  client: string;
  sage_code: string;
  sage_code_label: string;
  zone: string;
  service_center: string;
  service: string;
  region: string;
  region_name: string;
  territory: string;
  cpt_cp: string;
  phase: string;
  report: string;
  client_report: string;
  pricing_fas: number;
  pricing_rm: number;
  nb_racco_2015: number;
  nb_racco_2016: number;
  nb_racco_2017: number;
  nb_racco_2018: number;
  nb_racco_2019: number;
  nb_racco_2020: number;
  nb_racco_2021: number;
};

export const ContratsDetail = ({ contractItem, onClose }: ContractsDetailProps) => {
  const [show, setShow] = useState(true);

  const detailFields: DetailField[] = [
    { label: 'Contrat code', name: 'contract_management_code' },
    { label: 'contract effect date', name: 'contract_effect_date' },
    { label: 'contract expiration date', name: 'contract_expiration_date' },
    { label: 'signature date', name: 'signature_date' },
    { label: 'supervision date', name: 'supervision_date' },
    { label: 'scheduled radio 90 date', name: 'scheduled_radio_90_date' },
    { label: 'initial deployed date', name: 'initial_deployed_date' },
    { label: 'contract ve', name: 'contract_ve' },
    { label: 'contract status', name: 'contract_status' },
    { label: 'contract status label', name: 'contract_status_label' },
    { label: 'client', name: 'client' },
    { label: 'sage code', name: 'sage_code' },
    { label: 'sage code label', name: 'sage_code_label' },
    { label: 'zone', name: 'zone' },
    { label: 'service center', name: 'service_center' },
    { label: 'service', name: 'service' },
    { label: 'region', name: 'region' },
    { label: 'region name', name: 'region_name' },
    { label: 'territory', name: 'territory' },
    { label: 'cpt cp', name: 'cpt_cp' },
    { label: 'phase', name: 'phase' },
    { label: 'report', name: 'report' },
    { label: 'client report', name: 'client_report' },
    { label: 'pricing fas', name: 'pricing_fas' },
    { label: 'pricing rm', name: 'pricing_rm' },
    { label: 'nb racco 2015', name: 'nb_racco_2015' },
    { label: 'nb racco 2016', name: 'nb_racco_2016' },
    { label: 'nb racco 2017', name: 'nb_racco_2017' },
    { label: 'nb racco 2018', name: 'nb_racco_2018' },
    { label: 'nb racco 2019', name: 'nb_racco_2019' },
    { label: 'nb racco 2020', name: 'nb_racco_2020' },
    { label: 'nb racco 2021', name: 'nb_racco_2021' }
  ];
  return (
    <BirdzModal
      open={show}
      onClose={() => {
        setShow(false);
        onClose();
      }}
    >
      <BirdzModalTitle>Détail Contrat</BirdzModalTitle>
      <BirdzModalContent>
        <TableContainer sx={{ mb: 2 }}>
          <Table size="small">
            <TableBody>
              {detailFields.map((field: DetailField) => {
                return (
                  <TableRow key={field.name}>
                    <TableCell>{field.label}</TableCell>
                    <TableCell>{contractItem[field.name]}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </BirdzModalContent>
    </BirdzModal>
  );
};
