import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box
} from '@mui/material';
import { StyledTableHeaderRow } from '../../../styles/Table.styled';
import { SubcontractorWorkingHours } from './WorkingHours';
import { SubcontractorDetails } from './SubcontractorBOE';

const WorkingHoursBOE = ({ subcontractor }: { subcontractor: SubcontractorDetails }) => {
  const formatHours = (hours: string) => {
    return hours.slice(0, -3).replace(':', 'h');
  };

  const getWorkingHours = (dayNumber: number) => {
    const weekdaysWorkingHours: SubcontractorWorkingHours[] =
      subcontractor?.weekdays_working_hours || [];
    const weekdayWorkingHours = weekdaysWorkingHours.find((weekdayWorkingHours) => {
      return weekdayWorkingHours.isoweekday === dayNumber;
    });

    if (weekdayWorkingHours) {
      return {
        start_working: formatHours(weekdayWorkingHours.start_working ?? ''),
        end_working: formatHours(weekdayWorkingHours.end_working ?? '')
      };
    } else {
      return {
        start_working: '-',
        end_working: '-'
      };
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <h3>Heure ouvrées</h3>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table size="small">
          <TableHead>
            <StyledTableHeaderRow>
              <TableCell></TableCell>
              <TableCell>Lundi</TableCell>
              <TableCell>Mardi</TableCell>
              <TableCell>Mercredi</TableCell>
              <TableCell>Jeudi</TableCell>
              <TableCell>Vendredi</TableCell>
            </StyledTableHeaderRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Heure début</TableCell>
              <TableCell>{getWorkingHours(1).start_working}</TableCell>
              <TableCell>{getWorkingHours(2).start_working}</TableCell>
              <TableCell>{getWorkingHours(3).start_working}</TableCell>
              <TableCell>{getWorkingHours(4).start_working}</TableCell>
              <TableCell>{getWorkingHours(5).start_working}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Heure fin</TableCell>
              <TableCell>{getWorkingHours(1).end_working}</TableCell>
              <TableCell>{getWorkingHours(2).end_working}</TableCell>
              <TableCell>{getWorkingHours(3).end_working}</TableCell>
              <TableCell>{getWorkingHours(4).end_working}</TableCell>
              <TableCell>{getWorkingHours(5).end_working}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default WorkingHoursBOE;
