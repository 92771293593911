import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, FormControl, MenuItem, Select } from '@mui/material';
import { PiFileCsv } from 'react-icons/pi';
import { Grid } from '@mui/material';
import { useGetErps } from '../../../hooks/datarefs';
import axios from 'axios';
import { Alert } from '@mui/material';
import { Tooltip } from '@mui/material';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import { CircularProgress } from '@mui/material';
import { Link } from '@mui/material';
import { FormatProps } from './types';

type ExportPopupProps = {
  filter: string;
};

export default function ExportPopup({ filter }: ExportPopupProps) {
  const [showExportPopup, setShowExportPopup] = useState(false);
  const handleExportPopup = () => {
    setShowExportPopup(!showExportPopup);
    setExportInProgress(false);

    //reset formData to null if export is canceled
    setFormData({
      erp: null,
      export_format: null
    });
  };

  const { notif, notifOptions } = useNotif();
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);

  const { data: erps } = useGetErps();
  const [formats, setFormats] = useState<FormatProps[]>([]);

  const [failedExport, setFailedExport] = useState<boolean>(false);

  const [formData, setFormData] = useState({
    erp: null,
    export_format: null
  });

  // get export formats when an erp is selected
  const fetchFormats = async () => {
    const { data } = await axios.get(`/api/boi/extractor/erps/${formData.erp}`);
    const formats = data.export_formats_objects;
    setFormats(formats);
  };
  // fetch the async axios call when an erp is selected to get export formats associated
  useEffect(() => {
    if (formData.erp) {
      fetchFormats();
    }
  }, [formData]);

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const downloadFileWithPayload = async (
    url: string,
    payload: {
      export_format: number | null;
    },
    fileName?: string
  ) => {
    await axios({
      url: url,
      method: 'POST',
      responseType: 'blob',
      data: payload
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        let headerFilename: any;
        if (!fileName && response.headers && response.headers['content-disposition']) {
          headerFilename = `${response.headers['content-disposition'].split('attachment; filename=')}.csv`;
        }

        link.setAttribute('download', fileName ? fileName : headerFilename); //or any other extension
        document.body.appendChild(link);
        link.click();

        notif({
          content: "Succès de l'export",
          type: 'success'
        });

        setShowExportPopup(false);
        setFailedExport(false);
      })
      .catch((error) => {
        setFailedExport(true);
        notif({
          content: `Echec de l'export (${error.message})`,
          type: 'error'
        });
      })
      .finally(() => {
        setExportInProgress(false);
      });
  };

  const launchExport = async () => {
    setExportInProgress(true);

    downloadFileWithPayload(
      `/api/boi/extractor/interventions/export/${filter.replace(/^./, '?')}`,
      {
        export_format: formData.export_format
      }
    );
    //reset formData to null to disabled the next export if no erp & export format are selected
    setFormData({
      erp: null,
      export_format: null
    });
  };

  return (
    <>
      {!filter ? (
        <Grid container justifyContent="flex-end">
          <Tooltip title="Veuillez remplir au moins 1 filtre">
            <span>
              <Button
                size="small"
                variant="outlined"
                sx={{ mt: 2 }}
                onClick={() => handleExportPopup()}
                disabled={!filter || exportInProgress}
              >
                {exportInProgress && <CircularProgress size={18} thickness={5} />}
                <PiFileCsv />
                Exporter en csv
              </Button>
            </span>
          </Tooltip>
        </Grid>
      ) : (
        <Grid container justifyContent="flex-end">
          <Button
            size="small"
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => handleExportPopup()}
            disabled={!filter || exportInProgress}
          >
            {exportInProgress && <CircularProgress size={18} thickness={5} />}
            <PiFileCsv />
            Exporter en csv
          </Button>
        </Grid>
      )}

      <Dialog
        open={showExportPopup}
        onClose={handleExportPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Export d'interventions - Choix du format`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={1}>
              <Grid item xs={4} sx={{ mt: 2 }}>
                ERP*
              </Grid>
              <Grid item xs={8}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    id="erp-select"
                    name="erp"
                    placeholder="Sélectionnez une valeur"
                    onChange={handleChange}
                  >
                    {erps?.map((erp) => (
                      <MenuItem key={erp.id} value={erp.id}>
                        {erp.name} (Version {erp.version})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4} sx={{ mt: 2 }}>
                Format*
              </Grid>
              <Grid item xs={8}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select id="export_format-select" name="export_format" onChange={handleChange}>
                    {formats?.map((format) => (
                      <MenuItem key={format.id} value={format.id}>
                        {format.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        {failedExport && (
          <Alert severity="error">
            Veuillez vérifier que le format d'export choisi est correctement paramétré{' '}
            <Link href={`/boi/extractor/export-formats/settings/${formData.export_format}`}>
              <strong> ici</strong>
            </Link>
            .
          </Alert>
        )}
        <DialogActions>
          <Button onClick={handleExportPopup} color="primary" autoFocus>
            Annuler
          </Button>
          <Button
            onClick={launchExport}
            color="primary"
            autoFocus
            disabled={!formData.export_format || exportInProgress}
          >
            {exportInProgress && <CircularProgress size={18} thickness={5} />}
            Exporter
          </Button>
        </DialogActions>
      </Dialog>

      <BirdzNotif options={notifOptions} />
    </>
  );
}
