import { Box, Button, CircularProgress, TextField } from '@mui/material';
import React, { useState } from 'react';
import { authService } from '../../..';
import axios from 'axios';
import { ModalItem } from '../DMSTypes';
import {
  BirdzModal,
  BirdzModalActions,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';

type DMSModalCreateFolderProps = {
  item: Partial<ModalItem>;
  onClose: (successMessage?: string) => void;
  onError: (errorMessage: string) => void;
};

export const DMSModalCreateFolder = ({ item, onClose, onError }: DMSModalCreateFolderProps) => {
  const [folderName, setFolderName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createFolder = () => {
    if (!authService.isAdminGED()) {
      onError("Vous n'avez pas les droits de créer un dossier");
      return;
    }

    setIsLoading(true);
    axios
      .post('/api/boi/dms/create-folder/', { parent_file_id: item.id, name: folderName })
      .then(() => {
        onClose('Le dossier a été créé avec succès');
      })
      .catch(() => {
        onError('Une erreur est survenue lors de la création du dossier');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <BirdzModal open={true}>
      <BirdzModalTitle>Création d'un nouveau dossier</BirdzModalTitle>
      <BirdzModalContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            id="name"
            focused
            label="Dossier à créer"
            value={folderName}
            onChange={(value: any) => {
              setFolderName(value.target.value);
            }}
            fullWidth
          />
        </Box>
      </BirdzModalContent>
      <BirdzModalActions>
        <Button id="close" variant="outlined" onClick={() => onClose()}>
          Retour
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            createFolder();
          }}
          disabled={isLoading}
        >
          {isLoading && <CircularProgress size={16} thickness={5} sx={{ mr: 1 }} />} Créer
        </Button>
      </BirdzModalActions>
    </BirdzModal>
  );
};
