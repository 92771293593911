import { blue } from '@mui/material/colors';
import { createTheme, PaletteColor } from '@mui/material/styles';
import { frFR } from '@mui/x-date-pickers/locales';
const customBlue: PaletteColor = {
  light: '',
  main: '#253053',
  dark: '',
  contrastText: 'white'
};

const customSkyBlue: PaletteColor = {
  light: '',
  main: '#64a8c8',
  dark: '',
  contrastText: 'white'
};

export const boiTheme = createTheme(
  {
    palette: {
      primary: customBlue,
      secondary: blue
    },
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            verticalAlign: 'middle'
          }
        }
      }
    }
  },
  frFR
);

export const boeTheme = createTheme(
  {
    palette: {
      primary: customSkyBlue,
      secondary: blue
    },
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            verticalAlign: 'middle'
          }
        }
      }
    }
  },
  frFR
);
