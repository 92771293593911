import React, { useEffect, useState } from 'react';
import {
  ListPage,
  BirdzDialog,
  useDialog,
  AppPaper,
  BirdzTitle,
  FormField,
  Item
} from '@applications-terrains/birdz-react-library';
import { Link } from 'react-router-dom';
import moment from 'moment';
import * as Yup from 'yup';
import { Message } from '../../../types';
import axios from 'axios';
import { Button, Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNotif, BirdzNotif } from '@applications-terrains/birdz-react-library';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { formatToSelectOptions, useGetMessagesTypes } from '../../../hooks/datarefs';

const Messages = (params: any) => {
  const endpoint = '/api/boi/administration/messages/';
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const { data: messagesTypes } = useGetMessagesTypes();
  const [formFields, setFormFields] = useState<FormField[]>([]);

  useEffect(() => {
    if (!messagesTypes) return;

    const formFields: FormField[] = [
      {
        name: 'title',
        type: 'text',
        label: 'Titre message',
        validation: Yup.string().required('Vous devez renseigner le titre du message')
      },
      {
        name: 'type',
        type: 'select',
        label: 'Type message',
        options: {
          values: formatToSelectOptions(messagesTypes),
          identifier: 'label'
        },
        validation: Yup.mixed().required('Vous devez renseigner le type du message')
      },
      {
        name: 'destination',
        label: 'Destination',
        type: 'select-multiple',
        options: {
          values: [
            { value: 'Support', label: 'Support' },
            { value: 'Sous-traitant', label: 'Sous-traitant' },
            { value: 'Technicien', label: 'Technicien' }
          ]
        }
      },

      {
        name: 'content',
        type: 'richtext',
        label: 'Contenu message',
        validation: Yup.string().required('Vous devez renseigner le contenu du message')
      },
      {
        name: 'is_active',
        type: 'radio',
        label: 'Etat',
        options: {
          values: [
            { value: true, label: 'Actif' },
            { value: false, label: 'Inactif' }
          ]
        },
        defaultValue: true,
        permissions: ['IS_ACTIVE']
      },
      {
        name: 'updated_at',
        type: 'readonly',
        label: 'Dernière modification',
        transform: (value: any) => {
          const date = value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss');
          return date || '';
        }
      }
    ];
    setFormFields(formFields);
  }, [messagesTypes]);

  const listFields = [
    {
      name: 'title',
      label: 'Titre Message'
    },
    {
      name: 'type',
      label: 'Type'
    },
    {
      name: 'destination',
      label: 'Destination'
    },
    {
      name: 'is_active',
      label: 'Actif',
      className: 'text-center',
      options: { width: '130px' },
      orderable: true
    },
    {
      name: 'updated_at',
      label: 'Date de modification',
      transform: (value: any) => {
        return (value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
      },
      orderable: true,
      options: { width: '240px' }
    }
  ];

  const searchFields = [
    {
      name: 'title',
      id: 'search_by_title',
      label: 'Titre message',
      options: {
        identifier: 'title',
        source: endpoint,
        label: 'title',
        searchIsMulti: false
      }
    },

    {
      name: 'destination',
      id: 'search_by_destination',
      label: 'Destination',
      type: 'select',
      options: {
        values: [
          { value: 'Support', label: 'Support' },
          { value: 'Sous-traitant', label: 'Sous-traitant' },
          { value: 'Technicien', label: 'Technicien' }
        ]
      }
    }
  ];

  const actions = [
    {
      name: 'edit',
      label: 'Modifier le message',
      render: (message: Message) => {
        return (
          <IconButton component={Link} to={`/boi/administration/messages/edit/${message.id}`}>
            <EditIcon fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      name: 'delete-message',
      label: 'Supprimer le message',
      render: (model: any) => {
        return (
          <IconButton
            onClick={() => {
              confirmDialog({
                title: 'Supprimer le message',
                content: 'Êtes-vous sûr de vouloir supprimer ce message?',
                onValidate: () => {
                  axios.delete(`${endpoint}${model.id}/`).then(
                    () => {
                      notif({
                        content: 'Le message a été supprimé avec succès',
                        type: 'success'
                      });
                      document.location.reload();
                    },
                    () => {
                      notif({
                        content: 'Une erreur est survenue lors de la suppression',
                        type: 'error'
                      });
                    }
                  );
                  closeDialog();
                },
                onCancel: () => {
                  closeDialog();
                }
              });
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  return (
    <AppPaper>
      {params.action === 'list' && (
        <>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <BirdzTitle>Liste des messages</BirdzTitle>
            </Grid>
            <Grid item>
              <Link to="/boi/administration/messages/add">
                <Button variant="contained" sx={{ mt: 3 }}>
                  <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un message
                </Button>
              </Link>
            </Grid>
          </Grid>

          <ListPage
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
          />
          <BirdzDialog options={dialogOptions} />
        </>
      )}
      {params.action === 'add' && (
        <Item action="add" endpoint={endpoint} fields={formFields} name="Ajouter un message" />
      )}
      {params.action === 'edit' && (
        <Item action="edit" endpoint={endpoint} fields={formFields} name="Modifier le message" />
      )}
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
};

export default Messages;
