import React, { ReactNode, createContext, useContext, useState } from 'react';

type GlobalContextType = {
  values: { [key: string]: unknown };
  setValues: (value: { [key: string]: unknown }) => void;
};

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error('useGlobalContext must be used with a GlobalContext');
  }

  return context;
};

export const GlobalContextProvider = ({ children }: { children: ReactNode }) => {
  const [values, setValues] = useState<GlobalContextType['values']>({});

  const value = {
    values,
    setValues
  };

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};
