import React, { useState } from 'react';
import { ListPage, AppPaper, BirdzTitle } from '@applications-terrains/birdz-react-library';
import { useParams } from 'react-router-dom';
import { Grid, Paper } from '@mui/material';
import { SearchField } from '@applications-terrains/birdz-react-library';
import { Correspondance } from '../types';
import _ from 'lodash';

const CorrespondanceDetails = () => {
  const { id } = useParams();

  const target = 'translations.results';

  const [correspondanceDetails, setCorrespondanceDetails] = useState<Correspondance>();

  const listFields = [
    { name: 'code', label: 'Code Birdz', orderable: true },
    { name: 'translation', label: 'Traduction ERP', orderable: true }
  ];

  const getFilters = () => {
    type Filter = { label: string; value: string };
    const codeValues: Filter[] = [];
    const translationValues: Filter[] = [];
    correspondanceDetails &&
      _.get(correspondanceDetails, target).map((el) => {
        codeValues.push({ label: el.code, value: el.code });
        translationValues.push({ label: el.translation, value: el.translation });
      });
    return {
      code: codeValues,
      translation: translationValues
    };
  };

  const searchFields: SearchField[] = [
    {
      name: 'code',
      label: 'Nom',
      type: 'select',
      options: {
        values: getFilters().code
      }
    },
    {
      name: 'translation',
      label: 'Traduction',
      type: 'select',
      options: {
        values: getFilters().translation
      }
    }
  ];

  return (
    <AppPaper>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <BirdzTitle>Consultation d'une liste de correspondances</BirdzTitle>
        </Grid>
      </Grid>
      <Paper variant="outlined" square sx={{ p: 2, mb: 1 }}>
        <Grid container>
          {[
            { label: 'ERP', value: correspondanceDetails?.erp_name },
            { label: 'Nom', value: correspondanceDetails?.name }
          ].map((item, index) => (
            <Grid
              key={(item.value || 'value') + index}
              container
              alignItems={'center'}
              className="mb-1"
            >
              <Grid item xs={1}>
                {item.label}
              </Grid>
              <Grid item xs={11}>
                {item.value}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <ListPage
        fields={listFields}
        searchFields={searchFields}
        endpoint={`/api/boi/extractor/data-mapping/${id}`}
        resultsField={target}
        displayPaginationOptions={true}
        onLoadItems={(_, data) => {
          setCorrespondanceDetails(data);
        }}
      />
    </AppPaper>
  );
};

export default CorrespondanceDetails;
