import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import './NetworkStatus.scss';
import axios from 'axios';
import moment from 'moment';
import { ModuleData } from '../CR.types';
import { StyledTableBodyRow, StyledTableHeaderRow } from '../../../../styles/Table.styled';
import { Alert, Box, TableCell } from '@mui/material';
import DeviceMap from '../DeviceMap';

type Network = {
  TECH_2G: TechProps;
  TECH_3G: TechProps;
  TECH_4G: TechProps;
  TECH_4G_LTEM: TechProps;
  TECH_4G_LTEM_EC: TechProps;
  DATA_VOICE_2G: IncidentProps;
  DATA_3G: IncidentProps;
  VOICE_3G: IncidentProps;
  DATA_VOICE_4G: IncidentProps;
  DATA_VOICE_4G_LTEM: IncidentProps;
  DATA_VOICE_4G_LTEM_EC: IncidentProps;
  status: string;
  resolution_message?: string;
};
interface TechProps {
  coverage: string;
  quality_coverage: number;
}
interface IncidentProps {
  incident: string;
  date_start?: string;
  date_end?: string;
}

type NetworkStatusProps = {
  moduleData: ModuleData;
};

const NetworkStatus = ({ moduleData }: NetworkStatusProps) => {
  const [statuses, setStatuses] = useState<Network>();

  useEffect(() => {
    if (moduleData) {
      const fetchData = async () => {
        await axios
          .get(
            '/api/boi/external/network-status/?latitude=' +
              moduleData?.latitude +
              '&longitude=' +
              moduleData?.longitude
          )
          .then((response: any) => {
            setStatuses(response.data.network_status);
          });
      };
      fetchData();
    }
  }, [moduleData]);

  const showSignalIcon = (coverage: number) => {
    if (coverage >= 0 && coverage <= 4) {
      return <img alt={coverage.toString()} src={`/networkIcons/covNet_${coverage}.png`} />;
    }
    return null;
  };

  const showService = () => {
    const status = statuses?.status;
    if (status && ['MINOR', 'MAJOR', 'OK'].includes(status)) {
      return (
        <img alt={status.toString()} src={`/networkIcons/status-${status.toLowerCase()}.png`} />
      );
    }
    return '-';
  };

  const showIncidents = (property: IncidentProps) => {
    const getIncidentLabel = (incident: string) => {
      switch (incident) {
        case 'NO_INCIDENT':
          return 'Aucun incident';
        case 'NO_INFORMATION_AVAILABLE':
          return 'Information non disponible';
        case 'MAJOR_INCIDENT':
          return 'Incident majeur';
        default:
          return incident;
      }
    };

    const incidentLabel = getIncidentLabel(property?.incident);
    const startDate = property?.date_start
      ? moment(property.date_start).format('DD/MM/YYYY à HH:mm:ss')
      : '';
    const endDate = property?.date_end
      ? moment(property.date_end).format('DD/MM/YYYY à HH:mm:ss')
      : '';

    return (
      <>
        <TableCell>{incidentLabel}</TableCell>
        <TableCell>{startDate}</TableCell>
        <TableCell>{endDate}</TableCell>
      </>
    );
  };

  const displayRow = (techno: string) => {
    if (!statuses || !techno) return;

    const key: keyof Network = ('TECH_' + techno) as keyof Network;
    const techProp = statuses[key] as TechProps;
    const coverage = techProp && techProp?.quality_coverage ? techProp.quality_coverage : null;

    return (
      <StyledTableBodyRow>
        <TableCell>{techno}</TableCell>
        <TableCell align="right">{coverage && showSignalIcon(coverage)}</TableCell>
        <TableCell></TableCell>
        <TableCell align="right">{showService()}</TableCell>
      </StyledTableBodyRow>
    );
  };

  return statuses ? (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <DeviceMap moduleData={moduleData} />
      </Grid>

      <Grid item xs={7}>
        <Box>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <StyledTableHeaderRow>
                  <TableCell sx={{ width: '120px' }}></TableCell>
                  <TableCell>Couverture réseau</TableCell>
                  <TableCell colSpan={2}>Qualité de service</TableCell>
                </StyledTableHeaderRow>
              </TableHead>
              <TableBody>
                {displayRow('2G')}

                <StyledTableBodyRow key="TECH_3G">
                  <TableCell rowSpan={2}>3G</TableCell>
                  <TableCell rowSpan={2} align="right">
                    {statuses?.TECH_3G?.quality_coverage &&
                      showSignalIcon(statuses?.TECH_3G?.quality_coverage)}
                  </TableCell>
                  <TableCell>data</TableCell>
                  <TableCell align="right">{showService()}</TableCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableCell>voix</TableCell>
                  <TableCell align="right">{showService()}</TableCell>
                </StyledTableBodyRow>

                {displayRow('4G')}
                {displayRow('4G_LTEM')}
                {displayRow('4G_LTEM_EC')}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer sx={{ mt: 1 }} component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <StyledTableHeaderRow>
                  <TableCell sx={{ width: '25px' }}>Technologies</TableCell>
                  <TableCell sx={{ width: '15px' }}></TableCell>
                  <TableCell>Classification</TableCell>
                  <TableCell>Début</TableCell>
                  <TableCell>Fin</TableCell>
                </StyledTableHeaderRow>
              </TableHead>
              <TableBody>
                <StyledTableBodyRow key="TECH_2G">
                  <TableCell>2G</TableCell>
                  <TableCell></TableCell>
                  {showIncidents(statuses?.DATA_VOICE_2G)}
                </StyledTableBodyRow>

                <StyledTableBodyRow key="TECH_3G">
                  <TableCell rowSpan={2}>3G</TableCell>
                  <TableCell align="left">data</TableCell>
                  {showIncidents(statuses?.DATA_3G)}
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <TableCell>voix</TableCell>
                  {showIncidents(statuses?.VOICE_3G)}
                </StyledTableBodyRow>

                <StyledTableBodyRow key="TECH_4G">
                  <TableCell>4G</TableCell>
                  <TableCell></TableCell>
                  {showIncidents(statuses?.DATA_VOICE_4G)}
                </StyledTableBodyRow>

                <StyledTableBodyRow key="TECH_4G_LTEM">
                  <TableCell>4G LTEM</TableCell>
                  <TableCell></TableCell>
                  {showIncidents(statuses?.DATA_VOICE_4G_LTEM)}
                </StyledTableBodyRow>

                <StyledTableBodyRow key="TECH_4G_LTEM_EC">
                  <TableCell>4G LTEM EC</TableCell>
                  <TableCell></TableCell>
                  {showIncidents(statuses?.DATA_VOICE_4G_LTEM_EC)}
                </StyledTableBodyRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Alert severity="info">{statuses?.resolution_message || 'Aucun incident récent'}</Alert>
        </Box>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default NetworkStatus;
