import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import {
  CheckboxField,
  InputField,
  ListPage,
  SelectField,
  useNotif
} from '@applications-terrains/birdz-react-library';
import { BirdzNotif } from '@applications-terrains/birdz-react-library';
import { Backdrop, Box } from '@mui/material';
import { authService } from '../../..';
import { formatToSelectOptions, useGetGroups } from '../../../hooks/datarefs';
import { useNavigate } from 'react-router-dom';

interface FormValues {
  user__name: string;
  user__email: string;
  user__phone: string;
  user__password?: string;
  main_contact: boolean;
  groups: number[] | null;
  id?: number;
  user?: number;
}

interface DeleteValue {
  id: number;
}

const TechniciansBOE = () => {
  const [listIsVisible, setListIsVisible] = useState<boolean>(true);
  const { data: groups } = useGetGroups();
  const { notif, notifOptions } = useNotif();
  const [fields, setFields] = useState<any[]>([]);
  const [counter, setCounter] = useState<number>(3);
  const [triggerCounter, setTriggerCounter] = useState<boolean>(false);
  const navigate = useNavigate();

  const onCounterEnd = () => {
    setTriggerCounter(false);
    navigate('/auth');
  };

  useEffect(() => {
    if (triggerCounter && counter > 0) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }
    if (counter === 0) {
      onCounterEnd();
    }
  }, [counter, triggerCounter, onCounterEnd]);

  useEffect(() => {
    const fields = [
      {
        name: 'user__name',
        label: 'Technicien',
        transform: (value: any, object: any) => object?.user__name,
        options: {
          renderForm: () => (
            <InputField
              name="user__name"
              type="text"
              label="Nom prénom"
              size="small"
              className="mt-25"
            />
          )
        }
      },
      {
        name: 'groups',
        label: "Gestionnaire / Chef d'équipe / Technicien",
        transform: (values: any) => {
          return values
            .map((value: number) => {
              const group = groups?.find((dataServiceGroup: any) => dataServiceGroup.id === value);
              return group?.name || value || '-';
            })
            .join(', ');
        },
        options: {
          renderForm: () => (
            <SelectField
              id="group"
              name="groups"
              options={formatToSelectOptions(groups, {
                label: 'name'
              })}
              isMulti
              style={{ marginTop: '0.12rem' }}
            />
          )
        }
      },
      {
        name: 'user__email',
        label: 'E-mail',
        transform: (_value: any, object: any) => object?.user__email || '-',
        options: {
          renderForm: () => (
            <InputField
              name="user__email"
              type="email"
              label="E-mail"
              size="small"
              className="mt-25"
            />
          )
        }
      },
      {
        name: 'user__password',
        label: 'Mot de passe',
        transform: () => '',
        options: {
          renderForm: () => (
            <InputField
              name="user__password"
              type="password"
              label="Mot de passe"
              size="small"
              className="mt-25"
            />
          )
        }
      },
      {
        name: 'user__phone',
        label: 'Téléphone',
        transform: (_value: any, object: any) => object?.user__phone || '-',
        options: {
          renderForm: () => (
            <InputField
              name="user__phone"
              type="text"
              label="Téléphone"
              size="small"
              className="mt-25"
            />
          )
        }
      },
      {
        name: 'main_contact',
        label: 'Contact Principal',
        options: {
          renderForm: () => (
            <CheckboxField id="mainContact" name="main_contact" label="" className="mt-25" />
          )
        }
      }
    ];
    setFields(fields);
  }, [groups]);

  const refreshList = () => {
    setListIsVisible(false);
    setTimeout(() => {
      setListIsVisible(true);
    });
  };

  const showServerError = (error: any) => {
    const content = (
      <>
        Une erreur serveur est survenue, merci de réessayer ultérieurement
        {error?.response?.data?.message && (
          <>
            <br />
            <br />
            Erreur: {error?.response?.data?.message}
          </>
        )}
      </>
    );

    notif({
      content,
      type: 'error'
    });
  };

  let onSave: any = (values: FormValues) => {
    let payload: FormValues = {
      user__name: values.user__name,
      groups: values.groups,
      user__phone: values.user__phone,
      user__email: values.user__email,
      main_contact: values.main_contact ?? false
    };

    if (values.user__password) payload = { ...payload, user__password: values.user__password };

    if (values?.id) {
      if (values.user) payload = { ...payload, user: values.user };
      return axios.put(`/api/boe/subcontractors/employees/${values.id}/`, payload).then(
        () => {
          const redirectToAuth = payload.user__password && payload.main_contact === true;
          if (redirectToAuth) {
            setTriggerCounter(true);
          } else {
            notif({
              type: 'success',
              content: `L'employé a été modifié avec succès`
            });
            refreshList();
          }
        },
        (error: any) => showServerError(error)
      );
    } else {
      return axios.post(`/api/boe/subcontractors/employees/`, payload).then(
        () => {
          notif({
            type: 'success',
            content: "L'employé a été créé avec succès"
          });
          refreshList();
        },
        (error: any) => showServerError(error)
      );
    }
  };

  let onDelete: any = (values: DeleteValue) => {
    return axios
      .delete(`/api/boe/subcontractors/employees/${values.id}/`)
      .then(() => {
        notif({
          content: "L'employé a été supprimé avec succès",
          type: 'error'
        });
        refreshList();
      })
      .catch((error: any) => showServerError(error));
  };

  if (!authService.canAccess('ADMIN_MENU_ST_CREATE')) {
    onSave = undefined;
    onDelete = undefined;
  }

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <h3>Techniciens / Contact</h3>
        {listIsVisible && (
          <ListPage
            endpoint={`/api/boe/subcontractors/employees/`}
            fields={fields}
            inlineEditOptions={{
              onSave: onSave,
              onDelete: onDelete,
              validationSchema: Yup.object().shape({
                user__name: Yup.string().required('Champ obligatoire'),
                user__password: Yup.string().min(12, 'minimum 12 caractères'),
                user__email: Yup.string()
                  .email('Vous devez saisir un e-mail valide')
                  .required('Champ obligatoire'),
                groups: Yup.array().required('Vous devez choisir au moins un groupe')
              })
            }}
          />
        )}
        <BirdzNotif options={notifOptions} />
      </Box>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: '1.2rem'
        }}
        open={triggerCounter}
        onClick={() => onCounterEnd()}
      >
        <div className="d-flex flex-column align-items-center">
          Les modifications ont été enregistrées avec succès. Vous allez devoir vous reconnecter
          avec le nouveau mot de passe dans :
          <strong className="mt-1" style={{ fontSize: '2rem' }}>
            {counter}
          </strong>
        </div>
      </Backdrop>
    </>
  );
};

export default TechniciansBOE;
