import React from 'react';
import { Chip, Stack } from '@mui/material';

type MrCaseEquipmentCountProps = {
  equipmentsCount?: MrCaseEquipment;
};

type MrCaseEquipment = {
  BRIDGE: number;
  CELLULE: number;
  REPETEUR: number;
};

export default function MrCaseEquipmentCount({ equipmentsCount }: MrCaseEquipmentCountProps) {
  return (
    <Stack spacing={2} direction="row" justifyContent={'right'} sx={{ mt: 2 }}>
      <Chip
        label={
          <>
            Bridge(s): <strong>{equipmentsCount?.BRIDGE}</strong>
          </>
        }
        variant="outlined"
      />
      <Chip
        label={
          <>
            Cellule(s): <strong>{equipmentsCount?.CELLULE}</strong>
          </>
        }
        variant="outlined"
      />
      <Chip
        label={
          <>
            Répéteur(s): <strong>{equipmentsCount?.REPETEUR}</strong>
          </>
        }
        variant="outlined"
      />
    </Stack>
  );
}
