import React from 'react';
import { ListPage } from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Call } from './SearchCallsForm';
import ReplayIcon from '@mui/icons-material/Replay';

export default function CallsListing() {
  const endpoint = '/api/boi/calls/in-calls-history/';
  const navigate = useNavigate();

  const replayCallSearch = async (call: Call) => {
    const urlParams = [];
    const params: Array<keyof Call> = [
      'technician',
      'incoming_call_number',
      'device_id',
      'case_number'
    ];
    params.forEach((paramName) => {
      const paramValue = call[paramName];
      if (paramValue) {
        urlParams.push(`${paramName}=${paramValue}`);
      }
    });
    if (call.technician__name) {
      urlParams.push(`technician_name=${call.technician__name}`);
    }

    navigate(`/boi/calls/new/?${urlParams.join('&')}`);
  };

  const listFields = [
    {
      name: 'created_at',
      label: 'Date',
      className: 'text-center',
      transform: (value: any) => {
        return moment.unix(value).format('DD/MM/YYYY à HH:mm');
      },
      orderable: true
    },
    {
      name: 'created_by__name',
      label: 'Agent Support',
      className: 'text-center'
    },
    {
      name: 'device_id',
      label: 'Adresse radio',
      className: 'text-center'
    },
    {
      name: 'technician__name',
      label: 'Technicien',
      className: 'text-center'
    },
    {
      name: '',
      label: 'Rappel de la recherche',
      className: 'text-center',
      transform: (value: any, item: any) => {
        return (
          <IconButton onClick={() => replayCallSearch(item)}>
            <ReplayIcon fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  const searchFields = [
    {
      name: 'created_by',
      label: 'Agent support',
      options: {
        identifier: 'id',
        source: '/api/boi/calls/support-agents/',
        label: 'name',
        searchIsMulti: false
      }
    },
    {
      name: 'device_id',
      label: 'Équipement',
      options: {
        source: '/api/boi/devices/search/',
        label: 'device_id',
        searchIsMulti: false,
        formatResponse: (deviceId: string) => {
          return { value: deviceId, label: deviceId };
        }
      }
    }
  ];

  return (
    <ListPage
      endpoint={endpoint}
      fields={listFields}
      searchFields={searchFields}
      defaultOrder={['-created_at']}
    />
  );
}
