//This component represents a folder inside the DMS (GED)
//It includes a checkbox, a name and
//four buttons that call the DMSModal component to execute the following operations :
//Display folder details, manage reading rights on the folder, rename folder, delete folder.

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import DMSFile from './DMSFile';
import { File, Folder, DMSFolderProps, AssociatedDrive, ModalAction } from './DMSTypes';
import InfoIcon from '@mui/icons-material/Info';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { DMSActions } from './DMS.styled';
import { authService } from '../..';
import DMSModal from './DMSModal/DMSModal';

const DMSFolder = (dmsFolder: DMSFolderProps) => {
  const [checked, setChecked] = useState(false);
  const [hidden, setHidden] = useState(false); //This state is set to true when the folder is deleted.
  const [isHover, setIsHover] = useState(false);
  //The folder still exists but isn't displayed until the page or the parent folder is reloaded
  const [modalAction, setModalAction] = useState<ModalAction>('');
  const [folders, setFolders] = useState<Folder[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentName, setCurrentName] = useState(dmsFolder.name); //The current name of the folder can differ from the name retrieved from database
  //Useful when the folder is renamed but not reloaded yet.

  const updateContents = () => {
    let endpoint;
    if (authService.isAdminGED()) {
      endpoint = `/api/boi/dms/list-files/`;
    } else if (authService.isViewerGED()) {
      endpoint = `/api/boe/dms/list-files/`;
    }

    if (endpoint) {
      setLoading(true);
      axios.get(`${endpoint}?folder_id=${dmsFolder.id}`).then((response: any) => {
        const data = response.data;
        setFolders(data.folders);
        setFiles(data.files);
        setLoading(false);
        return data;
      });
    }
  };

  const updateName = () => {
    let endpoint;
    if (authService.isAdminGED()) {
      endpoint = `/api/boi/dms/file/`;
    } else if (authService.isViewerGED()) {
      endpoint = `/api/boe/dms/file/`;
    }

    if (endpoint) {
      axios.get(`${endpoint}?file_id=${dmsFolder.id}`).then((response: any) => {
        const data = response.data;
        setCurrentName(data.name);
        updateContents();
        return data;
      });
    }
  };

  return (
    <>
      {!hidden && (
        <Accordion>
          <AccordionSummary
            id="contents"
            onClick={updateContents}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            style={isHover ? { background: '#F2F2F2' } : {}}
            sx={{ height: 64 }}
          >
            <Grid container>
              <Grid item alignItems="center" sx={{ display: 'flex' }}>
                {dmsFolder.readOnly === false && (
                  <Checkbox
                    id={dmsFolder.id}
                    checked={checked}
                    readOnly
                    onClick={(e) => {
                      e.stopPropagation();
                      setChecked(!checked);
                      dmsFolder.checkFile(dmsFolder.id, '📁' + dmsFolder.name + ' ', !checked);
                    }}
                    sx={{ p: 0 }}
                  />
                )}
                <FolderIcon id="folderName" sx={{ ml: 1, mr: 1 }} />
                {currentName}
              </Grid>
              {isHover && (
                <>
                  <Grid sx={{ marginLeft: 'auto' }}>
                    {dmsFolder.readOnly === false && (
                      <DMSActions>
                        <Tooltip title="Uploader un fichier">
                          <IconButton
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                              e.stopPropagation();
                              setModalAction('uploadFile');
                            }}
                          >
                            <UploadFileIcon id="uploadFile" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Créer un dossier">
                          <IconButton
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                              e.stopPropagation();
                              setModalAction('createFolder');
                            }}
                          >
                            <CreateNewFolderIcon id="createFolder" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Informations">
                          <IconButton
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                              e.stopPropagation();
                              setModalAction('infos');
                            }}
                          >
                            <InfoIcon id="info" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Droits">
                          <IconButton
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                              e.stopPropagation();
                              setModalAction('permissions');
                            }}
                          >
                            <AccountCircleIcon id="rights" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Renommer">
                          <IconButton
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                              e.stopPropagation();
                              setModalAction('rename');
                            }}
                          >
                            <EditIcon id="rename" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Supprimer">
                          <IconButton
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                              e.stopPropagation();
                              setModalAction('delete');
                            }}
                          >
                            <DeleteIcon id="delete" />
                          </IconButton>
                        </Tooltip>
                      </DMSActions>
                    )}

                    {dmsFolder.onSelectDrive && (
                      <Button
                        variant="outlined"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (typeof dmsFolder.onSelectDrive === 'function') {
                            dmsFolder?.onSelectDrive({ id: dmsFolder.id, name: dmsFolder.name });
                          }
                        }}
                      >
                        Choisir
                      </Button>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </AccordionSummary>

          <AccordionDetails>
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                {folders &&
                  folders.map((folder: Folder) => {
                    return (
                      <DMSFolder
                        key={folder.id}
                        id={folder.id}
                        name={folder.name}
                        created_by={folder.created_by}
                        created_at={folder.created_at}
                        last_user_modified={folder.last_user_modified}
                        mimeType={folder.mimeType}
                        users={dmsFolder.users}
                        checkFile={dmsFolder.checkFile}
                        readOnly={dmsFolder.readOnly}
                        listOnlyFolders={dmsFolder.listOnlyFolders}
                        checkedItems={dmsFolder.checkedItems}
                        onSelectDrive={
                          typeof dmsFolder.onSelectDrive === 'function'
                            ? (driveProps: AssociatedDrive) => {
                                if (typeof dmsFolder.onSelectDrive === 'function') {
                                  dmsFolder?.onSelectDrive(driveProps);
                                }
                              }
                            : undefined
                        }
                      />
                    );
                  })}
                {dmsFolder.listOnlyFolders === false && (
                  <Box sx={{ mt: 1 }}>
                    {files &&
                      files.map((file: File) => {
                        return (
                          <DMSFile
                            key={file.id}
                            id={file.id}
                            name={file.name}
                            created_by={file.created_by}
                            created_at={file.created_at}
                            last_user_modified={file.last_user_modified}
                            mimeType={file.mimeType}
                            size={file.size}
                            users={dmsFolder.users}
                            checkFile={dmsFolder.checkFile}
                            readOnly={dmsFolder.readOnly}
                          />
                        );
                      })}
                    {(!files || files.length === 0) && (
                      <Alert severity="info" sx={{ mt: 1 }}>
                        Aucun fichier
                      </Alert>
                    )}
                  </Box>
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      )}

      <DMSModal
        action={modalAction}
        onClose={() => {
          setModalAction('');
          if (authService.isAdminGED()) {
            updateName();
          }
        }}
        item={{
          id: dmsFolder.id,
          name: currentName,
          type: 'folder',
          meta: {
            created_by: dmsFolder.created_by,
            created_at: dmsFolder.created_at
          },
          checkedItems: dmsFolder.checkedItems,
          userList: dmsFolder.users,
          onDelete: () => setHidden(true)
        }}
      />
    </>
  );
};

export default DMSFolder;
