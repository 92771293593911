import { Box, FormControl, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InterventionStatus from './InterventionStatus';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import {
  AppPaper,
  BirdzTitle,
  DatePickerField,
  TimePickerField
} from '@applications-terrains/birdz-react-library';
import { authService } from '../../../';
import CrCaseStatus from '../Cases/CrCaseStatus';

type InterventionInformationsProps = {
  readOnly?: boolean;
};

const InterventionInformations = ({ readOnly = false }: InterventionInformationsProps) => {
  const [failuresCodes, setFailureCodes] = useState<any>();
  const [techNames, setTechNames] = useState<any>();
  const { getFieldProps, values }: any = useFormikContext();

  useEffect(() => {
    const fetchFailureCodes = async () => {
      const endpoint = authService.getEndpoint(
        '/api/boi/params/failure-codes/',
        '/api/boe/params/failure-codes/'
      );
      await axios.get(endpoint).then((response) => {
        setFailureCodes(response.data.results);
      });
    };

    const fetchTech = async () => {
      const endpoint = authService.getEndpoint(
        '/api/boi/subcontractors/technicians/?subcontractor',
        '/api/boe/subcontractors/technicians/?subcontractor'
      );
      await axios.get(endpoint).then((response) => {
        setTechNames(response.data.results);
      });
    };

    fetchFailureCodes();
    fetchTech();
  }, []);

  return (
    <AppPaper variant="outlined" square sx={{ mt: 2 }}>
      <BirdzTitle>Informations générales</BirdzTitle>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
          marginBottom: '-70px',
          marginTop: '-50px'
        }}
      >
        {authService.canAccessBOI() && (
          <Box sx={{ display: 'flex', textAlign: 'right', alignItems: 'center' }}>
            <Typography sx={{ mr: 1, fontSize: '15px' }}>Statut dossier:</Typography>
            <CrCaseStatus crCaseStatusId={values?.cr_case__status} />
          </Box>
        )}

        <Box sx={{ display: 'flex', textAlign: 'right', alignItems: 'center' }}>
          <Typography sx={{ mr: 1, fontSize: '15px' }}>Statut intervention:</Typography>
          <InterventionStatus interventionStatusId={values?.status} />
        </Box>
      </Box>
      <Grid container sx={{ marginTop: authService.canAccessBOI() ? '15px' : '65px' }}>
        <Grid item xs={12} md={3}>
          <TextField
            id="cr_case__number"
            inputProps={{ readOnly: true }}
            label="Numéro dossier"
            {...getFieldProps('cr_case__number')}
            sx={{ width: 300 }}
            variant="filled"
            disabled={readOnly}
          />
        </Grid>
        {authService.canAccessBOI() && (
          <>
            <Grid item xs={12} md={3}>
              <TextField
                id="subcontractor__name"
                inputProps={{ readOnly: true }}
                label="Sous-traitant"
                {...getFieldProps('subcontractor__name')}
                sx={{ width: 300, ml: 2 }}
                variant="filled"
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  id="observations"
                  label="Observations"
                  multiline
                  fullWidth
                  rows={4}
                  placeholder="Renseignez ce champ..."
                  variant="filled"
                  {...getFieldProps('observation')}
                  disabled={readOnly}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  id="specific_instructions"
                  label="Consignes particulières"
                  multiline
                  fullWidth
                  rows={4}
                  placeholder="Renseignez ce champ..."
                  variant="filled"
                  {...getFieldProps('specific_instructions')}
                  disabled={readOnly}
                />
              </FormControl>
            </Grid>
          </>
        )}
        {authService.canAccessBOE() && (
          <>
            <Grid item xs={6} sx={{ ml: 2 }}>
              <FormControl sx={{ minWidth: 400 }}>
                <TextField
                  id="technician"
                  select
                  label="Technicien"
                  {...getFieldProps('technician')}
                  disabled={readOnly}
                >
                  <MenuItem key={'empty-tech'} value={''}></MenuItem>
                  {techNames && techNames.length > 0 ? (
                    techNames?.map((tech: any) => (
                      <MenuItem key={tech.user} value={tech.user}>
                        {tech.user__name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No options available</MenuItem>
                  )}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={6} sx={{ mt: 2 }}>
              <FormControl sx={{ minWidth: 400 }}>
                <DatePickerField
                  id="intervention_max_date"
                  inputFormat="dd/MM/yyyy"
                  time={false}
                  size="small"
                  label="Date maximum d'intervention"
                  {...getFieldProps('intervention_max_date')}
                  disabled={readOnly}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ mt: 2 }}>
              <Box>
                <Stack direction="row">
                  <FormControl sx={{ minWidth: 400 }}>
                    <TimePickerField
                      id="scheduled_start_date"
                      label="Date planifiée"
                      {...getFieldProps('scheduled_start_date')}
                      disabled={readOnly}
                    />
                  </FormControl>
                  {!readOnly && (
                    <Box sx={{ ml: 2, mt: 2 }}>
                      <Link to="/boe/cr/planning">Planifier</Link>
                    </Box>
                  )}
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <FormControl sx={{ width: '100%' }}>
                <TextField
                  id="specific_instructions 2"
                  label="Consignes particulières"
                  multiline
                  InputProps={{ readOnly: true }}
                  fullWidth
                  rows={4}
                  variant="filled"
                  placeholder="Renseignez ce champ..."
                  {...getFieldProps('specific_instructions')}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <FormControl sx={{ minWidth: '400px' }}>
                <TextField
                  id="failure_code__name"
                  select
                  InputProps={{ readOnly: true }}
                  {...getFieldProps('failure_code__name')}
                  label="Code panne"
                  variant="filled"
                >
                  {failuresCodes && failuresCodes.length > 0 ? (
                    failuresCodes?.map((code: any) => (
                      <MenuItem key={code.id} value={code.label}>
                        {code.label}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No options available</MenuItem>
                  )}
                </TextField>
              </FormControl>
            </Grid>
            {authService.canAccess('CR_INTER_ACT_SUPP') && (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <FormControl sx={{ width: '100%' }}>
                  <TextField
                    id="more_informations"
                    label="Consignes technicien supplémentaires"
                    multiline
                    fullWidth
                    rows={4}
                    placeholder="Renseignez ce champ..."
                    {...getFieldProps('more_informations')}
                    disabled={readOnly}
                  />
                </FormControl>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </AppPaper>
  );
};

export default InterventionInformations;
