import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LinearProgress, Box, Button, Alert } from '@mui/material';
import { Formik, Form } from 'formik';
import CrCasePatrimony from '../Cases/CrCasePatrimony';
import InterventionMedia from './InterventionMedia';
import TechnicianFeedback from '../TechnicianFeedback';
import InterventionInformations from './InterventionInformations';
import * as Yup from 'yup';
import { TechnicianFeedback as TechnicianFeedbackType } from '../Cases/Types';
import InterventionReservations from './InterventionReservations';
import {
  BirdzDialog,
  showFriendlyErrorsHTML,
  useDialog
} from '@applications-terrains/birdz-react-library';
import { InterventionEventsHelper } from '../utils/EventsHelper';
import InterventionPricings from './InterventionPricings';
import InterventionEvents from './InterventionEvents';
import { useNotif } from '@applications-terrains/birdz-react-library';
import { BirdzNotif } from '@applications-terrains/birdz-react-library';
import { authService } from '../../../';
import { useGetInterventionEvents } from '../../../hooks/datarefs';

export const regexp2Decimals = /^(\d+(?:[.,]\d{0,2})?)$/;
export const regexp2NegativeDecimals = /^-?(\d+(?:[.,]\d{0,2})?)$/;
export const regexp8NegativeDecimals = /^-?(\d{1,3}(?:[.,]\d{0,8})?)$/;

export default function InterventionForm() {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const [feedback, setFeedback] = useState<Partial<TechnicianFeedbackType>>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const feedbackFormRef = useRef<HTMLDivElement>(null);
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const navigate = useNavigate();
  const [interventionReadOnly, setInterventionReadOnly] = useState<boolean>(false);
  const { data: interventionEvents } = useGetInterventionEvents();
  const [error, setError] = useState<string>('');

  const OSS_URL = 'https://oss.birdz.com';
  const BLINK_URL = 'https://bidir.birdz.com';

  useEffect(() => {
    loadFeedback();
  }, [id]);

  const loadFeedback = useCallback(() => {
    setLoading(true);
    const endpoint = authService.getEndpoint(
      '/api/boi/cases/technician-feedback/',
      '/api/boe/cases/cr-interventions-details/'
    );
    return axios
      .get(`${endpoint}${id}/?thumbnail_size=300x300`)
      .then(
        (response) => {
          const feedback: Partial<TechnicianFeedbackType> = response.data;
          if (feedback.purchase_order_percent) {
            feedback.purchase_order_percent *= 100;
          }

          if (
            (authService.canAccess('BOI') &&
              InterventionEventsHelper.hasClosedEvent(feedback.events_history)) ||
            (!authService.canAccess('BOI') &&
              authService.canAccess('BOE') &&
              InterventionEventsHelper.hasSubcontractorClosed(feedback.events_history))
          ) {
            setInterventionReadOnly(true);
          } else {
            setInterventionReadOnly(false);
          }

          if (feedback?.technician__is_deleted && feedback?.technician) {
            confirmDialog({
              title: 'Technicien supprimé',
              content: (
                <>
                  Vous consultez une intervention dont le technicien n'existe plus.
                  <br />
                  Pour pouvoir modifier l'intervention, vous devez associer un autre technicien à
                  cette intervention
                </>
              ),
              buttons: [
                <Button key="ok" onClick={closeDialog} id="got_it">
                  Compris
                </Button>
              ],
              okButtonLabel: 'Compris'
            });
          }

          if (location.hash === '#retour') {
            setTimeout(() => feedbackFormRef.current?.scrollIntoView({ behavior: 'smooth' }), 1000);
          }

          setFeedback(feedback);
        },
        (error: any) => {
          notif({
            type: 'error',
            content: "Une erreur est survenue lors du chargement de l'intervention"
          });
          let errorMessage = "Une erreur est survenue lors du chargement de l'intervention.";
          errorMessage += error?.response?.status === 404 ? ' Intervention non trouvée.' : '';
          setError(errorMessage);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const saveFeedback = (values: any) => {
    const payload: any = Object.assign({}, values);
    Object.keys(payload).forEach(function (key) {
      if (payload[key] === '') {
        payload[key] = null;
      }
    });

    // Percents are stored in DB with decimal between 0 and 1
    if (payload.purchase_order_percent) {
      payload.purchase_order_percent = payload.purchase_order_percent / 100;
    }

    // Convert decimal with "dot" into "comma"
    [
      'height',
      'ground_to_antenna_base_height',
      'azimut_antenna_one',
      'azimut_antenna_two',
      'tilt_antenna_one',
      'tilt_antenna_two',
      'latitude_n_field_survey',
      'longitude_n_field_survey'
    ].forEach((fieldName) => {
      if (payload[fieldName]) {
        payload[fieldName] = payload[fieldName].replace('.', ',');
      }
    });

    const endpoint = authService.getEndpoint(
      `/api/boi/cases/technician-feedback/${id}/`,
      `/api/boe/cases/cr-interventions-details/${id}/`
    );
    axios
      .put(endpoint, payload)
      .then((response) => {
        setFeedback(response.data);
        notif({
          type: 'success',
          content: 'Formulaire enregistré avec succès'
        });
      })
      .catch((error) => {
        const errors = error?.response?.data || [];
        notif({
          type: 'error',
          content: showFriendlyErrorsHTML(
            errors,
            'Une erreur est survenue lors de la modification du retour'
          )
        });
      });
  };

  const closeIntervention = () => {
    let content = 'Êtes-vous sûr de vouloir clore cette intervention';

    if (authService.canAccessBOE()) {
      content += ' et avez-vous bien ajouté les libellés pour la facturation?';
    } else {
      content += '?';
    }

    confirmDialog({
      title: "Clôture de l'intervention",
      content,
      onValidate: () => {
        const endpoint = authService.getEndpoint(
          `/api/boi/cases/technician-feedback/${id}/close/`,
          `/api/boe/cases/cr-interventions-details/${id}/close/`
        );
        const payload = Object.assign({}, feedback);
        delete payload.purchase_order_percent;

        axios.post(endpoint, payload).then(
          () => {
            notif({
              type: 'success',
              content: "L'intervention a été clôturée avec succès"
            });
            closeDialog();
            if (authService.canAccess('BOI')) {
              setTimeout(() => {
                navigate(`/boi/cr/cases/edit/${feedback.cr_case}`);
              }, 3000);
            } else if (authService.canAccess('BOE')) {
              loadFeedback();
            }
          },
          (error) => {
            const errors = error?.response?.data || [];
            notif({
              type: 'error',
              content: showFriendlyErrorsHTML(
                errors,
                "Une erreur est survenue lors de la clôture de l'intervention"
              )
            });
          }
        );
      },
      onCancel: () => {
        closeDialog();
      }
    });
    return;
  };

  const closeCase = () => {
    confirmDialog({
      title: 'Clôture du dossier',
      content: 'Êtes-vous sûr de vouloir clôre ce dossier?',
      onValidate: () => {
        axios.post(`/api/boi/cases/cr-cases/${feedback.cr_case}/close/`).then(
          () => {
            notif({
              type: 'success',
              content: 'Le dossier a bien été clos, vous allez être redirigé vers le dossier'
            });
            setTimeout(() => {
              navigate(`/boi/cr/cases/edit/${feedback.cr_case}`);
            }, 2000);
          },
          () => {
            notif({
              type: 'error',
              content: 'Une erreur est survenue lors de la clôture du dossier'
            });
          }
        );
      },
      onCancel: () => {
        closeDialog();
      }
    });
  };

  const createNewIntervention = () => {
    confirmDialog({
      title: 'Créer une nouvelle intervention',
      content:
        "Voulez-vous créer une nouvelle intervention? Les données non sauvegardées dans l'intervention courante seront perdues.",
      onValidate: () => {
        navigate(`/boi/cr/cases/edit/${feedback.cr_case}#createInter`);
      },
      onCancel: () => {
        closeDialog();
      }
    });
    return;
  };

  const goToOSS = () => {
    const OSS_EVENT = interventionEvents?.find(
      (event) => event.code === InterventionEventsHelper.OSS_EVENT_CODE
    );
    if (!OSS_EVENT) return null;
    const payload = {
      date: new Date().toISOString().split('T')[0],
      step: OSS_EVENT.id,
      intervention: feedback.id
    };
    axios.post('/api/boi/cases/events-cr-interventions/', payload).then(() => {
      window.open(
        `${OSS_URL}/dysoweb/page/Inventory_PDC?flt.text=${feedback?.patrimony?.device_id}`
      );
    });
  };

  const goToConfigCR = () => {
    const CONFIGCR_EVENT = interventionEvents?.find(
      (event) => event.code === InterventionEventsHelper.CRSEND_EVENT_CODE
    );
    if (!CONFIGCR_EVENT) return null;
    const payload = {
      date: new Date().toISOString().split('T')[0],
      step: CONFIGCR_EVENT.id,
      intervention: feedback.id
    };
    axios.post('/api/boi/cases/events-cr-interventions/', payload).then(() => {
      window.open(`${BLINK_URL}/configuration-cr?crplus=${feedback?.patrimony?.device_id}`);
    });
  };

  const regexp2DecimalsError =
    'Ce champ doit contenir uniquement des nombres décimaux (max 2 décimales)';
  const regexp8NegativeDecimalsError =
    'Ce champ doit contenir uniquement des nombres décimaux (max 8 décimales)';

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <Formik
          initialValues={feedback}
          enableReinitialize={true}
          onSubmit={(values: any) => {
            saveFeedback(values);
          }}
          validationSchema={Yup.object({
            // Techniciens feedback
            lessor: Yup.string()
              .required('Ce champ est requis')
              .max(50, 'Ce champ ne peut excéder 50 caractères')
              .nullable(),
            lessor_contacts: Yup.string()
              .max(50, 'Ce champ ne peut excéder 50 caractères')
              .nullable(),
            power_type: Yup.number()
              .required('Ce champ est requis')
              .max(50, 'Ce champ ne peut excéder 50 caractères')
              .nullable(),
            latitude_n_field_survey: Yup.string()
              .required('Ce champ est requis')
              .max(15, 'Ce champ ne peut excéder 15 caractères')
              .matches(regexp8NegativeDecimals, regexp8NegativeDecimalsError)
              .nullable(),
            longitude_n_field_survey: Yup.string()
              .required('Ce champ est requis')
              .max(15, 'Ce champ ne peut excéder 15 caractères')
              .matches(regexp8NegativeDecimals, regexp8NegativeDecimalsError)
              .nullable(),
            work_type: Yup.string()
              .required('Ce champ est requis')
              .max(20, 'Ce champ ne peut excéder 20 caractères')
              .nullable(),
            access_constraints: Yup.string()
              .max(50, 'Ce champ ne peut excéder 50 caractères')
              .nullable(),
            amp_antenna_one: Yup.string()
              .max(50, 'Ce champ ne peut excèder 50 caractères')
              .nullable(),
            amp_antenna_two: Yup.string()
              .max(50, 'Ce champ ne peut excèder 50 caractères')
              .nullable(),
            new_amp_antenna_one: Yup.string()
              .max(50, 'Ce champ ne peut excéder 50 caractères')
              .nullable(),
            new_amp_antenna_two: Yup.string()
              .max(50, 'Ce champ ne peut excéder 50 caractères')
              .nullable(),
            azimut_antenna_one: Yup.string()
              .max(20, 'Ce champ ne peut excèder 20 caractères')
              .nullable()
              .matches(regexp2Decimals, regexp2DecimalsError),
            azimut_antenna_two: Yup.string()
              .max(20, 'Ce champ ne peut excèder 20 caractères')
              .nullable()
              .matches(regexp2Decimals, regexp2DecimalsError),
            tilt_antenna_one: Yup.string()
              .max(20, 'Ce champ ne peut excèder 20 caractères')
              .nullable()
              .matches(regexp2NegativeDecimals, regexp2DecimalsError),
            tilt_antenna_two: Yup.string()
              .max(20, 'Ce champ ne peut excèder 20 caractères')
              .nullable()
              .matches(regexp2NegativeDecimals, regexp2DecimalsError),
            old_sim_card_number: Yup.string()
              .max(20, 'Ce champ ne peut excèder 20 caractères')
              .nullable(),
            old_call_number: Yup.string()
              .max(20, 'Ce champ ne peut excèder 20 caractères')
              .nullable(),
            new_sim_card_number: Yup.string()
              .max(20, 'Ce champ ne peut excèder 20 caractères')
              .nullable(),
            new_call_number: Yup.string()
              .max(20, 'Ce champ ne peut excèder 20 caractères')
              .nullable(),
            height: Yup.string().matches(regexp2Decimals, regexp2DecimalsError).nullable(),
            ground_to_antenna_base_height: Yup.string()
              .matches(regexp2Decimals, regexp2DecimalsError)
              .nullable(),
            failure_origin: Yup.string().required('Ce champ est requis').nullable(),
            solution_applied: Yup.string().required('Ce champ est requis').nullable()
          })}
        >
          {({ handleSubmit, validateForm }) => {
            return (
              <Form>
                <InterventionInformations readOnly={interventionReadOnly} />

                <InterventionEvents
                  interventionEvents={feedback.events_history || []}
                  crCaseEvents={feedback.case_events_history || []}
                />
                <CrCasePatrimony results={feedback?.patrimony} />

                {InterventionEventsHelper.hasScheduled(feedback.events_history) && (
                  <>
                    <TechnicianFeedback ref={feedbackFormRef} readOnly={interventionReadOnly} />
                    <InterventionMedia />
                  </>
                )}

                {InterventionEventsHelper.hasTechnicianClosed(feedback.events_history) && (
                  <>
                    <InterventionPricings readOnly={interventionReadOnly} />
                    <InterventionReservations readOnly={interventionReadOnly} />
                  </>
                )}

                <Box sx={{ textAlign: 'center' }}>
                  {authService.canAccess('BOI') && (
                    <>
                      <Button
                        id="go_to_OSS"
                        type="button"
                        variant="outlined"
                        sx={{ m: 1 }}
                        onClick={() => {
                          goToOSS();
                        }}
                      >
                        OSS
                      </Button>
                      <Button
                        id="go_to_config_CR"
                        type="button"
                        variant="outlined"
                        sx={{ m: 1 }}
                        onClick={() => {
                          goToConfigCR();
                        }}
                      >
                        Config CR
                      </Button>
                    </>
                  )}

                  <Button
                    id="submitInterventionForm"
                    variant="contained"
                    sx={{ m: 1 }}
                    onClick={() => {
                      validateForm().then((errors: any) => {
                        const errorsList: string[] = Object.keys(errors);
                        if (errorsList.length) {
                          notif({
                            type: 'error',
                            content: (
                              <div>
                                <>{'Veuillez corriger les erreurs suivantes sur le formulaire :'}</>
                                <ul>
                                  {errorsList.map((error) => (
                                    <li key={error}>{error}</li>
                                  ))}
                                </ul>
                              </div>
                            )
                          });
                        } else handleSubmit();
                      });
                    }}
                  >
                    Enregistrer
                  </Button>
                  <Button
                    id="closeIntervention"
                    type="button"
                    variant="outlined"
                    sx={{ m: 1 }}
                    onClick={() => closeIntervention()}
                    disabled={interventionReadOnly}
                  >
                    Clore l'intervention
                  </Button>
                  {authService.canAccess('BOI') && (
                    <>
                      <Button
                        id="closeCase"
                        type="button"
                        variant="outlined"
                        sx={{ m: 1 }}
                        onClick={() => closeCase()}
                        disabled={feedback?.cr_case__status__label === 'Clos'}
                      >
                        Clore le dossier
                      </Button>

                      <Button
                        id="new_intervention"
                        type="button"
                        variant="outlined"
                        sx={{ m: 1 }}
                        onClick={() => createNewIntervention()}
                      >
                        Nouvelle intervention
                      </Button>
                    </>
                  )}
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}

      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
    </>
  );
}
