import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Grid, Box, CircularProgress, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { AppPaper, BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Select from 'react-select';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import MarkerClusterGroup from 'react-leaflet-cluster';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import { useGetAgents, useGetCompanies } from '../../hooks/datarefs';
import { Intervention } from './Types';

type Filters = {
  [key: string]: any;
};

const InterventionsMap = () => {
  const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  const madeAfterOptions = [
    { value: 1, label: 'sur les 30 derniers jours' },
    { value: 3, label: 'sur les 3 derniers mois' },
    { value: 6, label: 'sur les 6 derniers mois' },
    { value: 12, label: 'sur les 12 derniers mois' },
    { value: 24, label: 'sur les 24 derniers mois' },
    { value: null, label: 'depuis le lancement' }
  ];

  const { data: companies } = useGetCompanies();
  const { data: agents } = useGetAgents();
  const [interventions, setInterventions] = useState<Intervention[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { notif, notifOptions } = useNotif();

  const ALLTIME_START_DATE = '2017-01-01T00:00:00Z';

  const [filters, setFilters] = useState<Filters>({
    status: 3,
    madeAfter: 1,
    companies: [],
    agents: []
  });
  const filtersMap = {
    status: 'status',
    madeAfter: 'madeafter',
    companies: 'companyid',
    agents: 'agentid'
  };
  const backMapGenEndpoint = '/api/bconnect/webapp/back-map-gen';

  const search = () => {
    const filterString = Object.keys(filters)
      .map((filterName: string) => {
        let filterValue = filters[filterName];
        if (filterName === 'madeAfter') {
          filterValue = filterValue
            ? moment().subtract(filterValue, 'months').format('YYYY-MM-DD') + 'T00:00:00Z'
            : ALLTIME_START_DATE;
        }
        return `${filtersMap[filterName as keyof typeof filtersMap]}=${filterValue}`;
      })
      .join('&');

    axios
      .get(`${backMapGenEndpoint}?${filterString}`)
      .then((response: any) => {
        setInterventions(response.data);
      })
      .catch(() => {
        notif({
          severity: 'error',
          message: 'Erreur lors de la récupération des interventions'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!agents || !companies) {
    return null;
  }

  return (
    <>
      <AppPaper>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={2}>
            Interventions réalisées
          </Grid>
          <Grid item xs={4}>
            <Select
              className="select"
              options={madeAfterOptions}
              onChange={(madeAfter: any) => {
                setFilters((filters) => {
                  return { ...filters, madeAfter: madeAfter.value };
                });
              }}
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item xs={2}>
            Clients
          </Grid>
          <Grid item xs={4}>
            <Select
              className="select"
              isMulti
              options={companies.map((company) => {
                return { value: company.Id, label: company.Name };
              })}
              onChange={(companies: any) => {
                setFilters((filters) => {
                  return { ...filters, companies: companies.map((company: any) => company.value) };
                });
              }}
              menuPortalTarget={document.body}
            />
          </Grid>
          <Grid item xs={2} sx={{ mt: 1 }}>
            Agents
          </Grid>
          <Grid item xs={4} sx={{ mt: 1 }}>
            <Select
              id="chooseAgents"
              className="select"
              options={agents
                .filter((agent) => {
                  return filters.companies.length > 0
                    ? filters.companies.includes(agent.CompanyId)
                    : agent;
                })
                .map((agent) => {
                  return { value: agent.Id, label: agent.Name };
                })}
              isMulti
              onChange={(agents: any) => {
                setFilters((filters) => {
                  return { ...filters, agents: agents.map((agent: any) => agent.value) };
                });
              }}
              menuPortalTarget={document.body}
            />
          </Grid>
        </Grid>
        <Box textAlign={'right'}>
          <Button variant="contained" size="small" onClick={() => search()}>
            <SearchIcon />
            Rechercher
          </Button>
        </Box>
      </AppPaper>

      <Typography fontSize={20} sx={{ mt: 2 }}>
        {interventions.length} interventions
      </Typography>

      {!isLoading ? (
        <Grid container justifyContent="center" sx={{ mt: 2, zIndex: 0 }}>
          <MapContainer
            center={[45.764043, 4.835659]}
            zoom={13}
            maxZoom={18}
            style={{ height: '550px', zIndex: 1 }}
            scrollWheelZoom={false}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <MarkerClusterGroup chunkedLoading>
              {interventions.map(
                (intervention) =>
                  intervention.Latitude && (
                    <Marker
                      key={intervention.intervention_id}
                      position={[intervention.Latitude, intervention.Longitude]}
                    >
                      <Popup position={[intervention.Latitude, intervention.Longitude]}>
                        <Grid container justifyContent={'center'} spacing={2}>
                          <Grid item xs={12} fontWeight={'bold'}>
                            Intervention n°{intervention.intervention_id}
                          </Grid>
                          <Grid item xs={12} fontWeight={'bold'}>
                            Module n°{intervention.HrRadioId || 'inconnu'}
                          </Grid>
                          <Grid item xs={4}>
                            <PersonIcon />{' '}
                          </Grid>
                          <Grid item xs={8}>
                            {intervention.agentname || 'Agent supprimé'}
                          </Grid>
                          <Grid item xs={4}>
                            <BusinessIcon />
                          </Grid>
                          <Grid item xs={8}>
                            {intervention.regionname || 'Client supprimé'}
                          </Grid>
                          <Grid item xs={4}>
                            <DateRangeIcon />
                          </Grid>
                          <Grid item xs={8}>
                            {moment(intervention.MadeDate).format('DD/MM/YYYY')}
                          </Grid>
                          <Grid item xs={4}>
                            <LocationOnIcon />
                          </Grid>
                          <Grid item xs={8}>
                            {intervention.StreetNumber + ' ' + intervention.StreetName}
                            <br></br>
                            {intervention.ZipCode + ' ' + intervention.City}
                          </Grid>
                        </Grid>
                      </Popup>
                    </Marker>
                  )
              )}
            </MarkerClusterGroup>
          </MapContainer>
        </Grid>
      ) : (
        <Grid container justifyContent="center" sx={{ mt: 4 }}>
          <CircularProgress />
        </Grid>
      )}
      <BirdzNotif options={notifOptions} />
    </>
  );
};

export default InterventionsMap;
