import { ParamsConfigType } from './Types';
import * as Yup from 'yup';

const label = {
  name: 'label',
  label: 'Label',
  type: 'text',
  validationSchema: Yup.string().required('Champ obligatoire')
};

const code = {
  name: 'code',
  label: 'Code',
  type: 'text'
};

const orderField = {
  name: 'order',
  label: 'Ordre',
  type: 'number'
};

const driveInfos = [
  {
    name: 'region',
    label: 'Region',
    type: 'string',
    validationSchema: Yup.string().required('Champ obligatoire')
  },
  {
    name: 'drive_id',
    label: 'Id drive',
    type: 'string',
    validationSchema: Yup.string().required('Champ obligatoire')
  }
];

export const paramsConfig: ParamsConfigType[] = [
  {
    value: 'years',
    label: 'Années',
    endpoint: '/api/boi/params/years/',
    extraFields: [label]
  },
  {
    value: 'months',
    label: 'Mois',
    endpoint: '/api/boi/params/months/',
    extraFields: [label]
  },
  {
    value: 'regions',
    label: 'Régions',
    endpoint: '/api/boi/params/regions/',
    extraFields: [label]
  },
  {
    value: 'case-types',
    label: 'Types de dossiers',
    endpoint: '/api/boi/params/case-types/',
    extraFields: [label]
  },
  {
    value: 'case-statuses',
    label: 'Statuts de dossiers',
    endpoint: '/api/boi/params/case-statuses/',
    extraFields: [label, orderField]
  },
  {
    value: 'failure-codes',
    label: 'Codes panne',
    endpoint: '/api/boi/params/failure-codes/',
    extraFields: [label]
  },
  {
    value: 'antennas-types',
    label: "Types d'antennes",
    endpoint: '/api/boi/params/antennas-type/',
    extraFields: [label]
  },
  {
    value: 'case-events',
    label: 'Évènements dossiers',
    endpoint: '/api/boi/params/case-events/',
    extraFields: [label, code]
  },
  {
    value: 'subcontractor-types',
    label: 'Types sous-traitant',
    endpoint: '/api/boi/params/subcontractor-types/',
    extraFields: [label]
  },
  {
    value: 'intervention-statuses',
    label: "Statuts d'intervention",
    endpoint: '/api/boi/params/intervention-statuses/',
    extraFields: [label, orderField]
  },
  {
    value: 'intervention-events',
    label: "Évènements d'intervention",
    endpoint: '/api/boi/params/intervention-events/',
    extraFields: [label, code]
  },
  {
    value: 'sms-commands',
    label: 'Commandes SMS',
    endpoint: '/api/boi/params/sms-commands/',
    extraFields: [
      label,
      {
        name: 'command',
        label: 'Commande',
        type: 'text'
      }
    ]
  },
  {
    value: 'departments',
    label: 'Départements',
    endpoint: '/api/boi/params/departments/',
    extraFields: [label]
  },
  {
    value: 'cities',
    label: 'Communes',
    endpoint: '/api/boi/params/cities/',
    extraFields: [label]
  },
  {
    value: 'power-supply-type',
    label: "Types d'alimentation",
    endpoint: '/api/boi/params/power-supply-type/',
    extraFields: [label]
  },
  {
    value: 'drive',
    label: 'Drives',
    endpoint: '/api/boi/params/drives/',
    extraFields: driveInfos
  },
  {
    value: 'mr-intervention-type',
    label: "Types d'intervention MR",
    endpoint: '/api/boi/params/mr-intervention-type/',
    extraFields: [label, code]
  },
  {
    value: 'mr-devices-request',
    label: 'MR Devices request',
    endpoint: '/api/boi/params/mr-devices-request/',
    extraFields: [
      label,
      {
        name: 'clauseWhere',
        label: 'Clause where',
        type: 'string'
      },
      {
        name: 'type',
        label: 'Type',
        type: 'number'
      }
    ]
  }
];
