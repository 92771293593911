import React from 'react';
import { Grid } from '@mui/material';
import { Button, Table, TableBody, TableCell, TableHead, Tooltip } from '@mui/material';
import { StyledTableBodyRow, StyledTableHeaderRow } from '../../../styles/Table.styled';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/RemoveRedEye';
import moment from 'moment';
import { authService } from '../../../';

interface CrLinkedIntervention {
  case__number: string;
  case_id: number;
  created_at: number;
  id: number;
  intervention_status: number;
  intervention_status__name: string;
  updated_at: number;
}

type LinkedInterventionsProps = {
  interventions: CrLinkedIntervention[];
};
export default function LinkedInterventions({ interventions }: LinkedInterventionsProps) {
  return (
    <>
      <h3>Intervention(s) liée(s) : </h3>
      <Grid item sx={{ mt: 2 }}>
        <Table size="small">
          <TableHead>
            <StyledTableHeaderRow>
              <TableCell>Dossier</TableCell>
              <TableCell>Statut intervention</TableCell>
              <TableCell>Le</TableCell>
              <TableCell sx={{ width: 150, textAlign: 'center' }}>Visualiser</TableCell>
            </StyledTableHeaderRow>
          </TableHead>
          <TableBody>
            {interventions && interventions.length > 0 ? (
              <>
                {interventions.map((intervention) => (
                  <StyledTableBodyRow key={`linked-${intervention.id}`}>
                    <TableCell>{intervention.case__number}</TableCell>
                    <TableCell>{intervention.intervention_status__name}</TableCell>
                    <TableCell>
                      {moment.unix(intervention.created_at).format('DD/MM/YYYY à HH:mm')}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Voir intervention">
                        <Link
                          to={
                            authService.BOIorBOE(
                              `/boi/cr/interventions/edit/${intervention.id}`,
                              `/boe/cr/interventions/details/${intervention.id}`
                            ) as string
                          }
                        >
                          <Button id="go_to_intervention">
                            <VisibilityIcon fontSize="small" />
                          </Button>
                        </Link>
                      </Tooltip>
                    </TableCell>
                  </StyledTableBodyRow>
                ))}
              </>
            ) : (
              <StyledTableBodyRow>
                <TableCell colSpan={4}>Aucune intervention liée</TableCell>
              </StyledTableBodyRow>
            )}
          </TableBody>
        </Table>
      </Grid>
    </>
  );
}
