import { Box } from '@mui/material';
import Select from 'react-select';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Drive, SelectedDrive } from './DMSTypes';

type DMSDriveSelectionProps = {
  onSelectDrive: (drive: SelectedDrive) => void;
};

const DMSDriveSelection = ({ onSelectDrive }: DMSDriveSelectionProps) => {
  const [drives, setDrives] = useState<Drive[]>([]);
  const { driveId, driveName } = useParams<any>();
  const [selectedDrive, setSelectedDrive] = useState<SelectedDrive | undefined>();

  useEffect(() => {
    const fetchDrives = async () => {
      await axios.get('/api/boi/params/drives/?ordering=region&size=99999').then((response) => {
        setDrives(response.data.results);
      });
    };
    fetchDrives();
  }, []);

  useEffect(() => {
    if (driveId) {
      const selectedDrive = { value: driveId, label: driveName ?? '' };
      setSelectedDrive(selectedDrive);
      onSelectDrive(selectedDrive);
    }
  }, [driveId]);

  const handleSelectDrive = (driveOption: any) => {
    onSelectDrive(driveOption);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Select
        id="chooseDrive"
        options={
          drives &&
          drives?.map((drive) => {
            return { value: drive.drive_id, label: drive.region };
          })
        }
        onChange={handleSelectDrive}
        placeholder="Veuillez choisir un drive..."
        value={selectedDrive}
      />
    </Box>
  );
};
export default DMSDriveSelection;
