import React, { useEffect, useRef } from 'react';
import {
  AppPaper,
  ListField,
  ListPage,
  SearchField,
  BirdzTitle,
  useDialog,
  useNotif,
  BirdzNotif,
  BirdzDialog,
  SearchForm,
  SearchFormValue
} from '@applications-terrains/birdz-react-library';
import { authService } from '../..';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Grid, Tooltip, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/RemoveRedEye';
import axios from 'axios';
import { MonthlyStatement } from './Types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  formatToSelectOptions,
  useGetMonths,
  useGetSubcontractors,
  useGetYears
} from '../../hooks/datarefs';

export default function MonthlyStatementListing() {
  const endpoint = `${authService.BOIorBOE(
    `/api/boi/monthly-statements/monthly-statement/`,
    `/api/boe/monthly-statements/monthly-statement/`
  )}`;

  const { data: years } = useGetYears();
  const { data: months } = useGetMonths();
  const { data: subcontractors } = useGetSubcontractors();

  const searchRef: any = useRef(null);

  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const navigate = useNavigate();
  const [searchCriteria, setSearchCriteria] = React.useState<{ [criteriaName: string]: any }>({});
  const [filterString, setFilterString] = React.useState<string>('');
  const [results, setResults] = React.useState<MonthlyStatement[]>([]);
  const [searchFields, setSearchFields] = React.useState<SearchField[]>([]);
  const [listFields, setListFields] = React.useState<ListField[]>([]);

  const [searchFormValues, setSearchFormValues] = React.useState<SearchFormValue>();

  useEffect(() => {
    const searchFields: SearchField[] = [
      {
        name: 'year',
        label: 'Année',
        type: 'select',
        options: {
          values: years ? formatToSelectOptions(years) : []
        }
      },
      {
        name: 'month',
        label: 'Mois',
        type: 'select',
        options: {
          values: months ? formatToSelectOptions(months) : []
        }
      }
    ];

    if (authService.canAccessBOI()) {
      searchFields.splice(searchFields.length, 0, {
        name: 'subcontractor',
        label: 'Sous-traitant',
        options: {
          identifier: 'id',
          label: 'name',
          searchIsMulti: false,
          source: '/api/boi/subcontractors/subcontractors/'
        }
      });

      searchFields.splice(searchFields.length, 0, {
        name: 'status_monthly_statement',
        label: 'Etat',
        type: 'select',
        options: {
          values: [
            { value: 'draft', label: 'Brouillon' },
            { value: 'transmitted', label: 'Transmis' },
            { value: 'validated', label: 'Validé' }
          ]
        }
      });
    }

    if (years && months) {
      const currentYear = years.find((year: any) => year.label === moment().year().toString());
      const currentMonth = months.find((month: any) => month.id === +moment().format('MM'));

      const defaultFilter = {
        formValue: {
          month: {
            value: currentMonth.id,
            label: currentMonth.label
          },
          year: {
            value: currentYear.id,
            label: currentYear.label
          }
        },
        filterValues: {
          month: currentMonth.id,
          year: currentYear.id
        }
      };
      setSearchFormValues(defaultFilter);
    }

    setSearchFields(searchFields);
  }, [years, months]);

  useEffect(() => {
    if (searchFormValues) {
      setTimeout(() => {
        searchRef.current?.launchSearch();
      });
    }
  }, [searchFormValues]);

  useEffect(() => {
    const listFields: ListField[] = [
      {
        name: 'created_at',
        label: 'Date création',
        transform: (value: any) => {
          return (value && moment.unix(value).format('DD/MM/YYYY')) || '';
        }
      },
      {
        name: 'year__name',
        label: 'Année'
      },
      {
        name: 'month__name',
        label: 'Mois'
      },
      {
        name: 'prices_interventions',
        label: 'Montant prévisionnel BC',
        transform: (value: any) => {
          return (
            <>
              <p>{value ?? '-'}</p>
            </>
          );
        }
      },
      {
        name: 'transmission_date',
        label: 'Transmis',
        transform: (value: any) => {
          return value ? moment(value).format('DD/MM/YYYY') : <CloseIcon fontSize="small" />;
        }
      },
      {
        name: 'validation_date',
        label: 'Validé',
        transform: (value: any) => {
          return value ? (
            moment(value).format('DD/MM/YYYY à HH:mm')
          ) : (
            <CloseIcon fontSize="small" />
          );
        }
      },
      {
        name: 'actions',
        label: 'Actions',
        className: 'text-center',
        transform: (value: any, row: any) => {
          return row.validation_date && authService.canAccessBOI() ? (
            <>
              <Link
                to={`${authService.BOIorBOE(
                  `/boi/monthly-statement/view/${row.id}`,
                  `/boi/monthly-statement/view/${row.id}`
                )}`}
              >
                <IconButton aria-label="Intervention détail">
                  <VisibilityIcon id="visibilityIcon" />
                </IconButton>
              </Link>
            </>
          ) : row.transmission_date && authService.canAccessBOE() ? (
            <>
              <Link to={`/boe/monthly-statement/view/${row.id}`}>
                <IconButton aria-label="Intervention détail">
                  <VisibilityIcon id="visibilityIcon" />
                </IconButton>
              </Link>
            </>
          ) : (
            <>
              <IconButton
                component={Link}
                to={`${authService.BOIorBOE(
                  `/boi/monthly-statement/edit/${row.id}`,
                  `/boe/monthly-statement/edit/${row.id}`
                )}`}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => {
                  confirmDialog({
                    title: 'Supprimer le relevé mensuel',
                    content: 'Êtes-vous sûr de vouloir supprimer ce relevé mensuel?',
                    onValidate: () => {
                      axios
                        .delete(`${endpoint}${row.id}/`)
                        .then(() => {
                          notif({
                            content: 'Le relevé mensuel a été supprimé avec succès',
                            type: 'success'
                          });
                          setTimeout(() => {
                            navigate(0);
                          }, 2000);
                          closeDialog();
                        })
                        .catch(() => {
                          notif({
                            content: 'Une erreur est survenue lors de la suppression',
                            type: 'error'
                          });
                          closeDialog();
                        });
                    },
                    onCancel: () => {
                      closeDialog();
                    }
                  });
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </>
          );
        }
      }
    ];

    if (authService.canAccessBOI()) {
      listFields.unshift({
        name: 'subcontractor',
        label: 'Sous-traitant',
        transform: (subcontractorId) => {
          if (!subcontractors) return '';
          const subcontractor = subcontractors.find(
            (subcontractor) => subcontractor.id === subcontractorId
          );
          return subcontractor?.name || '';
        }
      });
    }

    setListFields(listFields);
  }, [subcontractors]);

  const createMonthlyStatementButtonIsDisabled = () => {
    return !searchCriteria || !searchCriteria.year || !searchCriteria.month || results.length !== 0;
  };
  return (
    <AppPaper>
      <div>
        <Grid justifyContent="space-between" container>
          <Grid item>
            <BirdzTitle>Relevés mensuels</BirdzTitle>
          </Grid>
          {authService.canAccessBOE() && (
            <Grid item>
              <Tooltip
                title={
                  createMonthlyStatementButtonIsDisabled() &&
                  "Vous devez choisir une période (mois/année) sur laquelle aucun relevé n'a été édité pour pouvoir ajouter un relevé."
                }
              >
                <span>
                  <Button
                    variant="contained"
                    sx={{ mt: 3 }}
                    disabled={createMonthlyStatementButtonIsDisabled()}
                    component={Link}
                    to={`add/${searchCriteria.year}/${searchCriteria.month}`}
                  >
                    <AddCircleIcon sx={{ mr: 1 }} /> Créer un relevé
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>

        <Paper variant="outlined" square sx={{ p: 2, mb: 1 }}>
          <SearchForm
            ref={searchRef}
            fields={searchFields}
            onSubmit={(filterString: string, searchCriteria: any) => {
              setSearchCriteria(searchCriteria.filterValues);
              setFilterString(filterString);
            }}
            filtersChange={() => {
              setSearchCriteria({});
            }}
            values={searchFormValues}
          />
        </Paper>
        <ListPage
          endpoint={`${endpoint}`}
          filters={filterString}
          fields={listFields}
          paginationOptions={{
            pageSize: 25
          }}
          defaultOrder={['-updated_at']}
          onLoadItems={(items: any) => {
            setResults(items);
          }}
          emptyMessage={
            filterString && authService.canAccessBOE()
              ? "Il n'y a pas de relevé disponible pour la période choisie, vous pouvez en créer un en cliquant sur le bouton en haut à droite"
              : undefined
          }
        />

        <BirdzDialog options={dialogOptions} />
      </div>
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
}
