import React from 'react';
import { Box } from '@mui/material';
import {
  BirdzNotif,
  Login as LoginForm,
  useNotif
} from '@applications-terrains/birdz-react-library';
import { authService } from '../..';
import axios from 'axios';

const Login = () => {
  //eslint-disable-next-line
  const logoUrl = process.env.PUBLIC_URL + '/logo-birdz-login.png';
  const { notif, notifOptions } = useNotif();

  return (
    <Box className="loginForm">
      <LoginForm
        onSubmit={(credentials: any) => {
          return new Promise((resolve, reject) => {
            authService.login(credentials.email, credentials.password).then(
              () => {
                resolve();
                // On est obligé de faire un refresh de la page pour que le contexte soit bien mis à jour (au niveau du ThemeProvider dans <App />)
                window.location.href = '/';
              },
              (error: any) => {
                reject(typeof error === 'string' ? error : 'Une erreur serveur est survenue ');
              }
            );
          });
        }}
        onSSOButtonClick={() => {
          authService.login_sso();
        }}
        onResetPassword={(email: string) => {
          return new Promise((resolve, reject) => {
            axios
              .post('/api/auth/request-password-reset/', {
                email
              })
              .then(() => {
                resolve();
                notif({
                  content:
                    "Un e-mail de réinitialisation de mot de passe vous a été envoyé à l'adresse e-mail indiquée, si toutefois elle existe.",
                  type: 'success'
                });
              })
              .catch(() => {
                reject();
                notif({
                  content: 'Une erreur est survenue lors de la réinitialisation du mot de passe.',
                  type: 'error'
                });
              });
          });
        }}
        options={{
          logo: <img src={logoUrl} alt="B-Licence" style={{ width: '100%', marginTop: '35px' }} />,
          //eslint-disable-next-line
          backgroundUrl: process.env.PUBLIC_URL + '/murmuration-blue.png'
        }}
      />
      <BirdzNotif options={notifOptions} />
    </Box>
  );
};

export default Login;
