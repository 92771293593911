import React from 'react';

import CheckIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import HideSourceIcon from '@mui/icons-material/HideSource';

export type StatusesProps = {
  className?: string;
  statusLabel:
    | 'IN_PROGRESS'
    | 'FILE_ERROR'
    | 'TO_DELIVER'
    | 'DELIVERY_ERROR'
    | 'DELIVERED_SUCCESSFULLY';
};

const DisplayStatus = ({ statusLabel, className = '' }: StatusesProps) => {
  const icons = {
    success: <CheckIcon className="mr-50 success" />,
    error: <ErrorIcon className="mr-50 error" />,
    pending: <PendingIcon className="mr-50 pending" />,
    default: <HideSourceIcon className="mr-50" />
  };
  const getElements = (status: StatusesProps['statusLabel']) => {
    switch (status) {
      case 'IN_PROGRESS':
        return { label: 'En cours', icon: 'pending' };
      case 'FILE_ERROR':
        return { label: 'Erreur fichier', icon: 'error' };
      case 'TO_DELIVER':
        return { label: 'A livrer', icon: 'pending' };
      case 'DELIVERY_ERROR':
        return { label: 'Erreur livraison', icon: 'error' };
      case 'DELIVERED_SUCCESSFULLY':
        return { label: 'Livré avec succès', icon: 'success' };
      default:
        return { label: '-', icon: 'default' };
    }
  };

  const { label, icon } = getElements(statusLabel);

  return (
    <span className={`d-flex align-items-center status ${className}`}>
      {icons[icon as keyof typeof icons]}
      {label}
    </span>
  );
};

export default DisplayStatus;
