import React, { useEffect } from 'react';
import { Box, Button, Paper, Tab, Tabs, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { indexOf } from 'lodash';

const HourInput = ({ value, onChange }: { value: string; onChange: (value: any) => void }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <span>Heure</span>
      <TextField
        type="time"
        size="small"
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          step: 60
        }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        sx={{ ml: 2 }}
        value={value}
      />
    </Box>
  );
};

const TABS = [
  { key: 'WEEKLY', label: 'Semaine' },
  { key: 'MONTHLY', label: 'Mois' },
  { key: 'CRON', label: 'Cron' }
];
const WEEK_DAYS = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi'];
const WEEKEND_DAYS = ['Samedi', 'Dimanche'];
const DAYS = [...WEEK_DAYS, ...WEEKEND_DAYS];
const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
];
const DAYS_NUMBER = Array.from(Array(31).keys());

export const Cron = () => {
  // Get formik fields
  const [programmingTypeField] = useField('programming_type');
  const [cronField] = useField('cron');

  const [tabIndex, setTabIndex] = React.useState<number>(
    Math.max(
      TABS.findIndex((tab) => tab.key === programmingTypeField?.value),
      0
    )
  );

  // Week and month states
  const [hour, setHour] = React.useState<string>('');

  // Week states
  const [daysWeekButtons, setDaysWeekButtons] = React.useState<any[]>([]);

  // Month states
  const [monthsButtons, setMonthsButtons] = React.useState<any[]>([]);
  const [daysMonthsButtons, setDaysMonthsButtons] = React.useState<any[]>([]);

  const [cron, setCron] = React.useState<string>('');

  const { setFieldValue, setFieldError, setTouched, values, errors, touched, isSubmitting } =
    useFormikContext<any>();

  const handleChangeTab = (event: any, newValueTab: number) => {
    setDaysWeekButtons([]);
    setMonthsButtons([]);
    setDaysMonthsButtons([]);
    setCron('');
    setFieldValue('cron', '');
    setFieldValue('programming_type', TABS[newValueTab]?.key);
    setTabIndex(newValueTab);
  };

  useEffect(() => {
    setCron(cronField.value);
  }, [cronField.value]);

  useEffect(() => {
    if (!values) return;

    // Set Tab
    const programmingType = values?.programming_type || '';

    const foundTabIndex = TABS.findIndex((tab) => tab.key === programmingType);
    if (foundTabIndex !== -1 && tabIndex !== foundTabIndex) {
      setTabIndex(foundTabIndex);
    }

    if (values.cron) {
      const { minutes, hours, daysMonth, months, daysWeek } = decodeCron(values.cron);
      const newHour = `${hours}:${minutes}`;
      if (programmingType === 'CRON') {
        setCron(values.cron);
      } else {
        if (minutes && hours && newHour !== values.hour) {
          setHour(newHour);
        }

        if (daysWeek) {
          if (daysWeek[0] === '*') {
            setDaysWeekButtons(DAYS);
          } else {
            setDaysWeekButtons(daysWeek.map((day: string) => DAYS[parseInt(day) - 1]));
          }
        }
        if (daysMonth) {
          if (daysMonth[0] === '*') {
            setDaysMonthsButtons(DAYS_NUMBER);
          } else {
            setDaysMonthsButtons(daysMonth.map((day: string) => parseInt(day) - 1));
          }
        }
        if (months) {
          if (months[0] === '*') {
            setMonthsButtons(MONTHS);
          } else {
            setMonthsButtons(months.map((month: string) => MONTHS[parseInt(month) - 1]));
          }
        }
      }
    }
  }, [values]);

  const getCronHours = () => {
    const [splitHour, splitMinute] = (hour || '').split(':');
    return `${splitMinute || '*'} ${splitHour || '*'}`;
  };

  const getCronDays = () => {
    if (!daysWeekButtons.length) {
      return;
    }
    if (daysWeekButtons.length === DAYS.length) {
      return '*';
    } else {
      return daysWeekButtons.map((day) => indexOf(DAYS, day) + 1).join(',');
    }
  };

  const getCronMonths = () => {
    if (!monthsButtons.length) {
      return;
    }
    if (monthsButtons.length === MONTHS.length) {
      return '*';
    } else {
      return monthsButtons.map((month) => indexOf(MONTHS, month) + 1).join(',');
    }
  };

  const getCronDaysNumber = () => {
    if (!daysMonthsButtons.length) {
      return;
    }
    if (daysMonthsButtons.length === DAYS_NUMBER.length) {
      return '*';
    } else {
      return daysMonthsButtons.map((day) => day + 1).join(',');
    }
  };

  const decodeCron = (cron: string) => {
    const [minutes, hours, daysMonth, months, daysWeek] = cron.split(' ');

    return {
      minutes,
      hours,
      daysMonth: daysMonth.split(','),
      months: months.split(','),
      daysWeek: daysWeek.split(',')
    };
  };

  useEffect(() => {
    if ('Semaine' === TABS[tabIndex]?.label) {
      const cronDays = getCronDays();
      const cronHours = getCronHours();
      if (!cronDays || !cronHours) {
        updateCronValue('');
        return;
      }
      //ex cron results:   minute hour * * days
      updateCronValue(`${cronHours} * * ${cronDays}`);
    }
  }, [daysWeekButtons, hour]);

  useEffect(() => {
    if ('Mois' === TABS[tabIndex]?.label) {
      const cronDaysNumber = getCronDaysNumber();
      const cronHours = getCronHours();
      const cronMonths = getCronMonths();
      if (!cronDaysNumber || !cronHours || !cronMonths) {
        updateCronValue('');
        return;
      }
      //ex cron results:   minute hour day(month) month *
      updateCronValue(`${cronHours} ${cronDaysNumber} ${cronMonths} *`);
    }
  }, [monthsButtons, daysMonthsButtons, hour]);

  const updateCronValue = (newCronValue: string) => {
    setTouched({ cron: true });
    if (newCronValue === '') {
      setTimeout(() => {
        setFieldError('cron', 'Champs invalides');
      });
    } else {
      setFieldError('cron', undefined);
    }
    if (values.cron !== newCronValue) {
      setFieldValue('cron', newCronValue);
    }
    if (values.programming_type !== TABS[tabIndex]?.key) {
      setFieldValue('programming_type', TABS[tabIndex]?.key);
    }
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Tabs value={tabIndex} onChange={handleChangeTab} sx={{ mb: 1 }}>
        {TABS.map((tab) => (
          <Tab key={tab.key} label={tab.label} />
        ))}
      </Tabs>

      <Paper
        variant="outlined"
        square
        sx={[
          { p: 2 },
          errors && errors?.cron && (touched?.cron || isSubmitting)
            ? { border: '1px solid #d32f2f' }
            : {}
        ]}
      >
        {'Semaine' === TABS[tabIndex]?.label && (
          <Box>
            <h3>Jour de la semaine</h3>
            <Box>
              {DAYS.map((day, index) => (
                <Button
                  key={index}
                  sx={{ m: 1 }}
                  size="small"
                  variant={daysWeekButtons.includes(day) ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={() => {
                    if (daysWeekButtons.includes(day)) {
                      setDaysWeekButtons(daysWeekButtons.filter((item) => item !== day));
                    } else {
                      setDaysWeekButtons([...daysWeekButtons, day]);
                    }
                  }}
                >
                  {day}
                </Button>
              ))}
              <Box>
                <Button
                  sx={{ m: 1 }}
                  onClick={() => {
                    setDaysWeekButtons(DAYS);
                  }}
                >
                  Tous les jours de la semaine
                </Button>
                <Button
                  sx={{ m: 1 }}
                  onClick={() => {
                    setDaysWeekButtons(WEEK_DAYS);
                  }}
                >
                  En semaine
                </Button>
                <Button
                  sx={{ m: 1 }}
                  onClick={() => {
                    setDaysWeekButtons(WEEKEND_DAYS);
                  }}
                >
                  Weekend
                </Button>
                <Button
                  sx={{ m: 1 }}
                  onClick={() => {
                    setDaysWeekButtons([]);
                  }}
                >
                  Aucun jour
                </Button>
              </Box>

              <HourInput value={hour} onChange={(value) => setHour(value)} />
            </Box>
          </Box>
        )}
        {'Mois' === TABS[tabIndex]?.label && (
          <Box>
            <h3>Mois</h3>
            <Box>
              {MONTHS.map((month, index) => (
                <Button
                  key={index}
                  sx={{ m: 1 }}
                  size="small"
                  variant={monthsButtons.includes(month) ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={() => {
                    if (monthsButtons.includes(month)) {
                      setMonthsButtons(monthsButtons.filter((item) => item !== month));
                    } else {
                      setMonthsButtons([...monthsButtons, month]);
                    }
                  }}
                >
                  {month}
                </Button>
              ))}
              <Box>
                <Button
                  sx={{ m: 1 }}
                  onClick={() => {
                    setMonthsButtons(MONTHS);
                  }}
                >
                  Tous les mois
                </Button>
                <Button
                  sx={{ m: 1 }}
                  onClick={() => {
                    setMonthsButtons([]);
                  }}
                >
                  Aucun mois
                </Button>
              </Box>
            </Box>

            <h3>Jour</h3>
            {DAYS_NUMBER.map((day, index) => (
              <Button
                key={index}
                sx={{ m: 1, width: '40px', height: '40px', minWidth: 'auto' }}
                size="small"
                variant={daysMonthsButtons.includes(day) ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => {
                  if (daysMonthsButtons.includes(day)) {
                    setDaysMonthsButtons(daysMonthsButtons.filter((item) => item !== day));
                  } else {
                    setDaysMonthsButtons([...daysMonthsButtons, day]);
                  }
                }}
              >
                {day + 1}
              </Button>
            ))}
            <Box>
              <Button
                sx={{ m: 1 }}
                onClick={() => {
                  setDaysMonthsButtons(DAYS_NUMBER);
                }}
              >
                Tous les jours
              </Button>
              <Button
                sx={{ m: 1 }}
                onClick={() => {
                  setDaysMonthsButtons([]);
                }}
              >
                Aucun jour
              </Button>
            </Box>

            <HourInput value={hour} onChange={(value) => setHour(value)} />
          </Box>
        )}

        {'Cron' === TABS[tabIndex]?.label && (
          <Box>
            <h3>Cron</h3>
            <TextField
              name="cron"
              size="small"
              fullWidth
              required
              onChange={(e) => {
                setFieldValue('cron', e.target.value);
              }}
              value={cron}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};
