import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Grid,
  LinearProgress,
  Box,
  ImageList,
  ImageListItem,
  Modal
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import {
  BirdzTitle,
  AppPaper,
  useDialog,
  BirdzDialog
} from '@applications-terrains/birdz-react-library';
import { useNotif } from '@applications-terrains/birdz-react-library';
import { BirdzNotif } from '@applications-terrains/birdz-react-library';
import { authService } from '../..';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type Details = {
  content?: string;
  created_at?: number;
  created_by?: number;
  created_by__name?: string;
  id?: number;
  is_read?: boolean;
  is_visible?: boolean;
  mark_read?: MarkRead[] | null;
  media?: Media[];
  picture?: boolean;
  processed?: string;
  subcontractor?: number;
  updated_at?: number;
};

type MarkRead = {
  created_at: number;
  created_by: number;
  created_by__name: string;
  id: number;
  observation: number;
  updated_at: number;
};

export type Media = {
  original_signed_url: string;
  uuid: string;
  thumbnail_signed_url: string;
  original_mime_type: string;
  id: number;
};

export default function InformationDetails() {
  const navigate = useNavigate();
  const { id } = useParams<any>();

  const [details, setDetails] = useState<Details>();
  const [isLoading, setLoading] = useState<boolean>();
  const [modalMedia, setModalMedia] = useState<Media | null>(null);
  const { notif, notifOptions } = useNotif();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();

  const handleChangeVisibility = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDetails({ ...details, ...{ is_visible: event.target.value === 'true' } });
  };

  const updateObservation = async () => {
    const payload = {
      content: details?.content || '',
      is_visible: details?.is_visible,
      updated_at: details?.updated_at
    };
    await axios
      .put(`/api/boe/extras/observations/${id}/`, payload)
      .then(() => {
        navigate('/boe/informations/');
      })
      .catch((error: any) => {
        notif({
          content: `Une erreur est survenue lors de la modification (${error.response.status} ${error.response.statusText})`,
          type: 'error'
        });
      });
  };

  const deleteObservation = () => {
    confirmDialog({
      title: "Supprimer l'observation",
      content: 'Êtes-vous sûr de vouloir supprimer cette observation ?',
      onValidate: () => {
        axios.delete(`/api/boe/extras/observations/${id}/`).then(() => {
          closeDialog();
          navigate('/boe/informations/');
        });
      },
      onCancel: () => {
        closeDialog();
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await axios
        .get(
          authService.getEndpoint(
            `/api/boi/extras/observations/${id}/`,
            `/api/boe/extras/observations/${id}/`
          )
        )
        .then((response: any) => {
          const data = response.data as Details;
          setDetails(data);
        })
        .catch(() => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchData();
  }, [id]);

  const markObservationAsRead = () => {
    axios
      .post(`/api/boi/extras/unprocessed-observations/${id}/mark-read/`)
      .then((response: any) => {
        const newDetails = { ...details };
        newDetails?.mark_read?.push(response.data);
        setDetails(newDetails);

        notif({
          content: 'Observation marquée comme lue avec succès',
          type: 'success'
        });
      })
      .catch((error: any) => {
        notif({
          content: `${error.response.status} ${error.response.statusText} (${error.response.data})`,
          type: 'error'
        });
      });
  };

  const processObservation = () => {
    axios
      .post(`/api/boi/extras/unprocessed-observations/${id}/process/`)
      .then(() => {
        const date = new Date();
        const newDetails = { ...details };
        newDetails.processed = date.toString();
        setDetails(newDetails);

        notif({
          content: 'Observation marquée comme lue avec succès',
          type: 'success'
        });
      })
      .catch((error: any) => {
        notif({
          content: `${error.response.status} ${error.response.statusText} (${error.response.data})`,
          type: 'error'
        });
      });
  };

  return isLoading ? (
    <LinearProgress />
  ) : details ? (
    <>
      <AppPaper>
        <BirdzTitle>Détails</BirdzTitle>

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <strong>Informations</strong>
          </Grid>
          <Grid item xs={9}>
            {authService.canAccessBOE() ? (
              <Box component="form" noValidate autoComplete="off">
                <div>
                  <TextField
                    id="outlined-multiline-static"
                    label=""
                    multiline
                    fullWidth
                    rows={4}
                    defaultValue={details?.content || ''}
                    onChange={(item: any) => {
                      setDetails({
                        ...details,
                        ...{ content: item.target.value }
                      });
                    }}
                  />
                </div>
              </Box>
            ) : (
              <>{details?.content ? details?.content : 'aucune information disponible'}</>
            )}
          </Grid>
          <Grid item xs={3}>
            <strong>Média</strong>
          </Grid>
          <Grid item xs={9}>
            {details.media?.length === 0 ? (
              <i>Aucun média disponible</i>
            ) : (
              <>
                <ImageList cols={5} rowHeight={200}>
                  <>
                    {details.media?.map((media: Media) => {
                      return (
                        <ImageListItem
                          sx={{ cursor: 'pointer' }}
                          key={media.uuid}
                          onClick={() => setModalMedia(media)}
                        >
                          {media.thumbnail_signed_url ? (
                            <img src={media.thumbnail_signed_url} alt="" />
                          ) : (
                            <Box>Aucun aperçu disponible</Box>
                          )}
                        </ImageListItem>
                      );
                    })}
                  </>
                </ImageList>
                <Modal
                  open={modalMedia !== null}
                  onClose={() => setModalMedia(null)}
                  sx={{ width: 'auto' }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      height: '80%',
                      transform: 'translate(-50%, -50%)'
                    }}
                  >
                    {modalMedia !== null && (
                      <img
                        src={modalMedia?.original_signed_url}
                        alt=""
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                      />
                    )}
                  </Box>
                </Modal>
              </>
            )}
          </Grid>

          {authService.canAccessBOE() && authService.canAccess('INFO_CMP_CMP') && (
            <>
              <Grid item xs={3}>
                <Box sx={{ mt: 3 }}>
                  <strong>Visible par Birdz</strong>
                </Box>
              </Grid>

              <Grid item xs={9}>
                <Radio
                  id="isVisibleByBirdz"
                  checked={details?.is_visible === true}
                  onChange={handleChangeVisibility}
                  value="true"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'true' }}
                />
                oui
                <Radio
                  id="isNotVisibleByBirdz"
                  checked={details?.is_visible === false}
                  onChange={handleChangeVisibility}
                  value="false"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'false' }}
                />
                non
              </Grid>
            </>
          )}

          <Grid container item>
            <Grid item xs={12}>
              <Box sx={{ mt: 1 }}>
                <strong>Saisi par : </strong>
                {details.created_by__name ? details.created_by__name : 'auteur inconnu'} le{' '}
                {details.created_at
                  ? moment.unix(details.created_at).format('DD/MM/YYYY à HH:mm')
                  : 'date inconnue'}
              </Box>
              {authService.canAccess('INFO_CMP_LU') && (
                <Box>
                  <strong>Lu par : </strong>
                  {details.mark_read && details.mark_read?.length !== 0
                    ? details.mark_read[0].created_by__name +
                      ' le ' +
                      moment.unix(details.mark_read[0].created_at).format('DD/MM/YYYY à HH:mm')
                    : '-'}
                </Box>
              )}
              <Box>
                <strong>Traité le : </strong>
                {details.processed && details.processed !== null
                  ? moment(details.processed).format('DD/MM/YYYY à HH:mm')
                  : '-'}
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {authService.canAccessBOI() && (
          <Box sx={{ textAlign: 'center' }}>
            <Link to="/boi/informations">
              <Button id="goBack" sx={{ marginRight: 2 }} variant="outlined">
                <ArrowBackIcon fontSize="small" sx={{ mr: 1 }} /> Retour
              </Button>
            </Link>

            {authService.canAccess('INFO_CMP_LU') && (
              <Button
                variant="contained"
                onClick={() => markObservationAsRead()}
                disabled={details.mark_read?.length !== 0}
              >
                <MarkEmailReadIcon sx={{ mr: 1 }} /> Marquer comme lu
              </Button>
            )}

            <Button
              id="processed"
              variant="outlined"
              sx={{ ml: 2 }}
              onClick={() => processObservation()}
              disabled={details.processed !== null}
            >
              <DoneIcon sx={{ mr: 1 }} />
              Traiter
            </Button>
          </Box>
        )}
        {authService.canAccessBOE() && (
          <Box sx={{ textAlign: 'center' }}>
            <Button
              id="deleteObs"
              variant="outlined"
              onClick={() => deleteObservation()}
              sx={{ mr: 2 }}
            >
              Supprimer
            </Button>

            <Button id="submitObs" variant="contained" onClick={() => updateObservation()}>
              Enregistrer
            </Button>
          </Box>
        )}
      </AppPaper>
      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
    </>
  ) : (
    <>
      <Alert severity="info" sx={{ mb: 2 }}>
        Aucune information disponible
      </Alert>
      <Grid container justifyContent="flex-end">
        <Button variant="contained" onClick={() => navigate('/boe/informations')}>
          Retour
        </Button>
      </Grid>
    </>
  );
}
