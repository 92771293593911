import axios from 'axios';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import DMSFile from './DMSFile';
import DMSFolder from './DMSFolder';
import { File, Folder, AssociatedDrive, ModalAction } from './DMSTypes';
import { BirdzTitle, AppPaper } from '@applications-terrains/birdz-react-library';
import { Alert, LinearProgress, Stack } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { authService } from '../..';
import { useNavigate } from 'react-router-dom';
import DMSModal from './DMSModal/DMSModal';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import DMSDriveSelection from './DMSDriveSelection';
import { useGetUsers } from '../../hooks/datarefs';

type DMSManagerProps = {
  title?: string;
  readOnly?: boolean;
  onSelectDrive?: (driveProps: AssociatedDrive) => void;
  listOnlyFolders?: boolean;
};

type CheckedFiles = {
  id: string;
  name: string;
};

/*
COMPONENT DESCRIPTION
This component manages the features of the DMS in the BIM / B-TECH project.

When loaded, it displays the files and the folders at the root of the drive used for the DMS.
It keeps in state an array of the ids of the files and folders that are selected (checked).
This allows the user to manage the permissions of multiple elements at once.

*/

const DMSManager = ({
  title = 'Gestion électronique des documents',
  readOnly = false,
  onSelectDrive,
  listOnlyFolders = false
}: DMSManagerProps) => {
  const [checkedFiles, setCheckedFiles] = useState<CheckedFiles[]>([]); //Files and folders that are checked (We use the linux convention that says folders are files)
  const [folders, setFolders] = useState<Folder[]>([]); //Files and folders at the root of the drive
  const [files, setFiles] = useState<File[]>([]);
  const [modalAction, setModalAction] = useState<ModalAction>('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDriveId, setSelectedDriveId] = useState<string>('');
  const navigate = useNavigate();

  const { data } = useGetUsers();
  const users = data || [];

  const checkFile = (id: string, name: string, check: boolean) => {
    //Maintain checkedFiles state using function passed as prop to each element.
    check
      ? setCheckedFiles((current: any) => [...current, { id: id, name: name }])
      : setCheckedFiles((current: any) => _.reject(current, { id: id }));
  };

  const fetchItems = () => {
    if (selectedDriveId && authService.canAccessBOI()) {
      setIsLoading(true);
      axios.get(`/api/boi/dms/list-files/?folder_id=${selectedDriveId}`).then((response: any) => {
        const data = response.data;
        setFolders(data.folders);
        setFiles(data.files);
        setIsLoading(false);
      });
    } else if (authService.isViewerGED()) {
      setIsLoading(true);
      axios.get('/api/boe/dms/list-allowed-folders/').then((response) => {
        setFolders(response.data.folders);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    fetchItems();
  }, [selectedDriveId]);

  const isReady = () => {
    return authService.canAccessBOE() || selectedDriveId;
  };

  return (
    <AppPaper>
      <BirdzTitle>{title}</BirdzTitle>

      {authService.canAccessBOI() && (
        <DMSDriveSelection
          onSelectDrive={(drive) => {
            setSelectedDriveId(drive?.value);
            if (typeof onSelectDrive === 'undefined') {
              navigate(`/boi/DMS/${drive.label}/${drive.value}`, { replace: false });
            }
          }}
        />
      )}

      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          {isReady() && (
            <>
              {folders &&
                folders.map((folder: Folder) => {
                  return (
                    <DMSFolder
                      key={folder.id}
                      id={folder.id}
                      name={folder?.name || 'no name'}
                      created_by={folder.created_by}
                      created_at={folder.created_at}
                      last_user_modified={folder.last_user_modified}
                      mimeType={folder.mimeType}
                      users={users}
                      checkedItems={checkedFiles}
                      checkFile={checkFile}
                      readOnly={readOnly}
                      onSelectDrive={
                        typeof onSelectDrive === 'function'
                          ? (driveProps: AssociatedDrive) => onSelectDrive(driveProps)
                          : undefined
                      }
                      listOnlyFolders={listOnlyFolders}
                    />
                  );
                })}

              {(!folders || folders.length === 0) && (
                <Alert severity="info" sx={{ mt: 1 }}>
                  Aucun dossier
                </Alert>
              )}

              {listOnlyFolders === false && (
                <>
                  {files && files.length > 0 && (
                    <Stack>
                      {files.map((file: File) => {
                        return (
                          <DMSFile
                            key={file.id}
                            id={file.id}
                            name={file.name}
                            created_by={file.created_by}
                            created_at={file.created_at}
                            last_user_modified={file.last_user_modified}
                            mimeType={file.mimeType}
                            size={file.size}
                            users={users}
                            checkFile={checkFile}
                            readOnly={readOnly}
                          />
                        );
                      })}
                    </Stack>
                  )}
                </>
              )}

              {!readOnly && (
                <BottomNavigation
                  showLabels
                  sx={{
                    backgroundColor: 'lightgrey',
                    position: 'sticky',
                    bottom: 0,
                    width: '100'
                  }}
                >
                  {checkedFiles.length && (
                    <BottomNavigationAction
                      id="updateRights"
                      label="Modifier les droits des éléments cochés"
                      icon={<AccountCircleIcon sx={{ mr: 1 }} />}
                      onClick={() => {
                        setModalAction('multiplePermissions');
                      }}
                      disabled={checkedFiles.length === 0}
                    />
                  )}
                  <BottomNavigationAction
                    id="addFile"
                    label="Ajouter un fichier"
                    icon={<UploadFileIcon sx={{ mr: 1 }} />}
                    onClick={() => {
                      setModalAction('uploadFile');
                    }}
                  />
                  <BottomNavigationAction
                    id="createFolder"
                    label="Ajouter un dossier"
                    icon={<CreateNewFolderIcon sx={{ mr: 1 }} />}
                    onClick={() => setModalAction('createFolder')}
                  />
                </BottomNavigation>
              )}
            </>
          )}
        </>
      )}

      {!readOnly && (
        <DMSModal
          action={modalAction}
          onClose={() => {
            setModalAction('');
            setCheckedFiles([]);
            fetchItems();
          }}
          item={{
            id: selectedDriveId,
            type: 'folder',
            checkedItems: checkedFiles,
            userList: users
          }}
        />
      )}
    </AppPaper>
  );
};
export default DMSManager;
