import { CrInterventionEvent } from '../Cases/Types';

export class InterventionEventsHelper {
  static OSS_EVENT_CODE = 'INTER_OSS';
  static CRSEND_EVENT_CODE = 'INTER_CRSEND';

  static hasEvent(events: CrInterventionEvent[], eventCode: string): boolean {
    if (events?.length > 0) {
      return !!events.find((event) => event.step__code === eventCode);
    }
    return false;
  }

  static hasScheduled(events: CrInterventionEvent[] | undefined = []): boolean {
    return this.hasEvent(events, 'INTER_SCHED');
  }

  static hasTechnicianClosed(events: CrInterventionEvent[] | undefined = []): boolean {
    return this.hasEvent(events, 'INTER_TCLOSED');
  }

  static hasSubcontractorClosed(events: CrInterventionEvent[] | undefined = []): boolean {
    return this.hasEvent(events, 'INTER_TSCONTRACT');
  }

  static hasClosedEvent(events: CrInterventionEvent[] | undefined = []): boolean {
    return this.hasEvent(events, 'INTER_CLOSED');
  }

  static hasFeedback(events: CrInterventionEvent[] | undefined = []): boolean {
    return this.hasEvent(events, 'INTER_INPROG');
  }
}

export class CaseEventsHelper {
  static hasEvent(events: CrInterventionEvent[], eventCode: string): boolean {
    if (events?.length > 0) {
      return !!events.find((event) => event.step__code === eventCode);
    }
    return false;
  }

  static hasClosedEvent(events: CrInterventionEvent[] | undefined = []): boolean {
    return this.hasEvent(events, 'DOSS_CLOSED');
  }
}
