import React, { useState, useEffect } from 'react';
import WeatherConditions from './WeatherConditions/WeatherConditions';
import { Alert, Grid, Button, LinearProgress, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import SmsHistory from './SmsHistory/SmsHistory';
import ContractState from './ContractState';
import { ModuleData } from './CR.types';
import CallHistory from './CallHistory';
import NetworkStatus from './NetworkStatus/NetworkStatus';
import DeviceInfos from './DeviceInfos/DeviceInfos';
import crCaseService from '../Cases/CrCaseService';

type DiagnosticParams = {
  moduleAddress: string;
};

const Diagnostics = () => {
  const { moduleAddress } = useParams<DiagnosticParams>();
  const [moduleData, setModuleData] = useState<ModuleData>();
  const [isLoading, setLoading] = useState<boolean>();
  const navigate = useNavigate();
  const [hasCoords, setHasCoords] = useState<boolean>();
  const [createCaseIsLoading, setCreateCaseIsLoading] = useState<boolean>(false);

  const moduleFound = () => {
    return moduleData && moduleData?.module_address;
  };

  useEffect(() => {
    const fetchModuleAddress = async () => {
      setLoading(true);
      await axios
        .get(`/api/boi/devices/${moduleAddress}/`)
        .then((response: any) => {
          setModuleData(response.data);
          moduleData?.latitude && moduleData?.longitude ? setHasCoords(true) : setHasCoords(false);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchModuleAddress();
  }, [moduleAddress, moduleData?.latitude, moduleData?.longitude]);

  const CreateCaseButton = () => {
    return moduleAddress ? (
      <Grid container justifyContent="flex-end">
        <Button
          id="create_crCase"
          sx={{ margin: 2 }}
          variant="contained"
          disabled={createCaseIsLoading}
          onClick={() => {
            setCreateCaseIsLoading(true);
            crCaseService
              .createCase(moduleAddress)
              .then((crCase) => {
                navigate(`/boi/cr/cases/edit/${crCase.id}/`);
              })
              .finally(() => setCreateCaseIsLoading(false));
          }}
        >
          Créer un dossier
        </Button>
      </Grid>
    ) : (
      <></>
    );
  };
  return (
    <>
      {isLoading && <LinearProgress />}
      {isLoading === false && !moduleFound() && (
        <Alert severity="error">Le module {moduleAddress} n'a pas été trouvé.</Alert>
      )}

      {moduleFound() && (
        <>
          {moduleData && (
            <>
              <CreateCaseButton />

              <Box>
                <h3>Équipement</h3>
                <DeviceInfos moduleData={moduleData} />
              </Box>

              <Box sx={{ mt: 2 }}>
                <h3>État opérateur</h3>
                {hasCoords ? (
                  <NetworkStatus moduleData={moduleData} />
                ) : (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    Il n'y a aucune coordonnée GPS renseignée pour le concentrateur {moduleAddress}.
                  </Alert>
                )}
              </Box>

              <Box sx={{ mt: 2 }}>
                <h3>Test ping SMS</h3>
                <SmsHistory moduleData={moduleData} />
              </Box>
              <Box sx={{ mt: 2 }}>
                <h3>État contrat</h3>
                <ContractState moduleData={moduleData} />
              </Box>
              <Box sx={{ mt: 2 }}>
                <h3>Historique communications</h3>
                <CallHistory moduleData={moduleData} />
              </Box>
              <Box sx={{ mt: 2 }}>
                <h3>Conditions météo</h3>
                {hasCoords ? (
                  <WeatherConditions moduleData={moduleData} />
                ) : (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    Il n'y a aucune coordonnée GPS renseignée pour le concentrateur {moduleAddress}.
                  </Alert>
                )}
              </Box>

              <CreateCaseButton />
            </>
          )}
        </>
      )}
    </>
  );
};
export default Diagnostics;
