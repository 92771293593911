import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  Stack,
  TextField
} from '@mui/material';
import { CrInterventionEvent } from '../../CR/Cases/Types';
import { Formik, useFormikContext } from 'formik';

import {
  BirdzNotif,
  useNotif,
  SelectField,
  BirdzTitle
} from '@applications-terrains/birdz-react-library';
import {
  formatToSelectOptions,
  useGetCaseStatuses,
  useGetCaseTypes
} from '../../../hooks/datarefs';

import CrCaseEvents from '../../CR/Cases/CrCaseEvents';
import MrCaseInterventions from './MrCaseInterventions';
import { MRCASETYPE, MrCase } from './Types';
import MrCaseInterventionForm from './MrCaseInterventionForm';
import { CaseEventsHelper } from '../../CR/utils/EventsHelper';
import * as Yup from 'yup';
import CrCaseStatus from '../../CR/Cases/CrCaseStatus';
import MrCaseEquipmentCount from './MrCaseEquipmentCount';

type FormErrorNotificationProps = {
  onError(error: string): void;
};

export default function MrCaseForm() {
  const { id } = useParams();
  const endpoint = `/api/boi/cases/mr-cases/${id}/`;
  const [isLoading, setLoading] = useState<boolean>(false);

  const [mrCase, setMrCase] = useState<Partial<MrCase>>({});
  const { notif, notifOptions } = useNotif();
  const { data: caseStatuses } = useGetCaseStatuses();
  const { data: caseTypes } = useGetCaseTypes();
  const navigate = useNavigate();

  const loadMRCase = useCallback(async () => {
    return axios
      .get(endpoint)
      .then((response: any) => {
        setMrCase(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [endpoint]);

  useEffect(() => {
    setLoading(true);
    loadMRCase();
  }, [endpoint, loadMRCase]);

  const saveMRCase = async (values: any) => {
    return axios.put(`/api/boi/cases/mr-cases/${id}`, values).then((response) => {
      setMrCase(response.data);
    });
  };

  const onSubmitForm = (values: any) => {
    saveMRCase(values).then(
      () => {
        notif({
          content: 'Le formulaire a été enregistré avec succès',
          type: 'success'
        });
      },
      () => {
        notif({
          content: "Une erreur est survenue lors de l'enregistrement du formulaire",
          type: 'error'
        });
      }
    );
  };

  const onCreateWzPhoenix = async (values: any) => {
    await saveMRCase(values);
    axios.post(`/api/boi/cases/mr-cases/${id}/create-work-zone-phoenix/`).then(
      async () => {
        notif({
          content: 'La zone de travaux Phoenix a été créé avec succès',
          type: 'success'
        });
        await loadMRCase();
      },
      (error: any) => {
        const errorMessage = error?.response?.data?.errorMessage
          ? error.response.data.errorMessage
          : typeof error?.response?.data === 'string'
          ? error.response.data
          : '';
        notif({
          content: (
            <>
              Une erreur est survenue lors de la création de la zone de travaux Phoenix.
              <br />
              Erreur:{' '}
              {errorMessage
                .split(' | ')
                .filter((message: string) => message !== '')
                .join(', ')}
            </>
          ),
          type: 'error'
        });
      }
    );
  };

  const archiveCase = async () => {
    await axios.post(`/api/boi/cases/mr-cases/${id}/archive/`).then(
      () => {
        notif({
          content: 'Le dossier module de répétition a été archivé avec succès',
          type: 'success'
        });
        setTimeout(() => {
          navigate('/boi/mr/cases');
        }, 1500);
      },
      () => {
        notif({
          content: "Une erreur est survenue lors de l'archivage du dossier module de répétition",
          type: 'error'
        });
      }
    );
  };

  const closeMrCase = async () => {
    await axios.post(`/api/boi/cases/mr-cases/${id}/close/`).then(
      () => {
        notif({
          content: 'Le dossier module de répétition a été clos avec succès',
          type: 'success'
        });
      },
      () => {
        notif({
          content: 'Une erreur est survenue lors de la clôture du dossier module de répétition',
          type: 'error'
        });
      }
    );
  };

  const FormErrorNotification = ({ onError }: FormErrorNotificationProps) => {
    const { isValid, isValidating, isSubmitting } = useFormikContext();

    useEffect(() => {
      if (!isValid && !isValidating && isSubmitting) {
        onError('Le formulaire contient des erreurs');
      }
    }, [isSubmitting, isValid, isValidating]);

    return null;
  };

  const openInPhoenix = () => {
    if (mrCase?.action_type === MRCASETYPE.MAINTENANCE) {
      window.open(
        `http://applispreproduction.priv.birdz.com/phoenix/maintenance/getMaintenanceTable/${mrCase?.phoenix_zone_id}`,
        '_blank'
      );
    } else if (mrCase?.action_type === MRCASETYPE.DEPLOYMENT) {
      window.open(
        `http://applispreproduction.priv.birdz.com/phoenix/getDeployTable/${mrCase?.phoenix_zone_id}`,
        '_blank'
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <Box textAlign="center">
          <CircularProgress />
          <h3>Chargement en cours...</h3>
        </Box>
      ) : (
        <Paper variant="outlined" square sx={{ p: 2 }} id="mrCaseForm">
          <BirdzTitle>Informations générales</BirdzTitle>

          <Formik
            initialValues={mrCase}
            enableReinitialize={true}
            onSubmit={(values) => {
              onSubmitForm(values);
              return;
            }}
            validationSchema={Yup.object().shape({
              number: Yup.mixed().required('Champ obligatoire'),
              type: Yup.mixed().required('Champ obligatoire'),
              status: Yup.mixed().required('Champ obligatoire')
            })}
          >
            {(props: any) => {
              const { errors, setSubmitting, handleSubmit, values } = props;
              return (
                <form
                  id="topForm"
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    setSubmitting(true);
                    // Check
                    if (Object.keys(errors).length === 0) {
                      handleSubmit();
                    }
                  }}
                >
                  <FormErrorNotification
                    onError={(error) => {
                      notif({ type: 'error', content: error });
                    }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                      <Stack spacing={2} sx={{ width: '400px' }}>
                        <FormControl>
                          <TextField
                            name="number"
                            id="number"
                            inputProps={{ readOnly: true }}
                            value={mrCase?.number}
                            label="Numéro dossier"
                            variant="filled"
                            sx={{ width: 300 }}
                          />
                        </FormControl>
                        <FormControl>
                          <SelectField
                            name="type"
                            label="Type"
                            options={formatToSelectOptions(caseTypes)}
                            required={true}
                            sx={{ width: 300 }}
                          />
                        </FormControl>
                        <FormControl>
                          <SelectField
                            name="status"
                            label="Status"
                            options={formatToSelectOptions(caseStatuses)}
                            required={true}
                            sx={{ width: 300 }}
                          />
                        </FormControl>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={5} textAlign="right" sx={{ width: 300 }}>
                      <Box sx={{ marginTop: '-50px' }}>
                        <CrCaseStatus crCaseStatusId={values?.status} />
                      </Box>

                      <Button
                        disabled={CaseEventsHelper.hasClosedEvent(mrCase.events_history)}
                        variant="contained"
                        onClick={() => closeMrCase()}
                      >
                        Clore dossier
                      </Button>
                      <Box>
                        <MrCaseEquipmentCount equipmentsCount={mrCase?.equipments_count} />

                        <Box sx={{ mt: 2 }}>
                          Type de dossier :{' '}
                          <strong>
                            {mrCase.action_type === MRCASETYPE.MAINTENANCE && 'Maintenance'}
                            {mrCase.action_type === MRCASETYPE.DEPLOYMENT && 'Déploiement'}
                          </strong>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  <CrCaseEvents
                    crEvents={mrCase?.events_history || []}
                    id={id ? parseInt(id) : undefined}
                    onEventsChange={(crEvents: CrInterventionEvent[]) => {
                      const newResults: any = { ...mrCase };
                      newResults.events_history = crEvents;
                      setMrCase(newResults);
                    }}
                    readOnly={false}
                  />

                  <MrCaseInterventionForm
                    mrCase={mrCase}
                    onCreateWzPhoenix={() => {
                      onCreateWzPhoenix(values);
                    }}
                  />

                  <MrCaseInterventions mrCase={mrCase} />

                  <Button variant="outlined" color="primary" onClick={() => openInPhoenix()}>
                    Vers la fiche Phoenix
                  </Button>

                  <Grid container sx={{ mt: 5, alignItems: 'center', justifyContent: 'center' }}>
                    <Button variant="outlined" onClick={() => archiveCase()} sx={{ mr: 2 }}>
                      Archiver
                    </Button>
                    <Button variant="contained" sx={{ mr: 2 }} type="submit">
                      Enregistrer
                    </Button>
                    <Button
                      disabled={CaseEventsHelper.hasClosedEvent(mrCase.events_history)}
                      variant="contained"
                      onClick={() => closeMrCase()}
                    >
                      Clore
                    </Button>
                  </Grid>
                </form>
              );
            }}
          </Formik>
          <BirdzNotif options={notifOptions} />
        </Paper>
      )}
    </>
  );
}
