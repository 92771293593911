import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import LicencesStats from './LicencesStats';
import NotationsStats from './NotationsStats';

function Dashboard() {
  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      <Grid item xs={6}>
        <Card sx={{ textAlign: 'center' }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Informations licences
            </Typography>
            <LicencesStats />
          </CardContent>
          <CardActions>
            <Button
              size="small"
              variant="outlined"
              sx={{ margin: 'auto' }}
              component={Link}
              to="/bconnect/dashboard/licences"
            >
              Voir détails
            </Button>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={6}>
        <Card sx={{ textAlign: 'center' }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Notations moyennes
            </Typography>
            <NotationsStats />
          </CardContent>
          <CardActions>
            <Button
              size="small"
              variant="outlined"
              sx={{ margin: 'auto' }}
              component={Link}
              to="/bconnect/dashboard/notations"
            >
              Voir détails
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
