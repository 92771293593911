import React, { useEffect, useState } from 'react';
import {
  AppPaper,
  BirdzSubtitle,
  BirdzTitle,
  ListField,
  ListPage,
  SearchField
} from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Grid } from '@mui/material';
import { useGetAgents, useGetCompanies } from '../../../hooks/datarefs';
import { licencesCount } from './licences.utils';

export default function LicencesList() {
  const endpoint = '/api/bconnect/webapp/customer/agents_stats/';
  const [listFields, setListFields] = useState<ListField[]>([]);
  const [searchFields, setSearchFields] = useState<SearchField[]>([]);

  const { data: companies } = useGetCompanies();
  const { data: agents } = useGetAgents();

  useEffect(() => {
    if (!companies) {
      return;
    }

    const listFields: ListField[] = [
      {
        name: 'CompanyId',
        label: 'Client',
        transform: (value: any) => {
          const customer = companies?.find((company) => company.Id === value);
          return 'Id ' + value + ' - ' + customer?.Name;
        },
        orderable: true
      },
      {
        name: 'Id',
        label: 'Id Agent'
      },
      {
        name: 'Name',
        label: 'Nom Agent'
      },
      {
        name: 'nb_interventions',
        label: "Nombre d'interventions",
        transform: (value: any) => {
          if (value === 0) {
            return <Grid sx={{ color: 'red' }}>{value}</Grid>;
          } else return value;
        },
        orderable: true
      },
      {
        name: 'date_first',
        label: 'Date de 1ère intervention',
        transform: (value: any) => {
          if (value) {
            return (
              'Le ' + moment(value).format('DD-MM-YYYY') + ' à ' + moment(value).format('HH:mm')
            );
          } else {
            return '';
          }
        },
        orderable: true
      },
      {
        name: 'date_last',
        label: 'Date de dernière intervention',
        transform: (value: any) => {
          if (value) {
            if (licencesCount(value) <= 180) {
              return (
                'Le ' + moment(value).format('DD-MM-YYYY') + ' à ' + moment(value).format('HH:mm')
              );
            }
            // we want to indicate if the licence was not used for 180 days in a row
            else {
              return (
                <Grid sx={{ color: 'red' }}>
                  Le {moment(value).format('DD-MM-YYYY')} à {moment(value).format('HH:mm')}
                  <Tooltip
                    title={`La licence n'est plus utilisée depuis ${licencesCount(value)} jours`}
                  >
                    <IconButton>
                      <AccessTimeIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              );
            }
          } else {
            return '';
          }
        },
        orderable: true
      },
      {
        name: 'State',
        label: 'Statut Licence',
        transform: (value: any) => {
          const state = agentState.find((state) => state.value === value);
          return state?.label;
        },
        orderable: true
      }
    ];

    const searchFields: SearchField[] = [
      {
        name: 'Client',
        label: 'Client',
        type: 'select',
        options: {
          identifier: 'value',
          label: 'label',
          values: companies.map((company) => {
            return { value: company.Id, label: company.Name };
          })
        }
      },
      {
        name: 'Agent',
        label: 'Agent',
        type: 'select',
        options: {
          identifier: 'value',
          label: 'label',
          values: agents?.map((agent) => {
            return { value: agent.Id, label: agent.Name };
          })
        }
      },
      {
        name: 'State',
        label: 'Statut Licence',
        type: 'select',
        options: {
          identifier: 'value',
          label: 'label',
          values: agentState
        }
      }
    ];

    setListFields(listFields);
    setSearchFields(searchFields);
  }, [companies, agents]);

  const agentState = [
    {
      value: 1,
      label: 'Active'
    },
    {
      value: 2,
      label: 'Suspendue'
    }
  ];

  return (
    <>
      <AppPaper>
        <BirdzTitle>Liste des licences</BirdzTitle>
        <BirdzSubtitle>
          Les licences non utilisées depuis plus de 6 mois sont affichées en rouge.
        </BirdzSubtitle>

        <ListPage
          endpoint={endpoint}
          fields={listFields}
          searchFields={searchFields}
          paginationOptions={{
            pageSize: 25
          }}
        />
      </AppPaper>
    </>
  );
}
