import {
  BirdzDialog,
  BirdzNotif,
  ListPage,
  useDialog,
  useNotif
} from '@applications-terrains/birdz-react-library';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TextField
} from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ExportFormat } from './ExportFormatForm';
import Edit from '@mui/icons-material/Edit';
import { useGetExtractorFields } from '../../../hooks/datarefs';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddCircle from '@mui/icons-material/AddCircle';
import _ from 'lodash';
import Delete from '@mui/icons-material/Delete';
import { ExportFormatBase } from './ExportFormatSettingsModal/ExportFormatBase';
import TitleIcon from '@mui/icons-material/Title';
import TodayIcon from '@mui/icons-material/Today';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import Filter1Icon from '@mui/icons-material/Filter1';
import { useGlobalContext } from '../../../contexts/globalContext';

export type ExtractorFieldsGroupedBySource = {
  [source: string]: { id: number; name: string; type: string; source: string };
};

export type ExportField = {
  id: number;
  name: string;
  type: string;
  source: string;
};

export type ExportFormatSettingsType = {
  id: number;
  export_field_name: string;
  export_format: number;
  data_mapping: number;
  field_to_export: number;
  field_to_export_object: ExportField;
  fixed_label: string;
  bool_if_true: boolean;
  bool_if_false: boolean;
  bool_if_null: boolean;
  text_prefix: string;
  text_suffix: string;
  number_decimal_separator: number;
  number_thousand_separator: number;
  number_digit_number_after_comma: number;
  date_forced_time: string;
  date_operation_type: string;
  date_operation_count: number;
  date_operation_unit: string;
  date_format: string;
  created_at: number;
  updated_at: number;
};

export default function ExportFormatSettings() {
  const { id } = useParams<{ id: string }>();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { setValues } = useGlobalContext();

  if (!id) {
    return null;
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exportFormat, setExportFormat] = useState<Partial<ExportFormat>>({});
  const endpoint = '/api/boi/extractor/export-formats/';
  const { data: extractorFields } = useGetExtractorFields();
  const [columnToAdd, setColumnToAdd] = useState<ExportField>();
  const [exportformatSettings, setExportFormatSettings] =
    useState<Partial<ExportFormatSettingsType>>();
  const { notif, notifOptions } = useNotif();
  const [expandedAccordion, setExpandedAccordion] = React.useState<string | false>(false);

  // exportFormat.settings
  const loadExportFormatSettings = useCallback(() => {
    if (id) {
      setIsLoading(true);
      axios.get(`${endpoint}${id}/`).then((response: AxiosResponse<ExportFormat>) => {
        setExportFormat(response.data);
        setValues(response.data);
        setIsLoading(false);
      });
    }
  }, [id]);

  useEffect(() => {
    loadExportFormatSettings();
  }, [id]);

  const getExtractorFields = useCallback(() => {
    return _.groupBy(extractorFields, 'source');
  }, [extractorFields]);

  const getExtractorFieldById = useCallback(
    (fieldId: number) => {
      return extractorFields?.find((field: any) => field.id === fieldId);
    },
    [extractorFields]
  );

  const onExpandAccordion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };

  const addColumn = (formValues: Partial<ExportFormatSettingsType>) => {
    const payload = Object.assign({}, formValues, { export_format: parseInt(id) });

    if (!formValues.id) {
      return axios.post(`/api/boi/extractor/export-format-settings/`, payload).then(() => {
        notif({
          type: 'success',
          title: 'Colonne ajoutée',
          content: "La colonne a bien été ajoutée à ce format d'export."
        });
        setExportFormatSettings({});
        loadExportFormatSettings();
      });
    } else {
      return axios
        .put(`/api/boi/extractor/export-format-settings/${payload.id}/`, payload)
        .then(() => {
          notif({
            type: 'success',
            title: 'Colonne modifiée',
            content: 'La colonne a bien été modifiée'
          });
          setExportFormatSettings({});
          loadExportFormatSettings();
        });
    }
  };

  const extractorFieldsGroupedBySource = getExtractorFields();

  if (isLoading) {
    return (
      <Grid container justifyContent="center" sx={{ mt: 4 }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Paper variant="outlined" square sx={{ p: 2 }}>
        <h3>Paramétrage d'un format d'export</h3>

        <Grid container alignItems="center" spacing={2}>
          <Grid alignItems={'center'} item xs={2}>
            Nom
          </Grid>
          <Grid alignItems={'center'} item xs={4}>
            {exportFormat.name}
          </Grid>
          <Grid alignItems={'center'} item xs={2}>
            ERP
          </Grid>
          <Grid alignItems={'center'} item xs={4}>
            {exportFormat.erp_name}
          </Grid>
          <Grid alignItems={'center'} item xs={2}>
            Version
          </Grid>
          <Grid alignItems={'center'} item xs={4}>
            {exportFormat.version}
          </Grid>
          <Grid alignItems={'center'} item xs={2}>
            Type de fichier
          </Grid>
          <Grid alignItems={'center'} item xs={4}>
            {exportFormat.file_type}
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={4}>
            {extractorFieldsGroupedBySource &&
              Object.keys(extractorFieldsGroupedBySource).map((source) => {
                const extractorFields = extractorFieldsGroupedBySource[source]
                  ? extractorFieldsGroupedBySource[source]
                  : [];

                return (
                  <Accordion
                    key={source}
                    disableGutters
                    onChange={onExpandAccordion(source)}
                    sx={{ mb: 1 }}
                    expanded={expandedAccordion === source}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      id="bconnect-fields"
                      sx={{
                        backgroundColor: 'rgb(222, 226, 230)',
                        height: '34px',
                        minHeight: '34px'
                      }}
                    >
                      {source}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ display: 'flex' }}>
                        <Box sx={{ flex: 1 }}>
                          <Autocomplete
                            onChange={(e: any, newValues: any) => {
                              if (!newValues) return;

                              setColumnToAdd({
                                name: newValues.value,
                                type: newValues.type,
                                id: newValues.id,
                                source: newValues.source
                              });
                            }}
                            options={extractorFields.map((extractorField: any) => {
                              return {
                                value: extractorField.name,
                                label: extractorField.name,
                                type: extractorField.type,
                                id: extractorField.id
                              };
                            })}
                            renderInput={(params: any) => {
                              return <TextField {...params} variant="outlined" size="small" />;
                            }}
                            renderOption={(props, option) => {
                              let IconComponent;
                              switch (option.type) {
                                case 'TEXT':
                                  IconComponent = TitleIcon;
                                  break;
                                case 'DATE':
                                  IconComponent = TodayIcon;
                                  break;
                                case 'BOOLEAN':
                                  IconComponent = LibraryAddCheckIcon;
                                  break;
                                case 'NUMBER':
                                  IconComponent = Filter1Icon;
                                  break;
                              }
                              return (
                                <li {...props}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      width: '100%'
                                    }}
                                  >
                                    {option.label}
                                    {IconComponent && <IconComponent />}
                                  </div>
                                </li>
                              );
                            }}
                            noOptionsText="Aucune valeur à sélectionner"
                            loadingText="Chargement en cours..."
                          />
                        </Box>
                        <IconButton
                          disabled={!columnToAdd}
                          onClick={() => {
                            setColumnToAdd(undefined);
                            if (columnToAdd) {
                              setExportFormatSettings({
                                field_to_export_object: columnToAdd,
                                field_to_export: columnToAdd.id
                              });
                            }
                          }}
                        >
                          <AddCircle fontSize="small" />
                        </IconButton>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </Grid>
          <Grid item xs={8} sx={{ mt: '-32px' }}>
            <ListPage
              loadedData={exportFormat.settings || []}
              fields={[
                {
                  name: 'column_name',
                  label: 'Colonne',
                  transform: (value, setting: any) => {
                    const field = getExtractorFieldById(setting.field_to_export);
                    return `${field?.source} - ${field?.name}`;
                  }
                },
                {
                  name: 'field_type',
                  label: 'Type',
                  transform: (value, setting: any) => {
                    const field = getExtractorFieldById(setting.field_to_export);
                    return `${field?.type || '-'}`;
                  }
                },
                {
                  name: 'date_format',
                  label: 'Format'
                },
                {
                  name: 'export_field_name',
                  label: 'Nom fichier'
                }
              ]}
              actions={[
                {
                  name: 'edit',
                  render: (exportFormatSettings: ExportFormatSettingsType) => {
                    return (
                      <IconButton
                        onClick={() => {
                          setExportFormatSettings(exportFormatSettings);
                        }}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    );
                  }
                },
                {
                  name: 'delete',
                  render: (exportFormatSettings: ExportFormatSettingsType) => {
                    return (
                      <IconButton
                        onClick={() => {
                          confirmDialog({
                            title: 'Supprimer la colonne',
                            content: 'Êtes-vous sûr de vouloir supprimer cette colonne?',
                            onValidate: () => {
                              axios
                                .delete(
                                  `/api/boi/extractor/export-format-settings/${exportFormatSettings.id}/`
                                )
                                .then(() => {
                                  notif({
                                    type: 'success',
                                    title: 'Colonne supprimée',
                                    content: 'La colonne a bien été supprimée'
                                  });
                                  loadExportFormatSettings();
                                });
                              closeDialog();
                            },
                            onCancel: () => {
                              closeDialog();
                            }
                          });
                        }}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    );
                  }
                }
              ]}
              displayResultsNumber={false}
              displayPaginationOptions={false}
            />
          </Grid>
        </Grid>

        {exportformatSettings && Object.keys(exportformatSettings).length > 0 && (
          <ExportFormatBase
            settings={exportformatSettings}
            onSubmit={(values: any) => {
              return addColumn(values);
            }}
            onClose={() => {
              setExportFormatSettings({});
            }}
          />
        )}

        <Box textAlign={'center'} sx={{ mt: 2 }}>
          <Button component={Link} to={`/boi/extractor/export-formats/list`} variant="contained">
            Retour
          </Button>
        </Box>
      </Paper>
      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
    </>
  );
}
