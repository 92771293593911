import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { SubcontractorWorkingHours } from './WorkingHours';
import axios from 'axios';
import { BirdzTitle, AppPaper } from '@applications-terrains/birdz-react-library';
import TechniciansBOE from './TechniciansBOE';
import PricingsBOE from './PricingsBOE';
import WorkingHoursBOE from './WorkingHoursBOE';

export type SubcontractoDetailsPricing = {
  id: number;
  year: number;
  year__label: string;
  price: string;
  start_date: string;
  end_date: string;
  capex_opex: string;
  subcontractor: number;
  product_accounting_code: string;
  product_accounting_name: string;
  product_subcontractor_name: string;
  max_usage_limit: null | number;
};

export type SubcontractorDetails = {
  id: number;
  name: string;
  action_type: string[];
  sage_code: string;
  nb_max_users: number;
  technicians: Array<{
    id: number;
    user: number;
    user__name: string;
    user__email: string;
    groups: number[];
    main_contact: boolean;
  }>;
  applicable_pricings: SubcontractoDetailsPricing[];
  additional_pricings: SubcontractoDetailsPricing[];
  working_day_duration_hours: number;
  weekdays_working_hours: SubcontractorWorkingHours[];
  created_at: number;
  updated_at: number;
};

const SubcontractorBOE = () => {
  const [subcontractorDetails, setSubcontractorDetails] = useState<any>();

  useEffect(() => {
    axios.get('/api/boe/subcontractors/subcontractors/details/').then((response: any) => {
      setSubcontractorDetails(response.data);
    });
  }, []);

  return (
    <AppPaper>
      <BirdzTitle>{subcontractorDetails?.name}</BirdzTitle>

      <Grid container>
        <Grid container item xs={6}>
          <Grid item xs={4}>
            Type:{' '}
          </Grid>
          <Grid item xs={8}>
            {subcontractorDetails?.action_type?.join(', ')}
          </Grid>
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={4}>
            Nombre utilisateurs max:{' '}
          </Grid>
          <Grid item xs={8}>
            {subcontractorDetails?.nb_max_users}
          </Grid>
        </Grid>
      </Grid>
      <TechniciansBOE />
      <PricingsBOE subcontractor={subcontractorDetails} />
      <WorkingHoursBOE subcontractor={subcontractorDetails} />
    </AppPaper>
  );
};
export default SubcontractorBOE;
