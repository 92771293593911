import React, { useEffect } from 'react';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import useCountdown from '../../hooks/useCountdown';

const NotifWithCountdown = ({
  action,
  notifObj
}: {
  action: () => void;
  notifObj: { type: 'error' | 'success'; content: string };
}) => {
  const notifCountdown = useCountdown(action);
  const { notif, notifOptions } = useNotif();

  useEffect(() => {
    notif({
      ...notifObj,
      content: (
        <div>
          {notifObj.content}
          <div className="d-flex">
            Redirection dans{' '}
            <div className="ml-25" style={{ width: '20px' }}>
              {notifCountdown}
            </div>
          </div>
        </div>
      )
    });
  }, [notifCountdown]);

  return <BirdzNotif options={notifOptions} />;
};

export default NotifWithCountdown;
