import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import {
  SearchForm,
  ListPage,
  BirdzTitle,
  AppPaper,
  SearchFormValue,
  SearchField
} from '@applications-terrains/birdz-react-library';
import { Link, useNavigate } from 'react-router-dom';
import { StyledTableHeaderRow } from '../../styles/Table.styled';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useLocation } from 'react-router-dom';
import { useNotif } from '@applications-terrains/birdz-react-library';
import { BirdzNotif } from '@applications-terrains/birdz-react-library';

export type Call = {
  case_number: string;
  created_at: number;
  created_by: number;
  created_by__name: string;
  device_id: string;
  id: number;
  incoming_call_number: string;
  technician: number;
  technician__name: string;
  updated_at: string;
};

type InCall = {
  case_number: string;
  city: string;
  contract_code: string;
  contract_label: string;
  cr_case: number;
  created_at: number;
  device_id: string;
  device_type: string;
  id: number;
  pdc_pdr_id: string;
  scheduled_start_date: string;
  updated_at: number;
  work_type: string;
};

type CallDevice = {
  device_id: string;
  device_type: string;
  contract_code: string;
  contract_label: string;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SearchCallsForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchRef: any = useRef(null);
  const query = useQuery();
  const [filterValues, setFilterValues] = useState<SearchFormValue>();
  const [devices, setDevices] = useState<CallDevice[]>();
  const [filters, setFilters] = useState<string>('');
  const { notif, notifOptions } = useNotif();
  const endpoint = `/api/boi/calls/in-calls/`;

  const searchFields: SearchField[] = [
    {
      name: 'technician',
      label: 'Technicien',
      options: {
        source: '/api/boi/administration/users/?user_type=external&is_active=true&',
        searchIsMulti: false
      }
    },
    {
      name: 'incoming_call_number',
      label: 'Numéro de téléphone',
      type: 'text',
      fieldSize: 8,
      labelSize: 4
    },
    {
      name: 'device_id',
      label: 'Équipement',
      options: {
        source: '/api/boi/devices/search/',
        label: 'device_id',
        searchIsMulti: false,
        formatResponse: (deviceId: string) => {
          return { value: deviceId, label: deviceId };
        }
      }
    },
    {
      name: 'case_number',
      label: 'Intervention',
      type: 'text',
      fieldSize: 8,
      labelSize: 4
    }
  ];

  const createHistory = (value: InCall) => {
    const payload = Object.assign({}, filterValues?.filterValues);
    payload.case_number = value.case_number;
    payload.device_id = value.device_id;

    axios
      .post(endpoint, payload)
      .then(() => {
        navigate(`/boi/cr/cases/edit/${value.cr_case}`);
      })
      .catch(function (error) {
        notif({
          type: 'error',
          content: 'Une erreur est survenue.' + error?.response?.data
        });
      });
  };

  const listFields = [
    {
      name: 'case_number',
      label: 'Identifiant'
    },
    {
      name: 'work_type',
      label: 'Type'
    },
    {
      name: 'pdc_pdr_id',
      label: 'PDC/PDR'
    },
    {
      name: 'device_id',
      label: 'Adresse radio'
    },
    {
      name: 'device_type',
      label: 'Type équipement'
    },
    {
      name: 'contract',
      label: 'Contrat',
      transform: (value: any, item: any) => {
        return (
          <>
            {item.contract_code || ''} - {item.contract_label || ''}
          </>
        );
      }
    },
    {
      name: 'city',
      label: 'Commune'
    },
    {
      name: 'technician__name',
      label: 'Technicien'
    }
  ];

  const actions = [
    {
      name: 'view-cr-case',
      label: `Consulter le dossier`,
      render: (value: any) => {
        return (
          <IconButton onClick={() => createHistory(value)}>
            <SettingsIcon fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  useEffect(() => {
    const selectQueryParams = ['device_id', 'technician'];
    const textQueryParams = ['incoming_call_number', 'technician_name', 'case_number'];
    const queryParams = [...selectQueryParams, ...textQueryParams];
    const newFilterValues: SearchFormValue = {
      filterValues: {},
      formValue: {}
    };

    queryParams.forEach((paramName: string) => {
      const paramValue = query.get(paramName);
      newFilterValues.filterValues[paramName] = paramValue;

      if (selectQueryParams.includes(paramName)) {
        const paramLabel = query.get(paramName + '_name') || paramValue;
        newFilterValues.formValue[paramName] = { value: paramValue, label: paramLabel };
      } else if (textQueryParams.includes(paramName)) {
        newFilterValues.formValue[paramName] = paramValue;
      }
    });

    setFilterValues(newFilterValues);
    if (location.search) {
      setFilters(location.search);
      setTimeout(() => {
        searchRef.current?.launchSearch();
      }, 500);
    }
  }, [location.search]);

  return (
    <AppPaper>
      <BirdzTitle>Dossier / Interventions</BirdzTitle>
      <AppPaper>
        <Box sx={{ mt: 2 }}>
          <SearchForm
            ref={searchRef}
            allowReadParamsFromQueryString={false}
            onSubmit={(filterString: any, rawFiltersValues: any) => {
              if (!filterString) {
                notif({
                  type: 'error',
                  content: 'Veuillez saisir au moins un filtre.'
                });
              } else {
                setFilters(filterString);
                setFilterValues(rawFiltersValues);
              }
            }}
            fields={searchFields}
            values={filterValues}
          />
        </Box>
      </AppPaper>

      {filters && (
        <>
          <ListPage
            endpoint={endpoint}
            fields={listFields}
            filters={filters}
            actions={actions}
            onLoadItems={(results: any, data: any) => {
              setDevices(data.devices);
            }}
          />
          <BirdzTitle>Équipements</BirdzTitle>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table size="small">
              <TableHead>
                <StyledTableHeaderRow>
                  <TableCell>Adresse radio</TableCell>
                  <TableCell>Type équipement</TableCell>
                  <TableCell>Contrat</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </StyledTableHeaderRow>
              </TableHead>
              <TableBody>
                {devices &&
                  devices.length > 0 &&
                  devices.map((device) => {
                    return (
                      <TableRow key={`${device.device_id}`}>
                        <TableCell>{device.device_id ? device.device_id : '-'}</TableCell>
                        <TableCell>{device.device_type ? device.device_type : '-'}</TableCell>
                        <TableCell>
                          {device.contract_code} - {device.contract_label}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Détails diagnostic">
                            <IconButton
                              component={Link}
                              to={`/boi/cr/diagnostics/details/${device.device_id}`}
                            >
                              <SettingsIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {(!devices || devices.length === 0) && (
                  <TableRow>
                    <TableCell colSpan={5}>Il n'y a aucun résultat à afficher</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <BirdzNotif options={notifOptions} />
    </AppPaper>
  );
}
