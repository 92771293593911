import React, { useState } from 'react';
import { AppPaper, BirdzTitle, ListPage } from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import { ContratsDetail } from './ContractDetail';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

type Contract = {
  contract_management_code: string;
  contract_effect_date: Date;
  contract_expiration_date: Date;
  contract_ve: string;
  client: string;
  sage_code_label: string;
};

const Contracts = (params: any) => {
  const endpoint = '/api/boi/params/contracts/';
  const [contratDetail, setContratDetail] = useState<any>();

  const listFields = [
    {
      name: 'contract_management_code',
      label: 'Code contrat',
      options: { width: '100px' }
    },

    {
      name: 'contract_ve',
      label: 'Contrat VE',
      className: 'text-center',
      options: { width: '150px' },
      orderable: true
    },
    {
      name: 'client',
      label: 'Client',
      className: 'text-center',
      options: { width: '150px' },
      orderable: true
    },
    {
      name: 'sage_code_label',
      label: 'Code sage ',
      className: 'text-center',
      options: { width: '150px' },
      orderable: true
    },
    {
      name: 'contract_effect_date',
      label: "Date d'effet",
      orderable: true,
      transform: (value: any) => {
        return (value && moment(value).format('DD/MM/YYYY')) || '';
      },
      options: { width: '150px' }
    },
    {
      name: 'contract_expiration_date',
      label: "Date d'expiration",
      orderable: true,
      transform: (value: any) => {
        return (value && moment(value).format('DD/MM/YYYY')) || '';
      },
      options: { width: '150px' }
    }
  ];

  const actions = [
    {
      name: 'detail',
      label: 'Détail',
      render: (contratDetail: Contract) => {
        return (
          <IconButton
            onClick={() => {
              setContratDetail(contratDetail);
            }}
          >
            <SearchIcon fontSize="small" />
          </IconButton>
        );
      },
      options: { width: '100px' }
    }
  ];

  return (
    <AppPaper>
      {params.action === 'list' && (
        <>
          <BirdzTitle>Liste des Contrats</BirdzTitle>
          <ListPage endpoint={endpoint} fields={listFields} actions={actions} />
        </>
      )}
      {contratDetail && (
        <ContratsDetail
          contractItem={contratDetail}
          onClose={() => {
            setContratDetail(null);
          }}
        />
      )}
    </AppPaper>
  );
};
export default Contracts;
