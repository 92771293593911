import React from 'react';
import {
  Box,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead
} from '@mui/material';
import {
  StyledTableBodyRow,
  StyledTableHeaderRow
} from '@applications-terrains/birdz-react-library';
import { useGetMRInterventionTypes } from '../../../hooks/datarefs';
import { useFormikContext } from 'formik';
import { MrCase, MrCaseDevice } from './Types';
import { PatrimonyItem } from '../../CR/Cases/Types';

type MrInterventionTypeProps = {
  mrCase: Partial<MrCase>;
};

export default function MrInterventionType({ mrCase }: MrInterventionTypeProps) {
  const { data: interventionTypes } = useGetMRInterventionTypes();
  const { values, setFieldValue } = useFormikContext<any>();

  const getInfoFromPatrimony = (device: MrCaseDevice, field: keyof PatrimonyItem) => {
    const deviceId: string = device.device || '';
    const patrimony = mrCase?.patrimony;
    return (patrimony && patrimony[deviceId] && patrimony[deviceId][field]) || '-';
  };

  return (
    <>
      <Box sx={{ flex: 1, display: 'block', mr: 2, mb: 1, textAlign: 'right' }}>
        Modifier par lot:{' '}
        <Select
          size="small"
          label=""
          variant="outlined"
          sx={{ width: 300, ml: 1 }}
          onChange={(e: any) => {
            mrCase.devices?.map((mrDevice: any, mrDeviceIndex: number) => {
              setFieldValue('batch_type', e.target.value);
              setFieldValue(`devices[${mrDeviceIndex}].intervention_type`, e.target.value);
            });
          }}
          value={values?.batch_type || ''}
        >
          {interventionTypes?.map((interventionType) => {
            return (
              <MenuItem key={interventionType.id} value={interventionType.id}>
                {interventionType.label}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table size="small">
          <TableHead>
            <StyledTableHeaderRow>
              <TableCell align="center">Source</TableCell>
              <TableCell align="center">PDC/PDR</TableCell>
              <TableCell align="center">Commune</TableCell>
              <TableCell align="center">Adresse radio</TableCell>
              <TableCell align="center">Contrat</TableCell>
              <TableCell align="center">Type d'intervention</TableCell>
            </StyledTableHeaderRow>
          </TableHead>
          <TableBody>
            {mrCase.devices?.map((device, index: number) => {
              return (
                <StyledTableBodyRow key={index}>
                  <TableCell align="center">{device.request_name || '-'}</TableCell>
                  <TableCell align="center">{getInfoFromPatrimony(device, 'pdc_pdr')}</TableCell>
                  <TableCell align="center">{getInfoFromPatrimony(device, 'city')}</TableCell>
                  <TableCell align="center">{device.device}</TableCell>
                  <TableCell align="center">
                    {getInfoFromPatrimony(device, 'contract_code')} -{' '}
                    {getInfoFromPatrimony(device, 'contract_label')}
                  </TableCell>
                  <TableCell align="center" width="300">
                    <Select
                      size="small"
                      label=""
                      variant="outlined"
                      sx={{ width: 300 }}
                      value={values.devices[index].intervention_type || ''}
                      // defaultValue={(values).devices[index].intervention_type || ""}
                      onChange={(e: any) => {
                        setFieldValue(`devices[${index}].intervention_type`, e.target.value);
                      }}
                    >
                      {interventionTypes?.map((interventionType) => {
                        return (
                          <MenuItem
                            key={index + '-' + interventionType.id}
                            value={interventionType.id}
                          >
                            {interventionType.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </TableCell>
                </StyledTableBodyRow>
              );
            })}
            {mrCase.devices?.length === 0 && (
              <StyledTableBodyRow>
                <TableCell colSpan={6} align="center">
                  Aucun équipement
                </TableCell>
              </StyledTableBodyRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
