import { AuthService } from '@applications-terrains/birdz-react-library';

export class SupportAuthService extends AuthService {
  constructor() {
    super();
  }

  canAccessBOE = () => {
    return this.canAccess('BOE') && !this.canAccess('BOI');
  };

  canAccessBOI = () => {
    return this.canAccess('BOI');
  };

  canAccessBOIorBOE = () => {
    return this.canAccess('BOI') || this.canAccess('BOE');
  };

  getEndpoint = (endpointBOI: string, endpointBOE: string, defaultEndpoint = '') => {
    if (this.canAccessBOI()) {
      return endpointBOI;
    }
    if (this.canAccessBOE()) {
      return endpointBOE;
    }
    return defaultEndpoint;
  };

  BOIorBOE = (BOI: JSX.Element | string, BOE: JSX.Element | string) => {
    if (this.canAccessBOI()) {
      return BOI;
    }
    if (this.canAccessBOE()) {
      return BOE;
    }
    return '';
  };

  isAdminGED = () => {
    return this.canAccessBOI() && this.canAccess('GED_ADMIN');
  };

  isViewerGED = () => {
    return this.canAccessBOE() && this.canAccess('GED_LECTURE');
  };
}
