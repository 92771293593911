import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Box, Button } from '@mui/material';
import Technicians from './Technicians';
import ApplicablePricings from './ApplicablePricings';
import AdditionalPricings from './AdditionalPricings';
import WorkingHours from './WorkingHours';
import { FormField, Item } from '@applications-terrains/birdz-react-library';

const SubcontractorsForm = ({ action }: any) => {
  const endpoint = '/api/boi/subcontractors/subcontractors/';
  const navigate = useNavigate();
  const handleOnClick = useCallback(
    () => navigate('/boi/administration/subcontractors', { replace: true }),
    [navigate]
  );

  const [listVisibility, setListVisibility] = useState<boolean>(true);
  const refreshListing = () => {
    setListVisibility(false);
    setTimeout(() => {
      setListVisibility(true);
    });
  };

  const formFields: FormField[] = [
    {
      name: 'name',
      type: 'text',
      label: 'Nom société',
      validation: Yup.string().required('Vous devez renseigner le nom'),
      size: 6,
      labelSize: 3,
      fieldSize: 9
    },
    {
      name: 'sage_code',
      type: 'text',
      label: 'Code société Sage',
      size: 6,
      labelSize: 4,
      fieldSize: 8
    },
    {
      name: 'action_type',
      label: 'Type',
      type: 'select-multiple',
      options: {
        values: [
          { value: 'CR', label: 'CR' },
          { value: 'MR', label: 'MR' }
        ]
      },
      size: 6,
      labelSize: 3,
      fieldSize: 9
    },
    {
      name: 'nb_max_users',
      label: "Nb d'utilisateurs max",
      type: 'number',
      size: 6,
      labelSize: 4,
      fieldSize: 8
    },
    {
      name: 'is_active',
      type: 'radio',
      label: 'Etat',
      options: {
        values: [
          { value: true, label: 'Actif' },
          { value: false, label: 'Inactif' }
        ]
      },
      size: 6,
      defaultValue: true,
      labelSize: 3,
      fieldSize: 9,
      permissions: ['IS_ACTIVE']
    }
  ];

  return (
    <>
      {action === 'add' && (
        <Item
          action="add"
          endpoint={endpoint}
          fields={formFields}
          name="Ajouter un sous traitant"
        />
      )}
      {action === 'edit' && (
        <>
          {listVisibility && (
            <Item
              action="edit"
              endpoint={endpoint}
              fields={formFields}
              name="Détails sous-traitant"
            />
          )}
          <Technicians />
          <ApplicablePricings />
          <AdditionalPricings />
          <WorkingHours
            onChange={() => {
              refreshListing();
            }}
          />
          <Box sx={{ textAlign: 'center' }}>
            <Button onClick={handleOnClick} variant="contained" sx={{ m: 2 }}>
              Retour à la liste
            </Button>
          </Box>
        </>
      )}
    </>
  );
};
export default SubcontractorsForm;
