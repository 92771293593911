import * as React from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import axios from 'axios';
import WeatherContitionsModal from './WeatherContitionsModal';
import {
  StyledTableBodyRow,
  StyledTableCell,
  StyledTableHeaderRow
} from '../../../../styles/Table.styled';
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface weatherDataProps {
  day: number;
  temperature: number;
  condition: string;
  condition_icon: any;
}

type ModuleDataProps = {
  module_address: string;
  contract: string;
  city: string;
  longitude: number;
  latitude: number;
  device_type: any;
  average_exclusive_module: any;
  contract_label: string;
  contract_exp_date: any;
  pdc_id: string | null;
  phone: string | null;
};

export default function WeatherConditions({ moduleData }: ModuleDataProps | any) {
  const [weatherConditions, setWeatherConditions] = useState<any>();
  const [fromDays, setFromDays] = useState<number>(-8);
  const [toDays, setToDays] = useState<number>(-1);
  const YESTERDAY = -1;

  const moveBack = () => {
    setFromDays(fromDays - 1);
    setToDays(toDays - 1);
  };
  const moveForward = () => {
    setFromDays(fromDays + 1);
    setToDays(toDays + 1);
  };

  useEffect(() => {
    if (moduleData) {
      const fetchData = async () => {
        await axios
          .get(
            '/api/boi/external/weather-conditions/?latitude=' +
              moduleData?.latitude +
              '&longitude=' +
              moduleData?.longitude +
              '&from_days=' +
              fromDays +
              '&to_days=' +
              toDays
          )
          .then((response) => {
            setWeatherConditions(response.data);
          });
      };
      fetchData();
    }
  }, [fromDays, toDays, moduleData]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <StyledTableHeaderRow>
              <StyledTableCell key="empty"></StyledTableCell>
              <StyledTableCell key="fromDays">
                <IconButton onClick={() => moveBack()}>
                  <ChevronLeftIcon fontSize="small" />
                </IconButton>
              </StyledTableCell>
              {weatherConditions &&
                weatherConditions.weather_conditions.map((column: any) => (
                  <StyledTableCell key={column.day} sx={{ textAlign: 'center' }}>
                    <strong>J{column?.day}</strong>
                    <WeatherContitionsModal day={column?.day} />
                  </StyledTableCell>
                ))}
              <StyledTableCell key="toDays">
                <IconButton disabled={toDays === YESTERDAY} onClick={() => moveForward()}>
                  <ChevronRightIcon fontSize="small" />
                </IconButton>
              </StyledTableCell>
            </StyledTableHeaderRow>
          </TableHead>
          <TableBody>
            <StyledTableBodyRow>
              <StyledTableCell key="temperature" colSpan={2}>
                <strong>Température</strong>
              </StyledTableCell>
              {weatherConditions &&
                weatherConditions.weather_conditions.map((row: weatherDataProps, index: number) => (
                  <StyledTableCell
                    key={row.day}
                    align="center"
                    sx={{ borderRight: index === 7 ? '0px !important' : null }}
                  >
                    {Number(row.temperature).toFixed(1)}°C
                  </StyledTableCell>
                ))}
              <StyledTableCell></StyledTableCell>
            </StyledTableBodyRow>
            <StyledTableBodyRow>
              <StyledTableCell key="weather" colSpan={2}>
                <strong>Temps</strong>
              </StyledTableCell>
              {weatherConditions &&
                weatherConditions.weather_conditions.map((row: weatherDataProps, index: number) => (
                  <StyledTableCell
                    key={row.day}
                    align="center"
                    sx={{ borderRight: index === 7 ? '0px !important' : null }}
                  >
                    <img
                      alt=""
                      src={row.condition_icon}
                      style={{ borderRadius: '15px', filter: 'brightness(1.2)' }}
                    />
                  </StyledTableCell>
                ))}
              <StyledTableCell></StyledTableCell>
            </StyledTableBodyRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
