import React from 'react';
import { ModuleData } from './CR.types';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

type DeviceMapProps = {
  moduleData: ModuleData;
};

const DeviceMap = ({ moduleData }: DeviceMapProps) => {
  const modulePosition: LatLngExpression = { lat: moduleData.latitude, lng: moduleData.longitude };

  const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <MapContainer
      center={modulePosition}
      zoom={13}
      style={{ height: '550px' }}
      scrollWheelZoom={false}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={modulePosition}></Marker>
    </MapContainer>
  );
};

export default DeviceMap;
