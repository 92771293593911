import React from 'react';
import { MapContainer, TileLayer, CircleMarker } from 'react-leaflet';
import { LatLngExpression, latLngBounds } from 'leaflet';
import { Box } from '@mui/material';

type MarkerData = {
  long: number;
  lat: number;
};

type CrStatisticsMapProps = {
  markers: MarkerData[];
};
const CrStatisticsMap = ({ markers }: CrStatisticsMapProps) => {
  const formatMarkers = (markers: MarkerData[]): LatLngExpression[] => {
    return markers.map((marker) => {
      return [marker.lat, marker.long];
    });
  };

  return (
    <Box sx={{ height: '400px', width: '100%', mt: 2 }}>
      <MapContainer
        zoom={6}
        style={{ height: '100%', width: '100wh' }}
        scrollWheelZoom={true}
        bounds={latLngBounds(formatMarkers(markers))}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {markers.map((marker, index) => {
          return (
            <CircleMarker
              key={'marker-' + index}
              center={[marker.lat, marker.long]}
              radius={10}
              pathOptions={{
                fillColor: '#636f8b',
                stroke: false,
                fillOpacity: 0.5
              }}
            ></CircleMarker>
          );
        })}
      </MapContainer>
    </Box>
  );
};

export default CrStatisticsMap;
