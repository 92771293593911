import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import '@applications-terrains/birdz-react-library/dist/esm/styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import * as Cookies from 'es-cookie';
import './i18n';
import { birdzReactLibrary } from '@applications-terrains/birdz-react-library';
import { SupportAuthService } from './components/Auth/SupportAuthService';
import * as Sentry from '@sentry/browser';

//eslint-disable-next-line
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://949e4d1672ed419d9d3b51946cce6ace@sentry.birdz.com/43',
    //eslint-disable-next-line
    environment: process.env.NODE_ENV
  });
}

axios.interceptors.response.use(
  (response) => {
    if (axios.isCancel(response)) {
      //eslint-disable-next-line
      return console.log(response);
    }
    // Do something with response data
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((config: any) => {
  config.headers['Accept'] = 'application/json';
  config.headers['Content-Type'] = 'application/json';
  const csrfToken = Cookies.get('csrftoken');
  if (csrfToken) {
    config.headers['X-CSRFToken'] = csrfToken;
  }
  return config;
});

const auth: any = new SupportAuthService();
birdzReactLibrary.authService = auth;
export const authService = birdzReactLibrary.authService;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
