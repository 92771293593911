import { Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { ModalItem } from '../DMSTypes';
import { authService } from '../../..';
import axios from 'axios';
import UploadIcon from '@mui/icons-material/Upload';
import {
  BirdzModal,
  BirdzModalActions,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';

type DMSModalUploadItemProps = {
  item: Partial<ModalItem>;
  onClose: (message?: string) => void;
  onError: (message: string) => void;
};

export const DMSModalUploadItem = ({ item, onClose, onError }: DMSModalUploadItemProps) => {
  const [newFile, setNewFile] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const uploadFile = () => {
    if (!authService.isAdminGED()) {
      return;
    }

    const readBinaryFile = (file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    setIsLoading(true);
    readBinaryFile(newFile).then((file: any) => {
      axios({
        method: 'PUT',
        url: `/api/boi/dms/upload-file/?parent_file_id=${item.id}`,
        headers: {
          'Content-Type': 'application/octet-stream',
          'Content-Disposition': `attachment; filename=${newFile.name}`
        },
        data: file
      })
        .then(() => {
          onClose(`Le fichier a été importé avec succès`);
        })
        .catch(() => {
          onError("Une erreur est survenue lors de l'import du fichier");
        })
        .finally(() => {
          setIsLoading(true);
        });
    });
  };

  return (
    <BirdzModal open={true}>
      <BirdzModalTitle>Ajouter un fichier</BirdzModalTitle>
      <BirdzModalContent>
        {!newFile ? (
          <Button id="select" variant="outlined" component="label" size="small">
            <UploadIcon sx={{ mr: 1 }} />
            Sélectionnez le fichier à importer
            <input
              hidden
              multiple
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const selectedFile = e.target.files?.length ? e.target.files[0] : null;
                setNewFile(selectedFile);
              }}
            />
          </Button>
        ) : (
          <>{newFile?.name}</>
        )}
      </BirdzModalContent>
      <BirdzModalActions>
        <Button id="cancelUpload" variant="outlined" onClick={() => onClose()}>
          Retour
        </Button>
        <Button
          id="confirmUpload"
          variant="contained"
          onClick={uploadFile}
          disabled={isLoading || !newFile}
        >
          {isLoading && <CircularProgress size={16} thickness={5} sx={{ mr: 1 }} />} Importer
        </Button>
      </BirdzModalActions>
    </BirdzModal>
  );
};
