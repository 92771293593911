import React, { useState } from 'react';
import { ModalItem } from '../DMSTypes';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { authService } from '../../..';
import axios from 'axios';
import {
  BirdzModal,
  BirdzModalActions,
  BirdzModalContent,
  BirdzModalTitle
} from '@applications-terrains/birdz-react-library';

type DMSModalRenameItemProps = {
  item: Partial<ModalItem>;
  onClose: (message?: string) => void;
  onError: (message: string) => void;
};

export const DMSModalRenameItem = ({ onClose, onError, item }: DMSModalRenameItemProps) => {
  const [itemName, setItemName] = useState<string>(item?.name || '');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const renameItem = () => {
    if (!authService.isAdminGED()) {
      onError("Vous n'avez pas les droits nécessaires pour renommer cet élément");
      return;
    }

    setIsLoading(true);
    axios
      .post('/api/boi/dms/rename-file-folder/', { file_id: item.id, new_name: itemName })
      .then(() => {
        onClose(`L'élément a été renommé avec succès`);
      })
      .catch(() => {
        onError("Une erreur est survenue lors du renommage de l'élément");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <BirdzModal open={true}>
      <BirdzModalTitle>Renommer le {item?.type === 'file' ? 'fichier' : 'dossier'}</BirdzModalTitle>
      <BirdzModalContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            id="name"
            focused
            label={item?.type === 'file' ? 'Nom du fichier' : 'Nom du dossier'}
            defaultValue={item.name}
            onChange={(value: any) => {
              setItemName(value.target.value);
            }}
            onKeyPress={(e: any) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            fullWidth
          />
        </Box>
      </BirdzModalContent>
      <BirdzModalActions>
        <Button id="close" variant="outlined" onClick={() => onClose()}>
          Retour
        </Button>
        <Button
          id="rename"
          variant="contained"
          onClick={renameItem}
          disabled={!itemName || isLoading}
        >
          {isLoading && <CircularProgress size={16} thickness={5} sx={{ mr: 1 }} />} Renommer
        </Button>
      </BirdzModalActions>
    </BirdzModal>
  );
};
