import axios from 'axios';
import { CrCaseValues } from './Types';

class CrCaseService {
  createCase(deviceId: string): Promise<CrCaseValues> {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/boi/cases/cr-cases/', {
          device_id: deviceId
        })
        .then((response) => {
          const data: CrCaseValues = response.data;
          // notificationService.show({
          //   type: 'success',
          //   message: 'Le dossier a été créé avec succès'
          // });
          resolve(data);
        })
        .catch(() => {
          // notificationService.show({
          //   type: 'error',
          //   message: 'Une erreur est survenue lors de la création du dossier'
          // });
          reject();
        });
    });
  }
}

const crCaseService = new CrCaseService();
export default crCaseService;
