import React from 'react';
import { BirdzTitle, InputField, SelectField } from '@applications-terrains/birdz-react-library';
import { Box, Grid } from '@mui/material';
import { ExportFormatSettingsMapping } from './ExportFormatSettingsMapping';

export const ExportFormatSettingsDate = () => {
  return (
    <>
      <BirdzTitle>Transformation</BirdzTitle>
      <Grid container alignItems="center" spacing={1}>
        <Grid alignItems={'center'} item xs={4}>
          Forcer l'heure
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <InputField
            type="time"
            name="date_forced_time"
            size="small"
            inputProps={{
              inputFormat: 'HH:mm:ss'
            }}
          />
        </Grid>
        <Grid alignItems={'center'} item xs={4}>
          Opération
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <Box sx={{ display: 'flex', gap: '30px' }}>
            <SelectField
              name="date_operation_type"
              options={[
                { value: 'ADD', label: 'Ajouter' },
                { value: 'REMOVE', label: 'Retirer' }
              ]}
              fullWidth
            />
            <InputField
              name="date_operation_count"
              size="small"
              type="number"
              sx={{ width: '200px' }}
            />
            <SelectField
              name="date_operation_unit"
              options={[
                { value: 'HOUR', label: 'Heure' },
                { value: 'DAY', label: 'Jour' },
                { value: 'MONTH', label: 'Mois' },
                { value: 'YEAR', label: 'Année' }
              ]}
              fullWidth
            />
          </Box>
        </Grid>
      </Grid>

      <BirdzTitle>Format</BirdzTitle>
      <Grid container alignItems="center" spacing={1}>
        <Grid alignItems={'center'} item xs={4}>
          Format
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <SelectField
            name="date_format"
            options={[
              { value: 'AAAAMMJJ', label: 'AAAAMMJJ' },
              { value: 'JJ/MM/AAAA', label: 'JJ/MM/AAAA' },
              { value: 'AAAA-MM-JJ', label: 'AAAA-MM-JJ' },
              { value: 'AAAA-MM-JJ HH:MI:SS', label: 'AAAA-MM-JJ HH:MI:SS' },
              { value: 'AAAA-MM-JJTHH:MI:SS', label: 'AAAA-MM-JJTHH:MI:SS' }
            ]}
          />
        </Grid>
      </Grid>

      <ExportFormatSettingsMapping />
    </>
  );
};
