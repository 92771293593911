import React from 'react';
import { BirdzTitle, InputField } from '@applications-terrains/birdz-react-library';
import { Grid } from '@mui/material';

export const ExportFormatSettingsBoolean = () => {
  return (
    <>
      <BirdzTitle>Format</BirdzTitle>
      <Grid container alignItems="center" spacing={1}>
        <Grid alignItems={'center'} item xs={4}>
          Valeur si vrai
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <InputField name="bool_if_true" size="small" />
        </Grid>
        <Grid alignItems={'center'} item xs={4}>
          Valeur si faux
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <InputField name="bool_if_false" size="small" />
        </Grid>
        <Grid alignItems={'center'} item xs={4}>
          Valeur si null
        </Grid>
        <Grid alignItems={'center'} item xs={8}>
          <InputField name="bool_if_null" size="small" />
        </Grid>
      </Grid>
    </>
  );
};
