import { Button } from '@mui/material';
import React, { useState } from 'react';
import FileDownload from '@mui/icons-material/FileDownload';

const DndOrImport = ({ setNewFile }: { setNewFile: (a: File) => void }) => {
  const [dragOver, setDragOver] = useState<boolean>(false);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setNewFile(droppedFile);
    setDragOver(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragOver(false);
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onMouseLeave={handleDragLeave}
      className={`dnd mb-1 ${dragOver ? 'drag-over' : ''}`}
    >
      <Button
        id="select"
        variant="outlined"
        component="label"
        size="small"
        className="select-file-button"
      >
        <FileDownload sx={{ mr: 1 }} />
        Sélectionner le fichier
        <input
          hidden
          // multiple
          type="file"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const selectedFile = e.target.files?.length ? e.target.files[0] : null;
            setNewFile(selectedFile as File);
          }}
        />
      </Button>
      <p>ou</p>
      glisser / déposer ici
    </div>
  );
};

export default DndOrImport;
