import React from 'react';
import { DMSModalCreateFolder } from './DMSModalCreateFolder';
import { ModalAction, ModalItem } from '../DMSTypes';
import { DMSModalPermissions } from './DMSModalPermissions';
import { DMSModalDeleteItem } from './DMSModalDeleteItem';
import { DMSModalRenameItem } from './DMSModalRenameItem';
import { DMSModalInformationsItem } from './DMSModalInformationsItem';
import { DMSModalUploadItem } from './DMSModalUploadItem';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';

type DMSModalProps = {
  item: Partial<ModalItem>;
  action: ModalAction;
  onClose: () => void;
};

const DMSModal = ({ onClose, action, item }: DMSModalProps) => {
  const { notif, notifOptions } = useNotif();

  const handleClose = (content?: string) => {
    if (content) {
      notif({ type: 'success', content });
    }
    onClose();
  };

  const handleError = (error: string) => {
    if (error) {
      notif({ type: 'error', content: error });
    }
  };

  return (
    <>
      {action === 'createFolder' && (
        <DMSModalCreateFolder item={item} onClose={handleClose} onError={handleError} />
      )}
      {action === 'permissions' && (
        <DMSModalPermissions
          item={{ ...item, ...{ checkedItems: [] } }}
          onClose={handleClose}
          onError={handleError}
        />
      )}
      {action === 'multiplePermissions' && (
        <DMSModalPermissions item={item} onClose={handleClose} onError={handleError} />
      )}
      {action === 'delete' && (
        <DMSModalDeleteItem item={item} onClose={handleClose} onError={handleError} />
      )}
      {action === 'rename' && (
        <DMSModalRenameItem item={item} onClose={handleClose} onError={handleError} />
      )}
      {action === 'infos' && <DMSModalInformationsItem item={item} onClose={handleClose} />}
      {action === 'uploadFile' && (
        <DMSModalUploadItem item={item} onClose={handleClose} onError={handleError} />
      )}
      <BirdzNotif options={notifOptions} />
    </>
  );
};

export default DMSModal;
