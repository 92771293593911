//This component represents a file in the DMS
//It includes a checkbox, pretty icon depending on the file's mimeType, a name and
//five buttons that call the DMSModal component to execute the following operations :
//Display file details, manage reading rights on the file, download file, rename file, delete file.
import React, { useState } from 'react';

import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { DMSActions } from './DMS.styled';
import InfoIcon from '@mui/icons-material/Info';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import {
  PiFilePpt,
  PiFileJpg,
  PiFilePdf,
  PiFilePng,
  PiFile,
  PiFileXls,
  PiFileDoc
} from 'react-icons/pi';
import { DMSFileProps, ModalAction } from './DMSTypes';
import { authService } from '../..';
import DMSModal from './DMSModal/DMSModal';

//Found on stackoverflow -> https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

const DMSFile = (file: DMSFileProps) => {
  const [checked, setChecked] = useState(false);
  const [hidden, setHidden] = useState(false); //This state is set to true when the file is deleted.
  //The file still exists but isn't displayed until the page or the parent folder is reloaded
  const [isHover, setIsHover] = useState(false);
  const [modalAction, setModalAction] = useState<ModalAction>('');
  const [downloading, setDownloading] = useState(false);
  const [currentName, setCurrentName] = useState(file.name); //The current name of the folder can differ from the name retrieved from database
  //Useful when the folder is renamed but not reloaded yet.

  const updateName = () => {
    axios.get('/api/boi/dms/file/?file_id=' + file.id).then((response: any) => {
      const data = response.data;
      setCurrentName(data.name);
      return data;
    });
  };

  const downloadFile = () => {
    let endpoint;
    if (authService.isAdminGED()) {
      endpoint = `/api/boi/dms/download-file/`;
    } else if (authService.isViewerGED()) {
      endpoint = `/api/boe/dms/download-file/`;
    }

    if (endpoint) {
      setDownloading(true);
      axios({
        //Found on stackoverflow -> https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
        url: endpoint + '?file_id=' + file.id,
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name); //or any other extension
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
      });
    }
  };

  const fileIcon = () => {
    //Displays pretty file icon depending on the files mime type
    switch (file.mimeType) {
      case 'image/jpeg':
        return <PiFileJpg />;
      case 'image/png':
        return <PiFilePng />;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return <PiFilePpt />;
      case 'application/pdf':
        return <PiFilePdf />;
      case 'application/octet-stream':
        return <PiFile />;
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return <PiFileXls />;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
        return <PiFileDoc />;
      default:
        return <PiFile />;
    }
  };

  return (
    <>
      {!hidden && (
        <Paper
          sx={{ p: 2, position: 'relative' }}
          elevation={isHover ? 2 : 0}
          style={isHover ? { background: '#F2F2F2' } : {}}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          square
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              onClick={() => {
                if (file.readOnly === false) {
                  setChecked(!checked);
                  file.checkFile(file.id, file.name + ' ', !checked);
                }
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ display: 'flex', height: 12 }}
              >
                <Box>
                  {file.readOnly === false && (
                    <Checkbox id={'file' + file.id} checked={checked} readOnly sx={{ p: 0 }} />
                  )}
                  <Box sx={{ ml: 2, mr: 2, display: 'inline' }}>{fileIcon()}</Box>
                  {currentName}
                </Box>

                {!isHover && <Box>{moment(file.created_at).format('DD/MM/YYYY à HH[h]mm')}</Box>}

                {isHover && (
                  <Box>
                    <DMSActions>
                      <Tooltip title="Informations">
                        <IconButton
                          id="info"
                          onClick={(e) => {
                            e.stopPropagation();
                            setModalAction('infos');
                          }}
                        >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      {file.readOnly === false && (
                        <Tooltip title="Droits">
                          <IconButton
                            id="rights"
                            onClick={(e) => {
                              e.stopPropagation();
                              setModalAction('permissions');
                            }}
                          >
                            <AccountCircleIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Télécharger">
                        <IconButton
                          id="download"
                          onClick={(e) => {
                            e.stopPropagation();
                            downloadFile();
                          }}
                        >
                          {downloading ? (
                            <CircularProgress size={18} thickness={5} />
                          ) : (
                            <DownloadIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>
                      {file.readOnly === false && (
                        <>
                          <Tooltip title="Renommer">
                            <IconButton
                              id="rename"
                              onClick={(e) => {
                                e.stopPropagation();
                                setModalAction('rename');
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Supprimer">
                            <IconButton
                              id="delete"
                              onClick={(e) => {
                                e.stopPropagation();
                                setModalAction('delete');
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </DMSActions>
                  </Box>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      )}

      <DMSModal
        action={modalAction}
        onClose={() => {
          setModalAction('');
          if (authService.isAdminGED()) {
            updateName();
          }
        }}
        item={{
          id: file.id,
          name: currentName,
          type: 'file',
          meta: {
            created_by: file.created_by,
            created_at: file.created_at,
            size: humanFileSize(file.size),
            mimeType: file.mimeType
          },
          userList: file.users,
          onDelete: () => setHidden(true)
        }}
      />
    </>
  );
};

export default DMSFile;
