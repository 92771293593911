import {
  AppPaper,
  BirdzTitle,
  SearchField,
  SearchForm
} from '@applications-terrains/birdz-react-library';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import CrStatisticsPie from '../../CR/Statistics/CrStatisticsPie';
import axios from 'axios';
import { useGetBConnectContracts, useGetCities } from '../../../hooks/datarefs';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import {
  AnomalyStatusProps,
  CompanyProps,
  ExportStatusProps,
  InterventionStatusProps,
  LineChartProps,
  StatPerDayProps,
  StatsProps,
  ValidationStatusProps
} from './types';

export default function Dashboard() {
  const [stats, setStats] = useState<StatsProps>();
  const [exportStatus, setExportStatus] = useState<ExportStatusProps>();
  const [validationStatus, setValidationStatus] = useState<ValidationStatusProps>();
  const [anomalyStatus, setAnomalyStatus] = useState<AnomalyStatusProps>();
  const [interventionStatus, setInterventionStatus] = useState<InterventionStatusProps>();
  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const [searchFields, setSearchFields] = useState<SearchField[]>([]);
  const [filterString, setFilterString] = useState<string>('');
  const [dataForLineChart, setDataForLineChart] = useState<LineChartProps[]>([]);

  const { data: cities } = useGetCities();
  const { data: contracts } = useGetBConnectContracts();

  const fetchDataForLineChart = async (filter: string) => {
    let data: StatPerDayProps = {};
    await axios.get('/api/bconnect/interventions-stat-per-day/?' + filter).then((response) => {
      return (data = response.data);
    });

    const result: LineChartProps[] = Object.entries(data).map(([name, values]) => ({
      name: name.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1'),
      done: values.done,
      new: values.new,
      in_progress: values.in_progress,
      impossible: values.impossible,
      expired: values.expired
    }));

    const sortedData = result.sort((a, b) => {
      const dateA = Date.parse(`${a.name.split('/').reverse().join('-')} 00:00:00`);
      const dateB = Date.parse(`${b.name.split('/').reverse().join('-')} 00:00:00`);
      return dateA - dateB;
    });

    setDataForLineChart(sortedData);
  };

  const fetchInterventionStatuses = async (filter: string) => {
    const { data } = await axios.get('/api/bconnect/interventions-status/?' + filter);
    setInterventionStatus(data);
  };

  const fetchStatistics = async (filter: string) => {
    const { data } = await axios.get('/api/boi/extractor/interventions-stat/?' + filter);
    setStats(data);
  };

  const fetchCompanies = async () => {
    const { data } = await axios.get('/api/boi/extractor/clients');
    const companies = data.results;
    setCompanies(companies);
  };

  const formatData = (data: Record<string, number>) => {
    if (data) {
      return Object.keys(data).map((value: string) => {
        const property = value as
          | 'in_anomaly'
          | 'corrected'
          | 'without_anomaly'
          | 'to_validate'
          | 'validated'
          | 'exported'
          | 'not exported'
          | 'new'
          | 'in_progress'
          | 'done'
          | 'impossible'
          | 'expired';

        switch (property) {
          case 'new':
            return { name: 'Créées', value: data[property] };
          case 'in_progress':
            return { name: 'En cours', value: data[property] };
          case 'done':
            return { name: 'Terminées', value: data[property] };
          case 'impossible':
            return { name: 'Impossibles', value: data[property] };
          case 'expired':
            return { name: 'Expirées', value: data[property] };
          case 'without_anomaly':
            return { name: 'Sans anomalie', value: data[property] };
          case 'in_anomaly':
            return { name: 'En anomalie', value: data[property] };
          case 'corrected':
            return { name: 'Corrigées', value: data[property] };
          case 'to_validate':
            return { name: 'A valider', value: data[property] };
          case 'validated':
            return { name: 'Validées', value: data[property] };
          case 'exported':
            return { name: 'Exportées', value: data[property] };
          case 'not exported':
            return { name: 'Non exportées', value: data[property] };
          default:
            return { name: property, value: data[property] };
        }
      });
    } else {
      return [];
    }
  };

  const displayDataInArray = (data: Record<string, number>) => {
    if (data) {
      return (
        <Box sx={{ height: 332 }}>
          <TableContainer component={Paper} sx={{ pt: '100px' }}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                {Object.keys(data).map((value: string) => {
                  const property = value as
                    | 'in_anomaly'
                    | 'corrected'
                    | 'without_anomaly'
                    | 'to_validate'
                    | 'validated'
                    | 'exported'
                    | 'not exported'
                    | 'new'
                    | 'in_progress'
                    | 'done'
                    | 'impossible'
                    | 'expired';

                  switch (property) {
                    case 'in_anomaly':
                      return (
                        <TableRow key={property}>
                          <TableCell align="center" sx={{ backgroundColor: 'grey' }}>
                            En anomalie
                          </TableCell>
                          <TableCell align="center">{data[property]}</TableCell>
                        </TableRow>
                      );
                    case 'corrected':
                      return (
                        <TableRow key={property}>
                          <TableCell align="center" sx={{ backgroundColor: 'grey' }}>
                            Corrigées
                          </TableCell>
                          <TableCell align="center">{data[property]}</TableCell>
                        </TableRow>
                      );
                    case 'without_anomaly':
                      return (
                        <TableRow key={property}>
                          <TableCell align="center" sx={{ backgroundColor: 'grey' }}>
                            Sans anomalie
                          </TableCell>
                          <TableCell align="center">{data[property]}</TableCell>
                        </TableRow>
                      );
                    case 'to_validate':
                      return (
                        <TableRow key={property}>
                          <TableCell align="center" sx={{ backgroundColor: 'grey' }}>
                            A valider
                          </TableCell>
                          <TableCell align="center">{data[property]}</TableCell>
                        </TableRow>
                      );
                    case 'validated':
                      return (
                        <TableRow key={property}>
                          <TableCell align="center" sx={{ backgroundColor: 'grey' }}>
                            Validées
                          </TableCell>
                          <TableCell align="center">{data[property]}</TableCell>
                        </TableRow>
                      );
                    case 'exported':
                      return (
                        <TableRow key={property}>
                          <TableCell align="center" sx={{ backgroundColor: 'grey' }}>
                            Exportées
                          </TableCell>
                          <TableCell align="center">{data[property]}</TableCell>
                        </TableRow>
                      );
                    case 'not exported':
                      return (
                        <TableRow key={property}>
                          <TableCell align="center" sx={{ backgroundColor: 'grey' }}>
                            Non exportées
                          </TableCell>
                          <TableCell align="center">{data[property]}</TableCell>
                        </TableRow>
                      );
                    case 'new':
                      return (
                        <TableRow key={property}>
                          <TableCell align="center" sx={{ backgroundColor: 'grey', width: '50%' }}>
                            Créées
                          </TableCell>
                          <TableCell align="center">{data[property]}</TableCell>
                        </TableRow>
                      );
                    case 'in_progress':
                      return (
                        <TableRow key={property}>
                          <TableCell align="center" sx={{ backgroundColor: 'grey' }}>
                            En cours
                          </TableCell>
                          <TableCell align="center">{data[property]}</TableCell>
                        </TableRow>
                      );
                    case 'done':
                      return (
                        <TableRow key={property}>
                          <TableCell align="center" sx={{ backgroundColor: 'grey' }}>
                            Terminées
                          </TableCell>
                          <TableCell align="center">{data[property]}</TableCell>
                        </TableRow>
                      );
                    case 'impossible':
                      return (
                        <TableRow key={property}>
                          <TableCell align="center" sx={{ backgroundColor: 'grey' }}>
                            Impossibles
                          </TableCell>
                          <TableCell align="center">{data[property]}</TableCell>
                        </TableRow>
                      );
                    case 'expired':
                      return (
                        <TableRow key={property}>
                          <TableCell align="center" sx={{ backgroundColor: 'grey' }}>
                            Expirées
                          </TableCell>
                          <TableCell align="center">{data[property]}</TableCell>
                        </TableRow>
                      );
                    default:
                      return (
                        <TableRow key={property}>
                          <TableCell align="center" sx={{ backgroundColor: 'grey' }}>
                            {property}
                          </TableCell>
                          <TableCell align="center">{data[property]}</TableCell>
                        </TableRow>
                      );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
    } else {
      return [];
    }
  };

  //load the data
  useEffect(() => {
    fetchInterventionStatuses('');
    fetchStatistics('');
    fetchCompanies();
    fetchDataForLineChart('');
  }, []);

  //handle filters from searchFields
  useEffect(() => {
    const filter = filterString.substring(1);
    fetchStatistics(filter);
    fetchInterventionStatuses(filter);
    fetchDataForLineChart(filter);
  }, [filterString]);

  useEffect(() => {
    if (stats) {
      const exportStatus = stats?.['export status'];
      setExportStatus(exportStatus);

      const validationStatus = stats?.['validation status'];
      setValidationStatus(validationStatus);

      const anomalyStatus = stats['anomaly status'];
      setAnomalyStatus(anomalyStatus);
    } else {
      return;
    }
  }, [stats]);

  useEffect(() => {
    const searchFields: SearchField[] = [
      {
        name: 'client',
        label: 'Client',
        type: 'select',
        options: {
          identifier: 'value',
          label: 'label',
          values: companies?.map((company) => {
            return { value: company.id, label: company.name };
          })
        }
      },
      {
        name: 'contract_id',
        label: 'Contrat',
        type: 'select',
        options: {
          values: contracts
            ? contracts?.map((contract) => {
                return {
                  value: contract.contract_id,
                  label: contract.contract_name
                };
              })
            : []
        }
      },
      {
        name: 'created_at_after',
        label: 'Créées entre le',
        type: 'datepicker'
      },
      {
        name: 'created_at_before',
        label: 'et le',
        type: 'datepicker'
      },
      {
        name: 'updated_at_after',
        label: 'Mises à jour entre le',
        type: 'datepicker'
      },
      {
        name: 'updated_at_before',
        label: 'et le',
        type: 'datepicker'
      },
      {
        name: 'closing_at_after',
        label: 'Réalisées entre le',
        type: 'datepicker'
      },
      {
        name: 'closing_at_before',
        label: 'et le',
        type: 'datepicker'
      },
      {
        name: 'city',
        label: 'Commune',
        type: 'select',
        options: {
          values:
            cities && cities.length
              ? cities.map((city) => {
                  return {
                    value: city,
                    label: city
                  };
                })
              : []
        }
      }
    ];

    setSearchFields(searchFields);
  }, [companies]);

  return (
    <AppPaper>
      <BirdzTitle>Tableau de bord des interventions</BirdzTitle>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <strong>Sélection des données</strong>
        </AccordionSummary>
        <AccordionDetails>
          <SearchForm
            fields={searchFields}
            onSubmit={(filterString) => {
              setFilterString(filterString);
            }}
          />
        </AccordionDetails>
      </Accordion>

      <Grid container sx={{ mt: 2, justifyContent: 'center' }} spacing={1}>
        <Grid item xs={6} sx={{ textAlign: 'center' }}>
          <AppPaper>
            <Box sx={{ mt: 1 }}>
              <strong>Statuts d'intervention</strong>
            </Box>
            {interventionStatus && (
              <>
                <CrStatisticsPie
                  data={formatData(interventionStatus)}
                  displayMode="values"
                  verticalAlign="bottom"
                  align="center"
                  height={50}
                />
                {displayDataInArray(interventionStatus)}
              </>
            )}
          </AppPaper>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'center' }}>
          <AppPaper>
            <Box sx={{ mt: 1 }}>
              <strong>Statuts d'anomalie</strong>
            </Box>
            {anomalyStatus && (
              <>
                <CrStatisticsPie
                  data={formatData(anomalyStatus)}
                  displayMode="values"
                  verticalAlign="bottom"
                  align="center"
                  height={50}
                />
                {displayDataInArray(anomalyStatus)}
              </>
            )}
          </AppPaper>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'center' }}>
          <AppPaper>
            <Box sx={{ mt: 1 }}>
              <strong>Statuts de validation</strong>
            </Box>
            {validationStatus && (
              <>
                <CrStatisticsPie
                  data={formatData(validationStatus)}
                  displayMode="values"
                  verticalAlign="bottom"
                  align="center"
                  height={50}
                />
                {displayDataInArray(validationStatus)}
              </>
            )}
          </AppPaper>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'center' }}>
          <AppPaper>
            <Box sx={{ mt: 1 }}>
              <strong>Statuts d'export</strong>
            </Box>
            {exportStatus && (
              <>
                <CrStatisticsPie
                  data={formatData(exportStatus)}
                  displayMode="values"
                  verticalAlign="bottom"
                  align="center"
                  height={50}
                />
                {displayDataInArray(exportStatus)}
              </>
            )}
          </AppPaper>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <AppPaper>
          <Box sx={{ mt: 2 }}>
            <strong>Histogramme</strong>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            <LineChart
              width={950}
              height={400}
              data={dataForLineChart}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="new" name="Créées" stroke="#95CDE8" />
              <Line type="monotone" dataKey="in_progress" name="En cours" stroke="#8884d8" />
              <Line type="monotone" dataKey="done" name="Terminées" stroke="#7ED17C" />
              <Line type="monotone" dataKey="impossible" name="Impossible" stroke="#F7A677" />
              <Line type="monotone" dataKey="expired" name="Expirées" stroke="#FF5338" />
            </LineChart>
          </Box>
        </AppPaper>
      </Grid>
    </AppPaper>
  );
}
