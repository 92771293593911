import React, { useState } from 'react';
import { ListPage, AppPaper, BirdzTitle } from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import { LogsDetail } from './LogDetail';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';

type Log = {
  id: number;
  date: any;
  ip: string;
  message: string;
  type: number;
  type_label: string;
  user_agent: string;
  user__name: string;
};

const Logs = (params: any) => {
  const endpoint = '/api/boi/administration/activity-logs/';
  const [logDetail, setLogDetail] = useState<any>();

  const listFields = [
    { name: 'id', label: '#', options: { width: '30px' } },
    {
      name: 'date',
      label: 'Date',
      orderable: true,
      transform: (value: any) => {
        return (value && moment(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
      },
      options: { width: '150px' }
    },
    {
      name: 'user__name',
      label: 'Utilisateur',
      className: 'text-center',
      options: { width: '200px' },
      orderable: true
    },
    {
      name: 'type_label',
      label: 'Type',
      className: 'text-center',
      options: { width: '150px' }
    },
    {
      name: 'message',
      label: 'Message',
      className: 'text-center'
    }
  ];

  const searchFields = [
    {
      name: 'user',
      id: 'search_by_user',
      label: 'Utilisateur',
      options: {
        identifier: 'id',
        source: '/api/boi/administration/users/?is_active=true&',
        label: 'name',
        searchIsMulti: false
      },
      className: 'col-sm-6'
    },
    {
      name: 'type',
      id: 'search_by_type',
      label: 'Type',
      type: 'select-multiple',
      options: {
        values: [
          { value: 1, label: 'Connexion' },
          { value: 2, label: 'INFO' },
          { value: 3, label: 'WARNING' },
          { value: 4, label: 'ERROR' }
        ]
      },
      className: 'col-sm-6'
    },
    {
      name: 'date_after',
      id: 'search_by_date_after',
      label: 'Entre le ',
      type: 'datepicker',
      className: 'col-sm-6'
    },
    {
      name: 'date_before',
      id: 'search_by_date_before',
      label: 'et le',
      type: 'datepicker',
      className: 'col-sm-6'
    }
  ];

  const actions = [
    {
      name: 'detail',
      label: 'Détail',
      render: (logDetail: Log) => {
        return (
          <IconButton
            onClick={() => {
              setLogDetail(logDetail);
            }}
          >
            <SearchIcon fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  return (
    <AppPaper>
      {params.action === 'list' && (
        <>
          <BirdzTitle>Liste des logs</BirdzTitle>
          <ListPage
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
          />
        </>
      )}
      {logDetail && (
        <LogsDetail
          logItem={logDetail}
          onClose={() => {
            setLogDetail(null);
          }}
        />
      )}
    </AppPaper>
  );
};
export default Logs;
