import { Box, Dialog } from '@mui/material';
import styled from 'styled-components';

export const DMSActions = styled(Box)`
  position: "absolute",
  right: 0,
  top: 5,
  marginLeft:"auto"
`;

export const DMSDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 80%;
    maxheight: 435;
  }
`;
