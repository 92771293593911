import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import './DashboardLayout.scss';
import moment from 'moment';
import { Box } from '@mui/material';
import routes from '../../routes';
import { Breadcrumbs } from '@applications-terrains/birdz-react-library';
import { authService } from '../..';
// import WaterDrop from '../EasterEgg/WaterDrop/WaterDrop';

const DashboardLayout = ({ component, ...props }: any) => {
  const { ...rest } = props;
  // const [showWaterDrop, setShowWaterDrop] = useState(false);
  // let sequence = ''; // Not use state to prevent rerenders at each typing

  // useEffect(() => {
  //   const handleKeyDown = (event: KeyboardEvent) => {
  //     sequence += event.key;
  //     // Store only last 10 characters
  //     sequence = sequence.length > 10 ? sequence.slice(-10) : sequence;
  //     if (sequence.slice(-4) === 'leak') {
  //       setShowWaterDrop(true);
  //     } else {
  //       setShowWaterDrop(false);
  //     }
  //   };

  //   document.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  return (
    <div className={`wrapper ${authService.BOIorBOE('boi', 'boe')}`}>
      {/* {showWaterDrop && <WaterDrop />} */}
      <Sidebar {...rest} />

      <div className="main-panel">
        <Box className="navbar">
          {authService.user?.avatar && (
            <div
              className="avatar"
              style={{
                backgroundImage: 'url("' + authService.user.avatar + '"'
              }}
            />
          )}
          {authService.user ? authService.user.name : ''}
        </Box>

        {authService.user?.previous_login && (
          <span className="last-login">
            Dernière connexion le{' '}
            {moment(authService.user.previous_login).format('DD/MM/YYYY à HH:mm:ss')}
          </span>
        )}

        <Breadcrumbs routes={routes} />

        <div className="page-content">
          <div className="inner">{component}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
