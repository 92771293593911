import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AppPaper } from '@applications-terrains/birdz-react-library';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Chip,
  Grid,
  LinearProgress,
  Paper,
  Stack
} from '@mui/material';
import CrStatisticsPie from './CrStatisticsPie';
import CrStatisticsMap from './CrStatisticsMap';
import ExpandMore from '@mui/icons-material/ExpandMore';

type CrStatisticsDetailProps = {
  filter: string;
};

type Statistic = {
  odm_in_progress: number;
  all_odm: number;
  odm_replaced: number;
  odm_waiting_csm: number;
  delay_inter: number;
  delay_breakdown: number;
  exclusive_modules: number;
  code_contracts: Record<string, number>;
  device_power_supply_types: Record<string, number>;
  device_patrimony_types: Record<string, number>;
  coordinates: Coordinates[];
  failure_codes: Record<string, number>;
  types: Record<string, number>;
};

type Coordinates = {
  long: number;
  lat: number;
};

export default function CrStatisticsDetail({ filter }: CrStatisticsDetailProps) {
  const [statistics, setStatistics] = useState<Statistic>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const formatData = (data: Record<string, number>) => {
    if (data) {
      return Object.keys(data).map((property: string) => {
        return { name: property, value: data[property] };
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    const filterString = filter.substring(1);

    const fetchStatistics = async () => {
      setLoading(true);
      await axios.get(`/api/boi/cases/cr-statistics/?` + filterString).then(
        (response) => {
          setLoading(false);
          const data = response.data;
          setStatistics(data);
        },
        () => {
          setStatistics(undefined);
          setLoading(false);
        }
      );
    };

    fetchStatistics();
  }, [filter]);

  return isLoading ? (
    <LinearProgress sx={{ mt: 2 }} />
  ) : statistics ? (
    <>
      <Grid item sx={{ mt: 2 }}></Grid>

      <Stack spacing={2} direction="row" justifyContent={'center'} sx={{ mt: 2 }}>
        <Chip
          label={
            <>
              ODM en cours : <strong>{statistics?.odm_in_progress}</strong>
            </>
          }
          variant="outlined"
        />
        <Chip
          label={
            <>
              Nombre total d'ODM : <strong>{statistics?.all_odm}</strong>
            </>
          }
          variant="outlined"
        />
        <Chip
          label={
            <>
              ODM avec remplacement de CR : <strong>{statistics?.odm_replaced}</strong>
            </>
          }
          variant="outlined"
        />
      </Stack>
      <Stack spacing={2} direction="row" justifyContent={'center'} sx={{ mt: 1 }}>
        <Chip
          label={
            <>
              En attente du Front Office : <strong>{statistics?.odm_waiting_csm}</strong>
            </>
          }
          variant="outlined"
        />
        <Chip
          label={
            <>
              Délai moyen des interventions :{' '}
              <strong>{Number(statistics?.delay_inter?.toFixed(2))}</strong>
            </>
          }
          variant="outlined"
        />
        <Chip
          label={
            <>
              Délai moyen des pannes :{' '}
              <strong>{Number(statistics?.delay_breakdown?.toFixed(2))}</strong>
            </>
          }
          variant="outlined"
        />
        <Chip
          label={
            <>
              Module exclusifs impactés : <strong>{statistics?.exclusive_modules}</strong>
            </>
          }
          variant="outlined"
        />
      </Stack>

      <Paper variant="outlined" square sx={{ mt: 2 }}>
        <Accordion defaultExpanded={true} sx={{ boxShadow: 'none' }} disableGutters={true}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <h3>Statistiques</h3>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container sx={{ mt: 2, justifyContent: 'center' }} spacing={1}>
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                <AppPaper>
                  <Box sx={{ paddingTop: 2 }}>
                    <strong>Contrats</strong>
                  </Box>
                  <CrStatisticsPie
                    data={formatData(statistics.code_contracts)}
                    displayMode="values"
                    verticalAlign="middle"
                    align="right"
                  />
                </AppPaper>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                <AppPaper>
                  <Box sx={{ paddingTop: 2 }}>
                    <strong>Codes panne</strong>
                  </Box>
                  <CrStatisticsPie
                    data={formatData(statistics.failure_codes)}
                    displayMode="percent"
                    verticalAlign="middle"
                    align="right"
                  />
                </AppPaper>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                <AppPaper>
                  <Box sx={{ paddingTop: 2 }}>
                    <strong>Types</strong>
                  </Box>
                  <CrStatisticsPie
                    data={formatData(statistics.types)}
                    displayMode="values"
                    verticalAlign="middle"
                    align="right"
                  />
                </AppPaper>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                <AppPaper>
                  <Box sx={{ paddingTop: 2 }}>
                    <strong>Types d'alimentation</strong>
                  </Box>
                  <CrStatisticsPie
                    data={formatData(statistics.device_power_supply_types)}
                    displayMode="percent"
                    verticalAlign="middle"
                    align="right"
                  />
                </AppPaper>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                <AppPaper>
                  <Box sx={{ paddingTop: 2 }}>
                    <strong>Types de devices</strong>
                  </Box>
                  <CrStatisticsPie
                    data={formatData(statistics.device_patrimony_types)}
                    displayMode="values"
                    verticalAlign="middle"
                    align="right"
                  />
                </AppPaper>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Paper variant="outlined" square sx={{ mt: 2 }}>
        <Accordion defaultExpanded={true} sx={{ boxShadow: 'none' }} disableGutters={true}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <h3>Cartographie</h3>
          </AccordionSummary>
          <AccordionDetails>
            <CrStatisticsMap markers={statistics.coordinates} />
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  ) : (
    <>
      <Grid item sx={{ mt: 2 }}></Grid>
      <Alert severity="info" sx={{ mt: 2 }}>
        Aucune statistique pour les filtres sélectionnés
      </Alert>
    </>
  );
}
