import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import packageJson from '../../../package.json';
import './Sidebar.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import MenuIcon from '@mui/icons-material/Menu';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CallIcon from '@mui/icons-material/Call';
import BuildIcon from '@mui/icons-material/Build';
import FolderIcon from '@mui/icons-material/Folder';
import MapIcon from '@mui/icons-material/Map';
import InfoIcon from '@mui/icons-material/Info';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import SettingsIcon from '@mui/icons-material/Settings';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MediationIcon from '@mui/icons-material/Mediation';
import HistoryIcon from '@mui/icons-material/History';
import StoreIcon from '@mui/icons-material/Store';
import ArticleIcon from '@mui/icons-material/Article';
import WarningIcon from '@mui/icons-material/Warning';
import DescriptionIcon from '@mui/icons-material/Description';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import WorkIcon from '@mui/icons-material/Work';
import ShareIcon from '@mui/icons-material/Share';
import InventoryIcon from '@mui/icons-material/Inventory';
import AppsIcon from '@mui/icons-material/Apps';
import TuneIcon from '@mui/icons-material/Tune';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { authService } from '../..';

type ApiVersion = {
  version: string;
  environment: string;
  last_commit: string;
};

const Sidebar = ({ ...props }) => {
  const [sidebarIsExpanded, setSidebarIsExpanded] = useState<boolean>(true);
  //eslint-disable-next-line
  const { staticContext, ...rest } = props;
  const [apiVersion, setApiVersion] = useState<ApiVersion>();
  const [showUiVersion, setShowUiVersion] = useState<boolean>();
  const appVersion = packageJson?.version;
  const [elementHovered, setElementHovered] = useState<string>();

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const location = useLocation();

  useEffect(() => {
    // Load expanded menu pref stored in localstorage
    const sidebarIsExpanded = JSON.parse(localStorage.getItem('sidebarIsExpanded') || 'true');
    if (typeof sidebarIsExpanded === 'boolean') {
      setSidebarIsExpanded(sidebarIsExpanded);
      setExpandedCssClasses(sidebarIsExpanded);
    }

    const checkIfMenuExpanded: any = sidebarItems.find((sidebarItem: any) => {
      return sidebarItem && sidebarItem?.key && location.pathname.includes(sidebarItem.key);
    });
    if (checkIfMenuExpanded) {
      setExpanded(checkIfMenuExpanded.key);
    }
    axios.get('/api/version/').then((response) => {
      setApiVersion(response?.data);
    });
  }, []);

  const toggleBurger = () => {
    localStorage.setItem('sidebarIsExpanded', !sidebarIsExpanded + '');
    setExpandedCssClasses(!sidebarIsExpanded);
    setSidebarIsExpanded(!sidebarIsExpanded);
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const setExpandedCssClasses = (menuIsExpanded: boolean) => {
    if (menuIsExpanded) {
      document.body.classList.add('sidebar-expanded');
      document.body.classList.remove('sidebar-collapsed');
    } else {
      document.body.classList.remove('sidebar-expanded');
      document.body.classList.add('sidebar-collapsed');
    }
  };

  const handleHoverOff = () => {
    setElementHovered('');
  };

  const handleHoverOn = (activeKey: string) => {
    setElementHovered(activeKey);
  };

  const itemStyle = { display: 'flex', alignItems: 'center' };

  const sidebarItems = [
    authService.canAccess('CALLS_MENU') && {
      item: (
        <>
          <CallIcon fontSize="small" /> <span>Appels</span>
        </>
      ),
      key: '/boi/calls',
      children: [
        {
          item: <Link to="/boi/calls/new">Réception</Link>,
          key: 'incalls'
        },
        authService.canAccess('CALLS_MENU_PAGE') && {
          item: <Link to="/boi/calls/listing">Listing</Link>,
          key: 'incalls-listing'
        }
      ]
    },
    authService.canAccess('CR_MENU') &&
      authService.canAccessBOI() && {
        item: (
          <>
            <BuildIcon fontSize="small" /> <span>CR</span>
          </>
        ),
        key: '/boi/cr/',
        children: [
          authService.canAccess('CR_MENU_DIAG') && {
            item: <Link to="/boi/cr/diagnostics">Diagnostics</Link>,
            key: '/boi/cr/diagnostics'
          },
          authService.canAccess('CR_MENU_DOSSIER') && {
            item: <Link to="/boi/cr/cases">Dossiers</Link>,
            key: '/boi/cr/cases'
          },
          authService.canAccess('CR_MENU_INTER') && {
            item: <Link to="/boi/cr/interventions">Interventions</Link>,
            key: '/boi/cr/interventions'
          },
          {
            item: <>Tableau de bord</>,
            key: 'models-params',
            children: [
              authService.canAccess('CR_MENU_TAB_CRP') && {
                item: <Link to="/boi/cr/statistics/cr+">CR+</Link>,
                key: '/boi/cr/statistics/cr+'
              }
            ]
          }
        ]
      },
    authService.canAccess('CR_MENU') &&
      authService.canAccessBOE() && {
        item: (
          <>
            <BuildIcon fontSize="small" /> <span>CR</span>
          </>
        ),
        key: '/boe/cr/',
        children: [
          {
            item: <Link to="/boe/cr/interventions">Interventions</Link>,
            key: '/boe/cr/interventions'
          },
          {
            item: <Link to="/boe/cr/planning">Planning</Link>,
            key: '/boe/cr/planning'
          }
        ]
      },
    authService.canAccess('MR_MENU') &&
      authService.canAccessBOI() && {
        item: (
          <>
            <ContentCopyIcon fontSize="small" /> <span>MR</span>
          </>
        ),
        key: '/boi/mr/',
        children: [
          authService.canAccess('MR_MENU_DOSSIER') && {
            item: <Link to="/boi/mr/cases/diagnostic">Diagnostics</Link>,
            key: '/boi/mr/cases/diagnostic'
          },
          authService.canAccess('MR_MENU_DOSSIER') && {
            item: <Link to="/boi/mr/cases">Dossiers</Link>,
            key: '/boi/mr/cases'
          }
        ]
      },
    authService.canAccess('GED_MENU') && {
      item: (
        <Link to={`${authService.BOIorBOE('/boi/DMS', '/boe/DMS')}`}>
          <FolderIcon fontSize="small" /> <span>GED</span>
        </Link>
      ),
      key: authService.BOIorBOE('/boi/DMS', '/boe/DMS')
    },
    authService.canAccess('INFO_CMP') && {
      item: (
        <Link to={`${authService.BOIorBOE('/boi/informations', '/boe/informations')}`}>
          <InfoIcon fontSize="small" /> <span>Informations</span>
        </Link>
      ),
      key: authService.BOIorBOE('/boi/informations', '/boe/informations')
    },
    authService.canAccess('RELEVE_MENU') && {
      item: (
        <Link to={`${authService.BOIorBOE('/boi/monthly-statement', '/boe/monthly-statement')}`}>
          <HourglassBottomIcon fontSize="small" /> <span>Relevés</span>
        </Link>
      ),
      key: authService.BOIorBOE('/boi/monthly-statement', '/boe/monthly-statement')
    },
    authService.canAccess('ADMIN_MENU') && {
      item: (
        <>
          <SettingsIcon fontSize="small" /> <span>Administration</span>
        </>
      ),
      key: '/boi/administration/',
      children: [
        authService.canAccess('ADMIN_MENU_USER') && {
          item: <Link to="/boi/administration/users">Utilisateurs</Link>,
          key: '/boi/administration/users'
        },
        authService.canAccess('ADMIN_MENU_GROUP') && {
          item: <Link to="/boi/administration/groups">Groupes</Link>,
          key: '/boi/administration/groups'
        },
        authService.canAccess('ADMIN_MENU_SECTION') && {
          item: <Link to="/boi/administration/sections">Sections</Link>,
          key: '/boi/administration/sections'
        },
        authService.canAccess('ADMIN_MENU_ST') && {
          item: (
            <Link
              to={`${authService.BOIorBOE(
                '/boi/administration/subcontractors',
                '/boe/administration/subcontractors'
              )}`}
            >
              Sous-traitants
            </Link>
          ),
          key: authService.BOIorBOE(
            '/boi/administration/subcontractors',
            '/boe/administration/subcontractors'
          )
        },
        authService.canAccess('ADMIN_LOG') && {
          item: <Link to="/boi/administration/logs">Logs</Link>,
          key: '/boi/administration/logs'
        },
        authService.canAccess('ADMIN_MESSAGE') && {
          item: <Link to="/boi/administration/messages">Messages</Link>,
          key: '/boi/administration/messages'
        },
        authService.isAdmin() && {
          item: <Link to="/boi/administration/tables">Tables techniques</Link>,
          key: '/boi/administration/tables'
        },
        authService.isAdmin() && {
          item: <Link to="/boi/administration/contracts">Contrats</Link>,
          key: '/boi/administration/contracts'
        }
      ]
    },
    authService.canAccess('EXTRACTOR_MENU') && {
      item: (
        <>
          <MediationIcon fontSize="small" /> <span>Admin export des interventions</span>
        </>
      ),
      key: 'extractor-admin',
      children: [
        {
          item: (
            <div style={itemStyle}>
              <StoreIcon fontSize="small" sx={{ mr: 1 }} />{' '}
              <Link to="/boi/extractor/clients/list">Clients</Link>
            </div>
          ),
          key: '/extractor/clients'
        },
        {
          item: (
            <div style={itemStyle}>
              <WarningIcon fontSize="small" sx={{ mr: 1 }} />{' '}
              <Link to="/boi/extractor/anomaly-profiles/list">Profils anomalies</Link>{' '}
            </div>
          ),
          key: 'anomaly-profiles'
        },
        {
          item: (
            <div style={itemStyle}>
              <ArticleIcon fontSize="small" sx={{ mr: 1 }} />{' '}
              <Link to="/boi/extractor/erps/list">ERP</Link>{' '}
            </div>
          ),
          key: 'erp'
        },
        {
          item: (
            <div style={itemStyle}>
              <DescriptionIcon fontSize="small" sx={{ mr: 1 }} />{' '}
              <Link to="/boi/extractor/export-formats/list">Formats d'export</Link>{' '}
            </div>
          ),
          key: 'export-formats'
        },
        {
          item: (
            <div style={itemStyle}>
              <JoinInnerIcon fontSize="small" sx={{ mr: 1 }} />{' '}
              <Link to="/boi/extractor/correspondances/list">Correspondances</Link>{' '}
            </div>
          ),
          key: 'correspondances'
        }
      ]
    },
    authService.canAccess('EXTRACTOR_MENU') && {
      item: (
        <>
          <ShareIcon fontSize="small" /> <span>Export des interventions</span>
        </>
      ),
      key: 'extractor',
      children: [
        {
          item: (
            <div style={itemStyle}>
              <WorkIcon fontSize="small" sx={{ mr: 1 }} />{' '}
              <Link to="/boi/extractor/jobs/list">Jobs</Link>{' '}
            </div>
          ),
          key: 'extractor-jobs'
        },
        {
          item: (
            <div style={itemStyle}>
              <HistoryIcon fontSize="small" sx={{ mr: 1 }} />{' '}
              <Link to="/boi/extractor/jobs-executions/list">Historique des exports</Link>{' '}
            </div>
          ),
          key: 'jobs-executions'
        }
      ]
    },
    authService.canAccess('BCONNECT_MENU') && {
      item: (
        <>
          <MapIcon fontSize="small" /> <span>B-Connect</span>
        </>
      ),
      key: '/bconnect/',
      children: [
        {
          item: <Link to="/bconnect/homepage">Accueil</Link>,
          key: 'bconnect/homepage'
        },
        {
          item: <Link to="/bconnect/dashboard">Dashboard</Link>,
          key: 'bconnect/dashboard'
        },
        {
          item: <Link to="/bconnect/users">Utilisateurs</Link>,
          key: 'bconnect/users'
        },
        {
          item: (
            <>
              <span>Suivi des interventions</span>
            </>
          ),
          key: 'extractor-interventions',
          children: [
            {
              item: (
                <div style={itemStyle}>
                  <DashboardIcon fontSize="small" sx={{ mr: 1 }} />{' '}
                  <Link to="/boi/extractor/interventions/dashboard">Tableau de bord</Link>
                </div>
              ),
              key: '/extractor/dashboard'
            },
            {
              item: (
                <div style={itemStyle}>
                  <BookmarkBorderIcon fontSize="small" sx={{ mr: 1 }} />{' '}
                  <Link to="/boi/extractor/interventions/list">Toutes les interv.</Link>{' '}
                </div>
              ),
              key: 'interventions'
            },
            {
              item: (
                <div style={itemStyle}>
                  <PendingActionsIcon fontSize="small" sx={{ mr: 1 }} />{' '}
                  <Link to="/bconnect/interventions/pending">Interv. en cours</Link>{' '}
                </div>
              ),
              key: 'interventions/pending'
            }
          ]
        }
      ]
    },
    authService.canAccess('BSTOCK_MENU') && {
      item: (
        <>
          <InventoryIcon fontSize="small" /> <span>B-Stock</span>
        </>
      ),
      key: '/bstock/',
      children: [
        {
          item: (
            <div style={itemStyle}>
              <AppsIcon fontSize="small" sx={{ mr: 1 }} />{' '}
              <Link to="/bstock/sessions">Sessions</Link>
            </div>
          ),
          key: 'bstock/sessions'
        },
        {
          item: (
            <div style={itemStyle}>
              <TuneIcon fontSize="small" sx={{ mr: 1 }} />{' '}
              <Link to="/bstock/configurations">Configurations</Link>
            </div>
          ),
          key: 'bstock/configurations'
        }
      ]
    }
  ];

  const SidebarItems = () => {
    return sidebarItems.map((sidebarItem) => {
      if (!sidebarItem) return null;
      const hasChildren = sidebarItem.children && sidebarItem?.children?.length > 0;
      const hasActiveChild =
        location.pathname === sidebarItem.key ||
        sidebarItem.children?.some((child: any) => {
          return location.pathname === child.key;
        });
      const sidebarIsExpanded = expanded === sidebarItem.key;
      return (
        <Accordion
          key={`${sidebarItem.key}`}
          disableGutters
          expanded={sidebarIsExpanded}
          onChange={hasChildren ? handleChange(sidebarItem.key) : undefined}
          onMouseEnter={() => {
            handleHoverOn(`${sidebarItem.key}`);
          }}
          onMouseLeave={() => {
            handleHoverOff();
          }}
          className={`${elementHovered === sidebarItem.key && 'hover'}`}
        >
          <AccordionSummary
            className={hasActiveChild && sidebarIsExpanded ? 'active' : ''}
            expandIcon={hasChildren && <KeyboardArrowRightIcon />}
          >
            {sidebarItem.item}
          </AccordionSummary>
          {hasChildren && (
            <AccordionDetails>
              {sidebarItem.children.map((child: any) => {
                if (!child) return null;
                const childIsActive = sidebarIsExpanded && location.pathname === child.key;
                return (
                  <Box
                    key={child.key}
                    sx={{ ml: 3, mb: 0.5 }}
                    className={childIsActive ? 'active' : ''}
                  >
                    {child.item}
                    {child.children && (
                      <Box sx={{ ml: 3 }}>
                        {child.children.map((subChild: any) => {
                          if (!subChild) return null;
                          return <Box key={`${child.key}/${subChild.key}`}>{subChild.item}</Box>;
                        })}
                      </Box>
                    )}
                  </Box>
                );
              })}
            </AccordionDetails>
          )}
        </Accordion>
      );
    });
  };

  return (
    <div
      className={`sidebar ${apiVersion?.environment} ${
        authService.canAccessBOE() ? 'boe' : authService.canAccessBOI() ? 'boi' : ''
      }`}
      {...rest}
    >
      <div className="logo">
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              <Link to="/" id="logo">
                <img
                  src={
                    //eslint-disable-next-line
                    process.env.PUBLIC_URL + '/logo-birdz-btech-icone.png'
                  }
                  alt=""
                  className="logo-icone"
                />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-start">
              <Link to="/" id="logo">
                <img
                  src={
                    //eslint-disable-next-line
                    process.env.PUBLIC_URL + '/logo-birdz-btech.png'
                  }
                  alt=""
                  className="logo-texte"
                />
              </Link>
            </Box>
          </Grid>
        </Grid>
        {authService.canAccessBOE() ? (
          <div className="workspace">
            <div className="title">Espace gestionnaire</div>
            <div className="subcontractor-name">{authService.user?.subcontractor__name}</div>
          </div>
        ) : authService.canAccessBOI() && !authService.isAdmin() ? (
          <div className="workspace">
            <div className="title">Espace support</div>
          </div>
        ) : null}
      </div>
      <IconButton id="burger" onClick={() => toggleBurger()}>
        <MenuIcon fontSize="small" />
      </IconButton>

      <div className="sidebar-wrapper">
        <div className="sidebar-scroll">{SidebarItems()}</div>
      </div>

      <span className="logout">
        <IconButton onClick={authService.logout}>
          <PowerSettingsNewIcon fontSize="small" />
        </IconButton>
      </span>

      {authService.canAccessBOI() && (
        <span className="version">
          <Box
            onClick={() => {
              setShowUiVersion(!showUiVersion);
            }}
          >
            <span title={`Dernier commit: ${apiVersion?.last_commit || '-'}`}>
              Version: {apiVersion?.version}
            </span>{' '}
            <span title="UI version" style={{ visibility: showUiVersion ? 'visible' : 'hidden' }}>
              {' '}
              / UI: {appVersion}
            </span>
          </Box>
        </span>
      )}
    </div>
  );
};

export default Sidebar;
