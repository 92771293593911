import {
  BirdzModal,
  BirdzModalActions,
  BirdzModalContent
} from '@applications-terrains/birdz-react-library';
import { Button, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

type CalendarEventModalProps = {
  data: any;
  onClose(): void;
};

const CalendarEventModal = ({ onClose, data = {} }: CalendarEventModalProps) => {
  return (
    <BirdzModal
      open={data !== null}
      onClose={() => {
        onClose();
      }}
    >
      <BirdzModalContent>
        <Typography variant="h6" component="h2">
          Détail intervention {data?.cr_case__number}
        </Typography>

        <p>
          Planifiée le {moment(data?.scheduled_start_date).format('DD/MM/YYYY à HH:mm')} (durée
          prévue: {data?.duration_hours}h)
        </p>
        <h5>Statut dossier</h5>
        <p>{data?.cr_case__status__label}</p>

        <h5>Observations</h5>
        <p>{data?.observation}</p>

        <h5>Instructions</h5>
        <p>{data?.specific_instructions}</p>

        <h5>Informations supplémentaires</h5>
        <div style={{ height: 300, overflow: 'auto' }}>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      </BirdzModalContent>
      <BirdzModalActions>
        <Button
          component={Link}
          variant="outlined"
          to={`/boe/cr/interventions/details/${data?.id}`}
        >
          Modifier intervention
        </Button>
      </BirdzModalActions>
    </BirdzModal>
  );
};

export default CalendarEventModal;
