import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { ModuleData } from '../CR.types';
import CountHistoryModal from './CountHistoryModal';
import {
  StyledTableBodyRow,
  StyledTableCell,
  StyledTableHeaderRow
} from '../../../../styles/Table.styled';

type DeviceProps = {
  moduleData: ModuleData;
};

const Device = ({ moduleData }: DeviceProps) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <StyledTableHeaderRow sx={{ bgcolor: 'gray' }}>
            <StyledTableCell align="center">Adresse radio</StyledTableCell>
            <StyledTableCell align="center">PDC</StyledTableCell>
            <StyledTableCell align="center">Type équipement</StyledTableCell>
            <StyledTableCell align="center">Nb de module exclusifs</StyledTableCell>
            <StyledTableCell align="center">Contrat</StyledTableCell>
          </StyledTableHeaderRow>
        </TableHead>
        <TableBody>
          <StyledTableBodyRow>
            <StyledTableCell align="center">
              {moduleData?.module_address ? moduleData?.module_address : 'Non renseignée'}
            </StyledTableCell>
            <StyledTableCell align="center">
              {moduleData?.pdc_id ? moduleData?.pdc_id : 'Non renseigné'}
            </StyledTableCell>
            <StyledTableCell align="center">
              {moduleData?.device_type ? moduleData?.device_type : 'Non renseigné'}
            </StyledTableCell>
            <StyledTableCell align="center">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div>
                  {moduleData?.average_exclusive_module ||
                  moduleData?.average_exclusive_module === 0
                    ? moduleData?.average_exclusive_module
                    : 'Non renseigné'}
                </div>
                <div>
                  <CountHistoryModal id={moduleData.module_address} />
                </div>
              </div>
            </StyledTableCell>
            <StyledTableCell align="center">
              {moduleData?.contract ? moduleData?.contract : 'Non renseigné'}
            </StyledTableCell>
          </StyledTableBodyRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Device;
